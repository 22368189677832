import { Buffer } from 'buffer';
globalThis.Buffer = Buffer;

import * as WalletAdapterBase from "@solana/wallet-adapter-base";
import * as WalletAdapterAlpha from '@solana/wallet-adapter-alpha';
import * as WalletAdapterAvana from '@solana/wallet-adapter-avana';
import * as WalletAdapterBackpack from '@solana/wallet-adapter-backpack';
import * as WalletAdapterBitKeep from '@solana/wallet-adapter-bitkeep';
import * as WalletAdapterBitpie from '@solana/wallet-adapter-bitpie';
import * as WalletAdapterClover from '@solana/wallet-adapter-clover';
import * as WalletAdapterCoin98 from '@solana/wallet-adapter-coin98';
import * as WalletAdapterCoinbase from '@solana/wallet-adapter-coinbase';
import * as WalletAdapterCoinhub from '@solana/wallet-adapter-coinhub';
import * as WalletAdapterFractal from '@solana/wallet-adapter-fractal';
import * as WalletAdapterHuobi from '@solana/wallet-adapter-huobi';
import * as WalletAdapterHyperPay from '@solana/wallet-adapter-hyperpay';
import * as WalletAdapterKrystal from '@solana/wallet-adapter-krystal';
import * as WalletAdapterLedger from '@solana/wallet-adapter-ledger';
import * as WalletAdapterMathWallet from '@solana/wallet-adapter-mathwallet';
import * as WalletAdapterNeko from '@solana/wallet-adapter-neko';
import * as WalletAdapterNightly from '@solana/wallet-adapter-nightly';
import * as WalletAdapterNufi from '@solana/wallet-adapter-nufi';
import * as WalletAdapterOnto from '@solana/wallet-adapter-onto';
import * as WalletAdapterParticle from '@solana/wallet-adapter-particle';
import * as WalletAdapterPhantom from '@solana/wallet-adapter-phantom';
import * as WalletAdapterSafePal from '@solana/wallet-adapter-safepal';
import * as WalletAdapterSaifu from '@solana/wallet-adapter-saifu';
import * as WalletAdapterSalmon from '@solana/wallet-adapter-salmon';
import * as WalletAdapterSky from '@solana/wallet-adapter-sky';
import * as WalletAdapterSolong from '@solana/wallet-adapter-solong';
import * as WalletAdapterSpot from '@solana/wallet-adapter-spot';
import * as WalletAdapterTokenary from '@solana/wallet-adapter-tokenary';
import * as WalletAdapterTokenPocket from '@solana/wallet-adapter-tokenpocket';
import * as WalletAdapterTrezor from '@solana/wallet-adapter-trezor';
import * as WalletAdapterTrust from '@solana/wallet-adapter-trust';
import * as WalletAdapterWalletConnect from '@solana/wallet-adapter-walletconnect';
import * as WalletAdapterXDEFI from '@solana/wallet-adapter-xdefi';
import * as WalletStandardApp from '@wallet-standard/app';
import * as WalletStandardChains from '@solana/wallet-standard-chains';
import * as WalletStandardFeatures from '@solana/wallet-standard-features';

import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';

import {
    Connection,
    PublicKey,
    Transaction,
    SystemProgram,
} from '@solana/web3.js';


import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAccount } from '@solana/spl-token';

export function getPublicKeyWrapper(pubkey) {
    return new PublicKey(pubkey);
}

export async function findAssociatedTokenAccount(connection, owner, mint) {
    const [account] = await PublicKey.findProgramAddressSync(
        [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID
    );
    return account;
}

export async function findOrCreateAssociatedTokenAccount(connection, payer, mint, owner, transaction) {
    try {
        const [associatedTokenAddress] = PublicKey.findProgramAddressSync(
            [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
            ASSOCIATED_TOKEN_PROGRAM_ID
        );

        const accountInfo = await connection.getAccountInfo(associatedTokenAddress);

        if (accountInfo === null) {
            const lamports = await connection.getMinimumBalanceForRentExemption(165);
            const createAccountInstruction = SystemProgram.createAccount({
                fromPubkey: payer,
                newAccountPubkey: associatedTokenAddress,
                lamports: lamports,
                space: 165,
                programId: TOKEN_PROGRAM_ID,
            });

            const initAccountInstruction = Token.createInitAccountInstruction(
                TOKEN_PROGRAM_ID,
                mint,
                associatedTokenAddress,
                owner
            );

            transaction.add(createAccountInstruction, initAccountInstruction);
        }

        return associatedTokenAddress;
    } catch (error) {
        console.error('Error in findOrCreateAssociatedTokenAccount:', error.message);
        throw error;
    }
}

export function getWallets() {
    function add_unique(wallets, wallet) {
        let found_wallet_index = wallets.findIndex((element) => element.name == wallet.name);
        if (found_wallet_index == -1) {
            wallets.push(wallet);
        } else {
            let found_wallet = wallets.splice(found_wallet_index, 1)[0];
            found_wallet.detected = true;
            wallets.unshift(found_wallet);
        }
    }

    let wallets = [];

    add_unique(wallets, new WalletAdapterAlpha.AlphaWalletAdapter());
    add_unique(wallets, new WalletAdapterAvana.AvanaWalletAdapter());
    add_unique(wallets, new BackpackWalletAdapter());
    add_unique(wallets, new SolflareWalletAdapter());
    
    add_unique(wallets, new WalletAdapterBitKeep.BitKeepWalletAdapter());
    add_unique(wallets, new WalletAdapterBitpie.BitpieWalletAdapter());
    add_unique(wallets, new WalletAdapterClover.CloverWalletAdapter());
    add_unique(wallets, new WalletAdapterCoin98.Coin98WalletAdapter());
    add_unique(wallets, new WalletAdapterCoinbase.CoinbaseWalletAdapter());
    add_unique(wallets, new WalletAdapterCoinhub.CoinhubWalletAdapter());
    add_unique(wallets, new WalletAdapterFractal.FractalWalletAdapter());
    add_unique(wallets, new WalletAdapterHuobi.HuobiWalletAdapter());
    add_unique(wallets, new WalletAdapterHyperPay.HyperPayWalletAdapter());
    add_unique(wallets, new WalletAdapterKrystal.KrystalWalletAdapter());
    add_unique(wallets, new WalletAdapterLedger.LedgerWalletAdapter());
    add_unique(wallets, new WalletAdapterMathWallet.MathWalletAdapter());
    add_unique(wallets, new WalletAdapterNeko.NekoWalletAdapter());
    add_unique(wallets, new WalletAdapterNightly.NightlyWalletAdapter());
    add_unique(wallets, new WalletAdapterNufi.NufiWalletAdapter());
    add_unique(wallets, new WalletAdapterOnto.OntoWalletAdapter());
    add_unique(wallets, new WalletAdapterParticle.ParticleAdapter());
    add_unique(wallets, new WalletAdapterPhantom.PhantomWalletAdapter());
    add_unique(wallets, new WalletAdapterSafePal.SafePalWalletAdapter());
    add_unique(wallets, new WalletAdapterSaifu.SaifuWalletAdapter());
    add_unique(wallets, new WalletAdapterSalmon.SalmonWalletAdapter());
    add_unique(wallets, new WalletAdapterSky.SkyWalletAdapter());
    add_unique(wallets, new WalletAdapterSolong.SolongWalletAdapter());
    add_unique(wallets, new WalletAdapterSpot.SpotWalletAdapter());
    add_unique(wallets, new WalletAdapterTokenary.TokenaryWalletAdapter());
    add_unique(wallets, new WalletAdapterTokenPocket.TokenPocketWalletAdapter());
    add_unique(wallets, new WalletAdapterTrezor.TrezorWalletAdapter());
    add_unique(wallets, new WalletAdapterTrust.TrustWalletAdapter());
    add_unique(wallets, new WalletAdapterWalletConnect.WalletConnectWalletAdapter());
    add_unique(wallets, new WalletAdapterXDEFI.XDEFIWalletAdapter());

    for (let wallet of WalletStandardApp.getWallets().get().filter((wallet) => {
        return (wallet.chains.includes(WalletStandardChains.SOLANA_MAINNET_CHAIN) &&
                Object.keys(wallet.features).includes(WalletStandardFeatures.SolanaSignTransaction) ||
                Object.keys(wallet.features).includes(WalletStandardFeatures.SolanaSignAndSendTransaction));
    })) {
        add_unique(wallets, wallet);
    }

    return wallets;
}

export async function getTokenAccount(connection, publicKey, tokenMintAddress) {
    const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, { mint: tokenMintAddress });

    if (tokenAccounts.value.length > 0) {
        const tokenAccount = tokenAccounts.value[0].pubkey;
        const tokenAccountInfo = await getAccount(connection, tokenAccount);
        return tokenAccountInfo;
    }

    return null;
}

export {
    PublicKey,
    Transaction,
};
