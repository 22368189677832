//
// main.js for The Shinobi Performance Pool
//

//
// Import JavaScript libraries
//

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as solanaWeb3 from '@solana/web3.js';
import { check_new_timestamp, load_overview_details, load_pool_details, load_non_pool_voters, load_voter_metrics_group, load_search } from './load.js';
import './bs58.js';
import './load.js';
import {
    getWallets,
    PublicKey,
    Transaction,
} from './solana_wallet_lib.js';

import {
    getStakePoolAccount,
    depositSol,
    depositStake,
    withdrawSol,
    withdrawStake,
    STAKE_POOL_PROGRAM_ID
} from '@solana/spl-stake-pool';

import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack'


import { WalletReadyState } from '@solana/wallet-adapter-base';

const backpackWallet = new BackpackWalletAdapter({
    // Add config to better handle mobile detection
    config: {
        autoConnect: true,
        mobileEnabled: true
    }
});

const BACKPACK_DOWNLOAD_URL = 'https://backpack.app/download';

//
// Global Vars
//

// Register Chart.js components and plugins
Chart.register(...registerables, ChartDataLabels);

const passwordProtectionEnabled = false;

const awardImage = new Image();
let combinedChart;
let userGraphPreferences = {};
let globalWallets = [];
let selectedRpcUrl = '';
let selectedPubkey = '';
let selectedWallet = null;
let customRpcUrl = '';
let customPubkey = '';
let showAdvancedOptions = false;
let xshinToSolRate = 0;
let globalPriorityFees = { };
let globalSortedStakeAccounts = [];
let lastSelectedMetric = 'llv';
let isScrollingProgrammatically = false;
let globalStakeWeights = null;
let globalAllValidatorsAverage = null;
let isDetailsOpen = false;
let currentOpenPubkey = null;
let isScrollingToValidator = false;
let isInitialLoad = true;
let shouldOpenValidatorDetails = false;
let initialUrlParamsHandled = false;

// Stake Pool Program Addresses
const stakePool = new solanaWeb3.PublicKey('spp1mo6shdcrRyqDK2zdurJ8H5uttZE6H6oVjHxN1QN');
const poolMint = new solanaWeb3.PublicKey('xSoL18r4U1k2ALa4yF857VDZJqCKecLtty92nscre3o');

// RPC Addresses
const heliusRpcUrl = 'https://halli-hmkuq8-fast-mainnet.helius-rpc.com/';
const backupRpcUrl = 'https://api.mainnet-beta.solana.com';
const defaultRpcUrl = heliusRpcUrl;

// Estimated CU of various transaction types.  These were obtained by examining previously submitted tx.
const stakeSOL_EstimatedCU = 60000;
const stakeAccount_EstimatedCU = 200000;
const unstakeToSOL_EstimatedCU = 60000;
const unstakeToStake_EstimatedCU = 200000;

//
// State Management
//

let appState = {
    timestamp: null,
    currentValidatorPubkey: null,
    cache: {
        overview: null,
        pool: null,
        nonPoolVoters: null,
        defiProjects: null,
        pressItems: null
    },
    activeSortColumn: null,
    activeSortDirection: null,
    lastUsedDataset: 'pool',
    currentTab: 'Home',
    validatorType: 'pool',
    selectedValidator: null,
    searchQuery: '',
    dataLoaded: false
};

function updateAppState(newState) {
    appState = { ...appState, ...newState };
    if (newState.cache) {
        appState.cache = { ...appState.cache, ...newState.cache };
    }
}

function getAppState(key) {
    return key ? appState[key] : appState;
}

//
// Login
//
const correctPasswordBase64 = "eHNvbGxlbnQ0Mg==";

function decodeBase64(base64) {
    return atob(base64);
}

function checkPassword(inputPassword) {
    const correctPassword = decodeBase64(correctPasswordBase64);
    return inputPassword === correctPassword;
}

function showPasswordForm() {
    const passwordFormHtml = `
        <div id="password-form-container" class="show">
            <form id="password-form">
                <label for="password-input">Enter Password:</label>
                <input type="password" id="password-input" required>
                <button type="submit">Submit</button>
            </form>
        </div>`;
    document.body.insertAdjacentHTML('afterbegin', passwordFormHtml);
    document.getElementById('password-form').addEventListener('submit', handlePasswordSubmit);
}

function handlePasswordSubmit(event) {
    event.preventDefault();
    const inputPassword = document.getElementById('password-input').value;
    if (checkPassword(inputPassword)) {
        document.getElementById('content-container').style.display = 'block';
        document.getElementById('password-form-container').remove();
    } else {
        alert('Incorrect password. Please try again.');
    }
}

function initializePasswordProtection() {
    if (passwordProtectionEnabled) {
        showPasswordForm();
    } else {
        document.getElementById('content-container').style.display = 'block';
    }
}

//
// Browser back button
//

window.addEventListener('popstate', async function(event) {
    await openTab(null);
});

//
// LOAD DATA
//

document.addEventListener('DOMContentLoaded', async () => {
    try {
        await loadInitialData();
        if (getAppState('dataLoaded')) {
            console.log("Initial data loaded successfully, initializing UI");
            await initializeUI();
        } else {
            console.error('Failed to load initial data. Please refresh the page.');
        }
    } catch (error) {
        console.error('Error during initial page load:', error);
    }
});

async function loadInitialData() {
    try {
        const latestTimestamp = await new Promise(resolve => check_new_timestamp(resolve));
        updateAppState({ timestamp: latestTimestamp });

        const [overviewAndPoolData, nonPoolVoters] = await Promise.all([
            loadOverviewAndPoolDetails(),
            fetchNonPoolData(latestTimestamp)
        ]);

        updateAppState({
            cache: {
                overview: overviewAndPoolData.overview,
                pool: overviewAndPoolData.pool,
                nonPoolVoters: nonPoolVoters.voters
            },
            dataLoaded: true
        });

        calculateGlobalMetrics();
        updateHeaderStats();

		updateDataTimestampDisplay(getAppState('timestamp'));

    } catch (error) {
        console.error('Error loading initial data:', error);
        updateAppState({ dataLoaded: false });
    }
}

async function fetchLatestTimestamp() {
    const response = await fetch('https://xshin.fi/data/pool/newest');
    const timestamp = await response.text();
    return Number(timestamp.trim());
}

async function fetchNonPoolData(timestamp) {
    return new Promise((resolve, reject) => {
        load_non_pool_voters(timestamp, (nonPoolVoters) => {
            if (nonPoolVoters && nonPoolVoters.voters) {
                resolve(nonPoolVoters);
            } else {
                console.error('Failed to load non-pool voter data or data is in unexpected format.');
                reject(new Error('Failed to load non-pool voter data.'));
            }
        });
    });
}

function showLoadingSpinner() {
	return;
	// skip
    const spinner = document.getElementById('content-loading-spinner');
    if (spinner) {
        positionLoadingSpinner();
        spinner.style.display = 'flex';
    }
}

function hideLoadingSpinner() {
    const spinner = document.getElementById('content-loading-spinner');
    if (spinner) {
        spinner.style.display = 'none';
    }
}

function positionLoadingSpinner() {
    const spinner = document.getElementById('content-loading-spinner');
    const validatorsTable = document.getElementById('validatorsTable');

    if (spinner && validatorsTable) {
        const header = document.querySelector('.fixed-header');
        const headerHeight = header ? header.offsetHeight : 0;
        const extraPadding = calculateExtraPadding();
        const validatorTypeToggle = document.querySelector('.validator-type-toggle');
        const toggleHeight = validatorTypeToggle ? validatorTypeToggle.offsetHeight : 0;
        const totalFixedHeight = headerHeight + toggleHeight + extraPadding - 178;

        // Get the position of the validators table
        const tableRect = validatorsTable.getBoundingClientRect();

        // Position the spinner just below the fixed elements
        const spinnerTop = tableRect.top + window.scrollY + totalFixedHeight;

        spinner.style.top = `${spinnerTop}px`;
        spinner.style.left = '50%';
        spinner.style.transform = 'translateX(-50%)';

        // Ensure the spinner is visible
        spinner.style.display = 'flex';
    }
}

function calculateGlobalMetrics() {
    const appState = getAppState();
    if (!appState.dataLoaded) {
        console.error("Data not loaded for calculating global metrics");
        return;
    }

    globalStakeWeights = countTotalStake();
    globalAllValidatorsAverage = computeAllValidatorsAverage(appState.cache.pool, globalStakeWeights);
}

function countTotalStake() {
    let nonPoolStakeWeight = BigInt(0);

    const poolStakeWeight = BigInt(getAppState().cache.overview.pool_stake.active);

    const nonPoolVoters = getAppState().cache.nonPoolVoters;

    if (nonPoolVoters && nonPoolVoters instanceof Map) {
        nonPoolVoters.forEach((validator, pubkey) => {
            nonPoolStakeWeight += BigInt(validator.details.stake.active);
        });
    } else {
        console.error("nonPoolVoters is not a Map or is undefined");
    }

    const totalStakeWeight = poolStakeWeight + nonPoolStakeWeight;

    return {
        poolStakeWeight: Number(poolStakeWeight),
        nonPoolStakeWeight: Number(nonPoolStakeWeight),
        totalStakeWeight: Number(totalStakeWeight)
    };
}

function computeAllValidatorsAverage(poolDetails, stakeWeights) {
    const { poolStakeWeight, nonPoolStakeWeight, totalStakeWeight } = stakeWeights;

    const allValidatorsAverage = {
        cu: ((poolDetails.compare_by_current.spp_raw.cu * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.cu * nonPoolStakeWeight)) / totalStakeWeight,
        llv: ((poolDetails.compare_by_current.spp_raw.llv * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.llv * nonPoolStakeWeight)) / totalStakeWeight,
        cv: ((poolDetails.compare_by_current.spp_raw.cv * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.cv * nonPoolStakeWeight)) / totalStakeWeight,
        vote_inclusion: ((poolDetails.compare_by_current.spp_raw.vote_inclusion * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.vote_inclusion * nonPoolStakeWeight)) / totalStakeWeight,
        // APY is special, it's the average only for pool validators, not for all validators.  This is because only
        // pool validator APY is accurately available.
        apy: poolDetails.compare_by_current.spp_raw.apy,
        skip_rate: ((poolDetails.compare_by_current.spp_raw.skip_rate * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.skip_rate * nonPoolStakeWeight)) / totalStakeWeight,
        latency: ((poolDetails.compare_by_current.spp_raw.latency * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.latency * nonPoolStakeWeight)) / totalStakeWeight,
        city_concentration: ((poolDetails.compare_by_current.spp_raw.city_concentration * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.city_concentration * nonPoolStakeWeight)) / totalStakeWeight,
        country_concentration: ((poolDetails.compare_by_current.spp_raw.country_concentration * poolStakeWeight) + (poolDetails.compare_by_current.other_raw.country_concentration * nonPoolStakeWeight)) / totalStakeWeight
    };

    return allValidatorsAverage;
}

function fetchInitialTimestampAndUpdateGlobal() {
    const req = new XMLHttpRequest();
    req.open("GET", "https://xshin.fi/data/pool/newest", true);
    req.onload = () => {
        if (req.status === 200 && req.responseText) {
            const timestamp = Number(req.responseText);
            updateAppState({ timestamp: timestamp });
            updateDataTimestampDisplay(getAppState('timestamp'));
            loadOverviewAndPoolDetails().then(() => {
                // Additional actions after loading data, if needed
            }).catch(error => {
                console.error("Error loading overview and pool details:", error);
            });
        } else {
            console.error("Failed to fetch the initial timestamp:", req.statusText);
        }
    };
    req.onerror = () => {
        console.error("Error fetching the initial timestamp.");
    };
    req.send();
}

function updateDataTimestampDisplay(timestamp) {
    let data_timestamp_label = document.getElementById("data_timestamp");
    data_timestamp_label.innerHTML = "Data last updated " + (new Date(timestamp * 1000)).toLocaleString();
}

async function loadNonPoolVotersData() {
    return new Promise((resolve, reject) => {
        load_non_pool_voters(getAppState('timestamp'), (nonPoolVotersData) => {
            if (nonPoolVotersData) {
                resolve(nonPoolVotersData);
            } else {
                reject(new Error('Failed to load non-pool voters data.'));
            }
        });
    });
}

async function loadOverviewAndPoolDetails() {
    if (getAppState('timestamp')) {
        try {
            const [overview, pool] = await Promise.all([
                new Promise((resolve, reject) => {
                    load_overview_details(getAppState('timestamp'), (overviewDetails) => {
                        if (overviewDetails) {
                            resolve(overviewDetails);
                        } else {
                            reject(new Error('Failed to load overview details.'));
                        }
                    });
                }),
                new Promise((resolve, reject) => {
                    load_pool_details(getAppState('timestamp'), (poolDetails) => {
                        if (poolDetails) {
                            resolve(poolDetails);
                        } else {
                            reject(new Error('Failed to load pool details.'));
                        }
                    });
                })
            ]);

            updateAppState({
                cache: {
                    overview,
                    pool: {
                        ...pool,
                        validators: pool.pool_voters
                    }
                }
            });

            return { overview, pool };
        } catch (error) {
            console.error('Failed to load overview and pool details:', error);
            throw error;
        }
    } else {
        console.error("Timestamp not initialized in app state.");
        throw new Error("Timestamp not initialized in app state.");
    }
}

function mergeValidatorData() {
    const appState = getAppState();
    const poolVoters = appState.cache.pool?.pool_voters;
    const nonPoolVoters = appState.cache.nonPoolVoters?.voters;

    if (!poolVoters || !nonPoolVoters) {
        console.error('Pool voters or non-pool voters data is missing');
        return new Map();
    }

    const mergedData = new Map([...poolVoters]);

    for (const [pubkey, validator] of nonPoolVoters) {
        if (!mergedData.has(pubkey)) {
            mergedData.set(pubkey, {
                ...validator,
                pool_stake: { active: 0 }
            });
        }
    }
    return mergedData;
}

//
// INITIALIZATION
//

async function initializeUI() {
    updateCurrentYear();
    initializePasswordProtection();
    initializeLogoClickEvent();
    initializeStakeNowButtons();
    initializeValidatorSearch();
    initializeAwardImage();
    initializeValidatorTypeRadioButtons();
    initializeStakeAmountInput();
    initializeWalletConnection();
    initializeUnstakeInputs();
    initializeAdvancedOptions();
    initializeSubTabs();
    initializeXshinToSolRate();
    initializeStakeBalanceDropdown();
    initializeStakingUI();
    initializeValidatorTypeChangeHandlers();
    initializeStakeAccountSelect();
    initializeInfoIcons();
    initMobileMessageClose();
    initializeExchangeRate();

    attachValidatorIconListeners();
    attachDetailsPaneListeners();

    // Open the initial tab
    await openTab(null, window.location.hash.slice(1).split('?')[0] || 'Home');

	isInitialLoad = false;
}

function initializeLogoClickEvent() {
    const logoLink = document.getElementById('logoLink');
    if (logoLink) {
        logoLink.addEventListener('click', function(event) {
            event.preventDefault();
            openTab(null, 'Home');
        });
    }
}

function initializeStakeNowButtons() {
    const stakeNowButtons = document.querySelectorAll('.stake-now-button');
    stakeNowButtons.forEach(button => {
        button.addEventListener('click', function(event) {
            event.preventDefault();
            openTab(event, 'Staking');
        });
    });
}

function initializeAwardImage() {
    awardImage.src = 'images/kunai-icon-b.png';
    //fetchInitialTimestampAndUpdateGlobal();
}

function initializeValidatorTypeRadioButtons() {
    document.querySelectorAll('input[name="validatorType"]').forEach((radio) => {
        radio.addEventListener('change', async (event) => {
            if (!isInitialLoad) {
                await handleValidatorTypeChange(event.target.value);
            }
        });
    });
}

function initializeValidatorSearch() {
    const validatorSearch = document.getElementById('validatorSearch');
    validatorSearch.addEventListener('input', debounce(async function () {
        if (!isValidatorsTabActive()) {
            await openTab(null, 'Validators');
        }
        await searchValidators();
    }, 0)); // 300ms debounce

    const clearSearchButton = document.getElementById('clearSearchButton');
    if (clearSearchButton) {
        clearSearchButton.addEventListener('click', clearSearch);
    }

    // Clear search when switching validator types
    document.querySelectorAll('input[name="validatorType"]').forEach(radio => {
        radio.addEventListener('change', async () => {
            await handleValidatorTypeChange(radio.value);
            clearSearch();
        });
    });

    // Clear search when navigating to a different tab
    document.querySelectorAll('.tablinks').forEach(tab => {
        tab.addEventListener('click', clearSearch);
    });
}

function updateValidatorTypeRadioSilently(type) {
    const radio = document.getElementById(`${type}-validators`);
    if (radio && !radio.checked) {
        radio.checked = true;
        updateAppState({ lastUsedDataset: type, validatorType: type });
    }
}

function initializeStakeAmountInput() {
    document.getElementById('stakeAmount').addEventListener('keypress', function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            stakeSOL();
        }
    });
}

function initializeWalletConnection() {
    document.getElementById('connectWalletButton').addEventListener('click', () => {
        openWalletSelectionModal();
    });
}

function initializeUnstakeInputs() {
    const xshinToSolInput = document.getElementById('unstakeXshinAmount');
    const xshinToSolEstimate = document.getElementById('xshinToSolEstimate');
    xshinToSolInput.addEventListener('input', async function () {
        const amount = parseFloat(this.value);
        if (!isNaN(amount) && amount > 0) {
            const estimate = await simulateUnstakeXshinToSol(amount);
            xshinToSolEstimate.textContent = `~${estimate.toFixed(3)} `;
        } else {
            xshinToSolEstimate.textContent = '';
        }
    });

    const xshinToStakeInput = document.getElementById('unstakeToStakeAmount');
    const xshinToStakeEstimate = document.getElementById('xshinToStakeEstimate');
    xshinToStakeInput.addEventListener('input', async function () {
        const amount = parseFloat(this.value);
        if (!isNaN(amount) && amount > 0) {
            const estimate = await simulateUnstakeXshinToStakeAccount(amount);
            xshinToStakeEstimate.textContent = `~${estimate.toFixed(3)} Staked`;
        } else {
            xshinToStakeEstimate.textContent = '';
        }
    });
}

function initializeAdvancedOptions() {
    const advancedOptionsCheckbox = document.getElementById('advancedOptionsCheckbox');
    advancedOptionsCheckbox.checked = showAdvancedOptions;
    advancedOptionsCheckbox.addEventListener('change', function() {
        const advancedOptionsContainer = document.getElementById('advancedOptionsContainer2');
        if (this.checked) {
            advancedOptionsContainer.style.justifyContent = 'center';
            advancedOptionsContainer.style.alignItems = 'center';
            advancedOptionsContainer.style.display = 'block';
        } else {
            advancedOptionsContainer.style.display = 'none';
        }
    });
}

function initializeSubTabs() {
    document.querySelectorAll('.sub-tab-list li a').forEach(function(tab) {
        tab.addEventListener('click', function(e) {
            e.preventDefault();
            const parent = this.closest('.sub-tabs');
            parent.querySelectorAll('.sub-tab-list li a').forEach(function(tab) {
                tab.classList.remove('active');
                tab.style.zIndex = '3';
            });
            this.classList.add('active');
            this.style.zIndex = '5';
            parent.querySelectorAll('.sub-tab-content > div').forEach(function(content) {
                content.style.display = 'none';
            });
            parent.querySelector(`#${this.getAttribute('data-tab')}`).style.display = 'block';
        });
    });
}

async function initializeXshinToSolRate() {
    xshinToSolRate = await fetchXshinToSolRate();
}

function initializeStakeBalanceDropdown() {
    const stakeBalanceContainer = document.querySelector('.stake-balance-container');
    const stakeAccountDropdown = document.querySelector('.stake-account-dropdown');
    const walletInfoContainer = document.querySelector('.wallet-info-container');
    let isDropdownOpen = false;

    stakeBalanceContainer.addEventListener('click', async function(event) {
        event.stopPropagation();
        isDropdownOpen = !isDropdownOpen;
        if (isDropdownOpen) {
            if (!globalSortedStakeAccounts || globalSortedStakeAccounts.length === 0) {
                if (selectedWallet && selectedWallet.publicKey) {
                    populateStakeAccountDropdown(selectedWallet.publicKey);
                }
            }
        }
        stakeAccountDropdown.classList.toggle('show', isDropdownOpen);
    });

    document.addEventListener('click', function(event) {
        if (!stakeBalanceContainer.contains(event.target) &&
            !stakeAccountDropdown.contains(event.target) &&
            !walletInfoContainer.contains(event.target)) {
            isDropdownOpen = false;
            stakeAccountDropdown.classList.remove('show');
        }
    });

    stakeAccountDropdown.addEventListener('click', function(event) {
        event.stopPropagation();
    });
}

function initializeStakingUI() {
    updateStakingUI(false);
    updateUnstakingUI(false);
}

function initializeValidatorTypeChangeHandlers() {
    document.getElementById('pool-validators').addEventListener('change', () => handleValidatorTypeChange('pool'));
    document.getElementById('all-validators').addEventListener('change', () => handleValidatorTypeChange('all'));
}

function initializeStakeAccountSelect() {
    const stakeAccountSelect = document.getElementById('stakeAccountSelect');
    stakeAccountSelect.addEventListener('change', async function() {
        const selectedOption = this.selectedOptions[0];
        if (selectedOption) {
            const balance = parseFloat(selectedOption.dataset.balance);
            if (!isNaN(balance)) {
                const estimate = await simulateStakeSolToStakeAccount(balance);
                document.getElementById('stakeToXshinEstimate').textContent = `~${estimate.toFixed(3)}`;
            } else {
                document.getElementById('stakeToXshinEstimate').textContent = '';
            }
        } else {
            document.getElementById('stakeToXshinEstimate').textContent = '';
        }
    });
}

function initializeInfoIcons() {
    document.querySelectorAll('.info-icon').forEach(icon => {
        icon.addEventListener('mouseenter', () => {
            const tooltip = icon.nextElementSibling;
            if (tooltip && tooltip.classList.contains('info-tooltip')) {
                tooltip.style.display = 'block';
            }
            showInfoTooltip();
        });

        icon.addEventListener('mouseleave', () => {
            const tooltip = icon.nextElementSibling;
            if (tooltip && tooltip.classList.contains('info-tooltip')) {
                tooltip.style.display = 'none';
            }
            hideInfoTooltip();
        });
    });
}

async function initializeExchangeRate() {
    xshinToSolRate = await fetchXshinToSolRate();
    const exchangeRateElement = document.getElementById('exchangeRate');
    if (exchangeRateElement) {
        exchangeRateElement.textContent = xshinToSolRate.toFixed(4);
    }
}

function attachDetailsPaneListeners(){
	document.addEventListener('click', (event) => {
        const detailsPane = document.getElementById('validator-details-pane');
        if (isDetailsOpen && detailsPane && !detailsPane.contains(event.target)) {
            toggleDetailsPane(currentOpenPubkey, false, event);
        }
    });
}

//
// REFRESH DATA
//

document.getElementById('refreshDataButton').addEventListener('click', (event) => {
    event.preventDefault();
    refreshValidatorData(true);
}, { once: true });

async function refreshValidatorData(forceRefresh = false) {
    const refreshButton = document.getElementById('refreshDataButton');
    refreshButton.classList.add('rotating');

    const startTime = Date.now();
    const minRotationTime = 1000; // 1 second for a full rotation

    try {
        const latestTimestamp = await fetchLatestTimestamp();
        const currentTimestamp = getAppState('timestamp');

        if (forceRefresh || latestTimestamp !== currentTimestamp || !getAppState('cache') || !getAppState('cache').pool) {
            try {
                const [{ overview, pool }, nonPoolVoters] = await Promise.all([
                    loadOverviewAndPoolDetails(),
                    loadNonPoolVotersData()
                ]);

                // Create a new Map for validators
                const validators = new Map(pool.pool_voters);

                updateAppState({
                    timestamp: latestTimestamp,
                    cache: {
                        ...getAppState('cache'),
                        overview,
                        pool: {
                            ...pool,
                            validators
                        },
                        nonPoolVoters
                    }
                });

				const timestampElement = document.getElementById('data_timestamp');
				if (timestampElement) {
					const date = new Date(latestTimestamp * 1000);
					timestampElement.textContent = `Data last updated: ${date.toLocaleString()}`;
				}

            } catch (error) {
                console.error('Error refreshing data:', error);
            }
        }
    } catch (error) {
        console.error('Error refreshing data:', error);
    } finally {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < minRotationTime) {
            await new Promise(resolve => setTimeout(resolve, minRotationTime - elapsedTime));
        }
        refreshButton.classList.remove('rotating');
    }
}

//
// NAVIGATION
//

function handleUrlParams() {
    if (initialUrlParamsHandled) {
        return;
    }
    initialUrlParamsHandled = true;

    const hash = window.location.hash.slice(1);
    const [tabName, params] = hash.split('?');
    const urlParams = new URLSearchParams(params);
    const pubkey = urlParams.get('pubkey');
    if (pubkey && tabName === 'Validators') {
        waitForValidatorsToLoad().then(async () => {
            const poolValidators = getAppState().cache.pool?.validators;
            const isInPool = poolValidators && poolValidators.has(pubkey);

            if (isInPool) {
                document.getElementById('pool-validators').checked = true;
                await handleValidatorTypeChange('pool', true);
            } else {
                document.getElementById('all-validators').checked = true;
                await handleValidatorTypeChange('all', true);
            }

            // Force a search for the pubkey
            const validatorSearch = document.getElementById('validatorSearch');
            validatorSearch.value = pubkey;
            await searchValidators();

            scrollToValidator(pubkey);
        });
    }
}

function waitForValidatorsToLoad() {
    return new Promise((resolve) => {
        const checkValidators = () => {
            if (getAppState().cache.pool?.validators) {
                resolve();
            } else {
                requestAnimationFrame(checkValidators);
            }
        };
        checkValidators();
    });
}

// Header offsets
/* Header row positions */ // in main.css

function calculateExtraPadding() {
    if (window.innerWidth > 1024) {
        return 226;
    } else if (window.innerWidth > 768) {
        return 222;
    } else if (window.innerWidth > 480) {
        return 256;
    } else {
        return 224;
    }
}

function scrollToRowBelowFixedHeader(rowElement, fromHomePage = false) {
    isScrollingProgrammatically = true;
    const header = document.querySelector('.fixed-header');
    const headerHeight = header ? header.offsetHeight : 0;
    const extraPadding = calculateExtraPadding();

    const rowPosition = rowElement.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = rowPosition - headerHeight - extraPadding;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });

    // Reset the flag after scrolling is complete
    setTimeout(() => {
        isScrollingProgrammatically = false;
    }, 1000);
}

// Prevent default scrolling when coming from homepage or URL
window.addEventListener('scroll', (e) => {
    if (isScrollingProgrammatically) {
        e.preventDefault();
        e.stopPropagation();
    }
}, { passive: false });

// Call this function when the page loads
window.addEventListener('load', () => {
    initialLoadComplete = true;
    handleUrlParams();
});

// Handle back/forward navigation
window.addEventListener('popstate', handleUrlParams);

// Navigation Tabs
globalThis.openTab = async function(evt, initialTabName) {

    if (evt) {
        evt.preventDefault();
    }

    const appState = getAppState();

    // Check if the tab is already open
    if (initialTabName === appState.currentTab && !isInitialLoad) {
		return;
	}

    // Preserve the search input
    const searchInput = document.getElementById('validatorSearch');
    const searchValue = searchInput ? searchInput.value : '';

    updateAppState({ currentTab: initialTabName });

    const hash = window.location.hash.slice(1);
    let [tabName, params] = hash.split('?');
    tabName = initialTabName || tabName || 'Home';

	// Immediately update UI
	updateTabUI(tabName, params);

	if (tabName === 'Validators') {
		document.body.classList.add('dark-background');
		await loadValidatorsTab();
		const pubkeyFromUrl = new URLSearchParams(params).get('pubkey');
		if (pubkeyFromUrl) {
			await scrollToValidator(pubkeyFromUrl);
		}

		if (searchInput && searchValue.trim() !== '') {
			searchInput.value = searchValue;
			await searchValidators();
		}
		const validatorType = getAppState().validatorType || 'all';
		handleValidatorTypeChange(validatorType);

		try {
			const latestTimestamp = await fetchLatestTimestamp();
			const currentTimestamp = getAppState('timestamp');

			if (!getAppState().dataLoaded || latestTimestamp !== currentTimestamp) {
				showLoadingSpinner();
				await refreshValidatorData(true);
				hideLoadingSpinner();
				await loadValidatorsTab(true); // Reload with fresh data
			}

			if (shouldOpenValidatorDetails) {
				scrollToValidator(pubkeyFromUrl);
				shouldOpenValidatorDetails = false;
			}
		} catch (error) {
			console.error('Error loading validators tab:', error);
		}
	} else {
		document.body.classList.remove('dark-background');
		try {
			await loadTabContent(tabName);
		} catch (error) {
			console.error(`Error loading content for tab ${tabName}:`, error);
		}
	}

	handleUrlParams();
	if (tabName != 'Validators'){
		clearSearch();
	}

	displayRandomCharacterImage();
}

function updateTabUI(tabName, params) {
    // Update the URL without reloading the page
    if (tabName === 'Validators' && params) {
        history.pushState(null, '', `#${tabName}?${params}`);
    } else {
        history.pushState(null, '', `#${tabName}`);
    }

    // Hide all tab content
    document.querySelectorAll(".stake-pool-tab-content").forEach(content => {
        content.style.display = "none";
        content.classList.remove('show');
    });

    // Remove active class from all tab links
    document.querySelectorAll(".tab-link").forEach(link => {
        link.className = link.className.replace(" active", "");
    });

    // Show the selected tab content
    const selectedContent = document.getElementById(tabName);
    if (selectedContent) {
        selectedContent.style.display = "block";
        selectedContent.classList.add('show');
    }

    // Mark the tab link as active
    const activeTabLink = document.querySelector(`.tab-link[onclick*='${tabName}']`);
    if (activeTabLink) {
        activeTabLink.classList.add("active");
    }

    // Update the dropdown menu selection
    const tabDropdown = document.getElementById('tabDropdown');
    if (tabDropdown) {
        tabDropdown.value = tabName;
    }
}

async function loadTabContent(tabName) {
	try {
		switch(tabName) {
			case 'Validators':
				await loadValidatorsTab();
				break;
			case 'Dashboard':
				await loadDashboardTab();
				break;
			case 'FAQ':
				await loadFAQTab();
				break;
			case 'DeFi':
				await loadDefiTab();
				break;
			case 'Strategy':
				document.getElementById('Strategy').style.display = 'block';
				scrollToTopOfPage();
				break;
			case 'Press':
				await loadPressTab();
				break;
			case 'Privacy':
				await loadPrivacyTab();
				break;
			case 'Terms':
				await loadTermsTab();
				break;
			case 'Staking':
				await loadStakingTab();
				break;
			case 'Home':
				await loadHomeTab();
				break;
			default:
				console.warn(`No specific loading function for tab: ${tabName}`);
		}
	} catch (error) {
		console.error(`Error loading tab ${tabName}:`, error);
		reject(error);
	}
}

async function loadHomeTab() {
    const appState = getAppState();

    if (appState.cache.overview && appState.cache.pool) {
        // Update home page stats
        document.getElementById('apyValue').textContent = (appState.cache.overview.apy * 100).toFixed(2);
        document.getElementById('solStaked').textContent = Number(appState.cache.overview.pool_stake.active / BigInt(1e9)).toFixed(0);
        document.getElementById('validatorCount').textContent = appState.cache.pool.pool_validator_count.toString();

        // Initialize charts
        try {
            await initializeCharts();
        } catch (error) {
            console.error("Error initializing charts:", error);
        }

        // Display awards
        try {
            displayAwards();
        } catch (error) {
            console.error("Error displaying awards:", error);
        }
    } else {
        console.error("App state cache is missing necessary data for home page stats.");
    }

    const chartContainer = document.querySelector('.chart-container');
    if (chartContainer) {
        chartContainer.style.animation = 'none';
        chartContainer.offsetHeight; // Trigger reflow
        chartContainer.style.animation = 'riseUp 0.5s ease-out forwards';
        chartContainer.style.transform = 'translateY(50px)';
        chartContainer.style.opacity = '0';
    }
    scrollToTopOfPage();
}

async function loadValidatorsTab(refreshData = true) {

    const appState = getAppState();

    if (!refreshData && appState.validatorsLoaded) {
        return;
    }

    showLoadingSpinner();
    try {
        if (refreshData) {
            await refreshValidatorData(true);
        }

        updateValidatorTypeRadioButtons();

        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        const pubkeyFromUrl = urlParams.get('pubkey');

        let dataset = getAppState('lastUsedDataset') || 'pool';

        if (pubkeyFromUrl) {
            const poolValidators = getAppState().cache.pool?.validators;
            const isInPool = poolValidators && poolValidators.has(pubkeyFromUrl);
            dataset = isInPool ? 'pool' : 'all';
            updateValidatorTypeRadioSilently(dataset);
        }

        updateAppState({ lastUsedDataset: dataset });

        let validatorData;
        if (dataset === 'pool') {
            validatorData = getAppState().cache.pool?.validators;
        } else {
            // Ensure we have non-pool data loaded
            if (!getAppState().cache.nonPoolVoters) {
                await loadNonPoolVotersData();
            }
            validatorData = mergeValidatorData();
        }

        if (validatorData && validatorData.size > 0) {
			const validatorArray = Array.from(validatorData);
			const filteredData = filterValidators(dataset, getAppState('searchQuery'), validatorArray);

			await populateValidatorRows(filteredData);

			updateValidatorTypeRadioButtons();

            sortValidators(getAppState('activeSortColumn') || 'details.total_score', 'desc');

			updateAppState({ validatorsPopulated: true });

            if (pubkeyFromUrl && !isScrollingToValidator) {
                shouldOpenValidatorDetails = true;
            }
        } else {
            console.error('No validator data available');
            throw new Error('No validator data available');
        }
    } catch (error) {
        console.error('Error loading validators tab:', error);
    } finally {
        hideLoadingSpinner();
    }

	// Call sortValidators after populating
    sortValidators(getAppState('activeSortColumn') || 'details.total_score', 'desc');

	updateAppState({ validatorsLoaded: true });
}

async function loadStakingTab() {
    const publicKey = selectedWallet ? selectedWallet.publicKey : null;
    updateStakingUI(!!publicKey);

    // Only populate if not already populated
    if (document.getElementById('stakeAccountSelect').options.length <= 1) {
        populateStakeAccountDropdown();
    }

    // Update the estimate for the selected account
    const selectedOption = document.getElementById('stakeAccountSelect').selectedOptions[0];
    if (selectedOption && selectedOption.dataset.balance) {
        const balance = parseFloat(selectedOption.dataset.balance);
        if (!isNaN(balance)) {
            const estimate = await simulateStakeSolToStakeAccount(balance);
            document.getElementById('stakeToXshinEstimate').textContent = `~${estimate.toFixed(3)}`;
        }
    }
    renderStaking();
    scrollToTopOfPage();
}

async function loadDashboardTab() {
    updateDashboardStats();
    scrollToTopOfPage();
}

async function loadFAQTab() {
    renderAwards();
    scrollToTopOfPage();
}

async function loadStrategyTab() {
    renderStrategy();
    scrollToTopOfPage();
}

async function loadDefiTab() {
    renderDefiProjects();
    scrollToTopOfPage();
}

async function loadPressTab() {
    renderPress();
    scrollToTopOfPage();
}

async function loadPrivacyTab() {
    renderPrivacy();
    scrollToTopOfPage();
}

async function loadTermsTab() {
    renderTerms();
    scrollToTopOfPage();
}

function scrollToTopOfPage() {
	// Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

//
// HEADER
//

// Dynamic stats update
function updateHeaderStats() {
    const appState = getAppState();
    if (appState.cache.overview && appState.cache.pool) {
        const apyValue = document.getElementById('apyValue');
        const Staked = document.getElementById('solStaked');
        const validatorCount = document.getElementById('validatorCount');

        const apy = appState.cache.overview.apy;
        const poolStakeActive = appState.cache.overview.pool_stake.active;
        const poolValidatorCount = appState.cache.pool.pool_validator_count;

        const apyPercentage = (apy * 100).toFixed(2);
        const solStakedFormatted = Number(poolStakeActive / BigInt(1e9)).toFixed(0);
        const validatorCountFormatted = Number(poolValidatorCount);

        // Animate to current data
        animateValue('apyValue', 0, parseFloat(apyPercentage), 1000, 2);
        animateValue('solStaked', 0, parseFloat(solStakedFormatted), 1000, 0);
        animateValue('validatorCount', 0, validatorCountFormatted, 1000, 0);
    } else {
        console.error("App state cache is missing necessary data for dashboard stats.");
    }
}

// Animate values in header
function animateValue(id, start, end, duration, decimalPlaces = 0) {
    let obj = document.getElementById(id);
    let range = end - start;
    let minTimer = 50; // Minimum frame rate (20fps)
    let stepTime = Math.abs(Math.floor(duration / range));

    stepTime = Math.max(stepTime, minTimer);

    let startTime = new Date().getTime();
    let endTime = startTime + duration;
    let timer;

    function run() {
        let now = new Date().getTime();
        let remaining = Math.max((endTime - now) / duration, 0);
        let value = end - (remaining * range);
        // Format the value with specified decimal places for display
        obj.textContent = value.toLocaleString(undefined, { maximumFractionDigits: decimalPlaces });

        if (remaining <= 0) {
            clearInterval(timer);
        }
    }

    timer = setInterval(run, stepTime);
    run();
}

//
// HOME PAGE HERO SECTION
//

// Applying hover effects
document.querySelectorAll('.section-image').forEach(image => {
    image.addEventListener('mouseenter', () => {
        image.style.transform = 'scale(1.1)';
    });

    image.addEventListener('mouseleave', () => {
        image.style.transform = 'scale(1)';
    });
});

// Adding animations to elements based on scroll visibility
function addScrollInAnimation(element, animationClass) {
    if (element.classList.contains('in-view')) {
        element.classList.add(animationClass);
    } else {
        element.classList.remove(animationClass);
    }
}

// Detecting scroll event to apply animations
window.addEventListener('scroll', () => {
    document.querySelectorAll('.section-headline').forEach(headline => {
        addScrollInAnimation(headline, 'fade-in-animation');
    });

    document.querySelectorAll('.section-subheader').forEach(subheader => {
        addScrollInAnimation(subheader, 'slide-up-animation');
    });

    document.querySelectorAll('.section-description').forEach(description => {
        addScrollInAnimation(description, 'slide-down-animation');
    });
});

//
// COMPARISON CHARTS
//

function isMobileView() {
    const mobile = window.innerWidth <= 480;
    return mobile;
}

const awardPlugin = {
    id: 'awardPlugin',
    afterDatasetsDraw: function (chart, args, options) {
        const ctx = chart.ctx;
        const isLowerBetter = chart.canvas.id === 'lowValueChart';
        const isMobile = isMobileView();
        const iconScale = isMobile ? 0.6 : 1.0; // Adjust scale as needed

        if (chart.data && chart.data.datasets.length > 1) {
            // Loop through "our" dataset values
            chart.data.datasets[isLowerBetter ? 0 : 1].data.forEach((oursValue, index) => {
                const theirsValue = chart.data.datasets[isLowerBetter ? 1 : 0].data[index];
                let percentageDifference;
                let starShouldAppearOnOurs = false;

                percentageDifference = ((oursValue - theirsValue) / theirsValue * 100).toFixed(1);

                if (isLowerBetter) {
                    starShouldAppearOnOurs = percentageDifference <= -20;
                } else {
                    starShouldAppearOnOurs = percentageDifference >= 20;
                }

                if (starShouldAppearOnOurs) {
                    const meta = chart.getDatasetMeta(isLowerBetter ? 0 : 1);
                    const bar = meta.data[index];
                    drawStar(ctx, bar, chart, isLowerBetter, iconScale);
                }
            });
        }
    }
};

function drawStar(ctx, bar, chart, isLowerBetter, scale) {
    const isMobile = isMobileView();
    const ctxScale = isMobile ? 0.8 : 1.0; // Smaller scale for mobile

    const maxWidth = bar.width * 0.8 * scale * ctxScale;
    const maxHeight = 40 * scale * ctxScale;
    const aspectRatio = awardImage.width / awardImage.height;
    let drawWidth = maxWidth;
    let drawHeight = drawWidth / aspectRatio;
    if (drawHeight > maxHeight) {
        drawHeight = maxHeight;
        drawWidth = maxHeight * aspectRatio;
    }

    drawWidth = Math.min(drawWidth, maxWidth);
    drawHeight = drawWidth / aspectRatio;

    // Center the icon by adjusting the x position
    const x = bar.x - (drawWidth / 2);
    const yAdjustment = isLowerBetter ? 5 * scale * ctxScale : 5 * scale * ctxScale;
    const y = bar.base - yAdjustment - drawHeight;

    ctx.drawImage(awardImage, x, y, drawWidth, drawHeight);
}

// Initialize comparison charts
async function initializeCharts() {
    const poolDetails = getAppState('cache').pool;
    if (poolDetails) {
        const { highValueData, oursHighData, theirsHighData, lowValueData, oursLowData, theirsLowData } = transformPoolDetailsToChartData(poolDetails);

		const isMobile = isMobileView();
        const highValueConfig = getHighValueConfig(isMobile, highValueData, oursHighData, theirsHighData);
        const lowValueConfig = getLowValueConfig(isMobile, lowValueData, oursLowData, theirsLowData);

        // Destroy existing charts if they exist
        if (window.highValueChart instanceof Chart) {
            window.highValueChart.destroy();
        }
        if (window.lowValueChart instanceof Chart) {
            window.lowValueChart.destroy();
        }

        const highValueCtx = document.getElementById('highValueChart');
        const lowValueCtx = document.getElementById('lowValueChart');

        if (highValueCtx && lowValueCtx) {
            window.highValueChart = new Chart(highValueCtx.getContext('2d'), highValueConfig);
            window.lowValueChart = new Chart(lowValueCtx.getContext('2d'), lowValueConfig);

            ['click', 'touchstart'].forEach(eventType => {
                window.highValueChart.canvas.addEventListener(eventType, function(event) {
                    const elements = window.highValueChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
                    if (elements.length) {
                        const firstPoint = elements[0];
                        window.highValueChart.tooltip.setActiveElements([firstPoint]);
                        window.highValueChart.update();
                    }
                });

                window.lowValueChart.canvas.addEventListener(eventType, function(event) {
                    const elements = window.lowValueChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
                    if (elements.length) {
                        const firstPoint = elements[0];
                        window.lowValueChart.tooltip.setActiveElements([firstPoint]);
                        window.lowValueChart.update();
                    }
                });
            });
        } else {
            console.error("Chart canvas elements not found");
        }
    } else {
        console.error("Failed to load pool details.");
    }
}

// Transform pool details to chart data format
function transformPoolDetailsToChartData(poolDetails) {
    const highValueLabels = [
        ['Compute', 'Units'],
        ['Low Latency', 'Votes'],
        ['Consensus', 'Votes'],
        ['Vote', 'Inclusion']
    ];

    const oursHighData = [
        poolDetails.compare_by_current.spp_raw.cu,
        poolDetails.compare_by_current.spp_raw.llv,
        poolDetails.compare_by_current.spp_raw.cv,
        poolDetails.compare_by_current.spp_raw.vote_inclusion
    ];

    const theirsHighData = [
        poolDetails.compare_by_current.other_raw.cu,
        poolDetails.compare_by_current.other_raw.llv,
        poolDetails.compare_by_current.other_raw.cv,
        poolDetails.compare_by_current.other_raw.vote_inclusion
    ];

	const isMobile = isMobileView();

    const normalizedTheirsHighData = theirsHighData.map((value, index) => (value / oursHighData[index]) * 100);
    const normalizedOursHighData = oursHighData.map(() => 100);

    const highValueData = {
        labels: highValueLabels,
        datasets: [
            {
                label: 'Theirs',
                data: normalizedTheirsHighData,
                backgroundColor: '#353b4e',
                borderColor: '#2b2e3a',
                borderWidth: 1,
                order: 1,
                barThickness: (context) => {
					return context.chart.width < 600 ? 30 : 50;
				},
                categoryPercentage: 0.8,
                barPercentage: .9,
            },
            {
                label: 'Ours',
                data: normalizedOursHighData, // Ours is always 100%
                backgroundColor: '#dfa754',
                borderColor: '#b58341',
                borderWidth: 1,
                order: 2,
                barThickness: (context) => {
					return context.chart.width < 600 ? 30 : 50;
				},
                categoryPercentage: 0.8,
                barPercentage: .9,
            }
        ]
    };

    // Low Value Metrics
    const lowValueLabels = [
        ['Skip Rate'],
        ['Latency'],
        ['City', 'Concentration'],
        ['Country', 'Concentration']
    ];

    const oursLowData = [
        poolDetails.compare_by_current.spp_raw.skip_rate,
        poolDetails.compare_by_current.spp_raw.latency,
        poolDetails.compare_by_current.spp_raw.city_concentration,
        poolDetails.compare_by_current.spp_raw.country_concentration
    ];

    const theirsLowData = [
        poolDetails.compare_by_current.other_raw.skip_rate,
        poolDetails.compare_by_current.other_raw.latency,
        poolDetails.compare_by_current.other_raw.city_concentration,
        poolDetails.compare_by_current.other_raw.country_concentration
    ];

    const normalizedOursLowData = oursLowData.map((value, index) => (value / theirsLowData[index]) * 100);
    const normalizedTheirsLowData = theirsLowData.map(() => 100);


    // Normalize Data for Chart.js
    const lowValueData = {
        labels: lowValueLabels,
        datasets: [
            {
                label: 'Ours',
                data: normalizedOursLowData, // Normalized "Ours" data
                backgroundColor: '#dfa754',
                borderColor: '#b58341',
                borderWidth: 1,
                order: 2,
                barThickness: (context) => {
					return context.chart.width < 600 ? 30 : 50;
				},
                categoryPercentage: 0.8,
                barPercentage: .9,
            },
            {
                label: 'Theirs',
                data: normalizedTheirsLowData, // "Theirs" is always 100%
                backgroundColor: '#353b4e',
                borderColor: '#2b2e3a',
                borderWidth: 1,
                order: 1,
                barThickness: (context) => {
					return context.chart.width < 600 ? 30 : 50;
				},
                categoryPercentage: 0.8,
                barPercentage: .9,
            }
        ]
    };

    return {
        highValueData,
        oursHighData,
        theirsHighData,
        lowValueData,
        oursLowData,
        theirsLowData
    };
}

function getLowValueConfig(isMobile, lowValueData, oursLowData, theirsLowData) {
    return {
        type: 'bar',
        data: lowValueData,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    stacked: false,
                    ticks: {
                        font: {
                            size: isMobile ? 8 : 12,
                        },
                        maxRotation: 0,
                        minRotation: 0
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        display: false,
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Lower is Better',
                    padding: {
                        top: 10,
                        bottom: 30
                    },
                    font: {
                        size: 18
                    },
                    color: '#353b4e'
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const actualValue = context.datasetIndex === 0 ? oursLowData[context.dataIndex] : theirsLowData[context.dataIndex];
                            let label = context.chart.data.labels[context.dataIndex];
                            let formattedValue;

                            if (Array.isArray(label)) {
                                label = label.join(" ");
                            }

                            switch (true) {
                            case label.includes('Skip Rate'):
                                formattedValue = percent_string(actualValue, 2)
                                break;
                            case label.includes('Latency'):
                                formattedValue = `${actualValue.toFixed(3)} ms`;
                                break;
                            case label.includes('City Concentration'):
                            case label.includes('Country Concentration'):
                                formattedValue = percent_string(actualValue, 2);
                                break;
                            default:
                                formattedValue = `${actualValue}`;
                            }

                            return `${context.dataset.label}: ${formattedValue}`;
                        },
                        title: function (tooltip_item_array) {
                            if (tooltip_item_array.length > 0) {
                                return tooltip_item_array[0].label.replace(",", " ");
                            }
                            else {
                                return "";
                            }
                        }
                    },
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    color: '#353b4e',
                    anchor: 'end',
                    align: 'top',
                    display: function (context) {
                        return context.datasetIndex === 0;
                    },
                    formatter: function (value, context) {
                        const oursValue = context.dataset.data[context.dataIndex];
                        const theirsValue = context.chart.data.datasets[1].data[context.dataIndex];
                        const percentageDifference = ((oursValue - theirsValue) / theirsValue * 100).toFixed(1);
                        return percentageDifference > 0 ? `+${percentageDifference}%` : `${percentageDifference}%`;
                    },
                    font: {
                        weight: 'bold',
                        size: 12
                    },
                    offset: 0,
                }
            },
			barThickness: isMobile ? 20 : 50,
        },
        plugins: [awardPlugin, ChartDataLabels],
    };
}

function getHighValueConfig(isMobile,highValueData, oursHighData, theirsHighData) {
    return {
        type: 'bar',
        data: highValueData,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    stacked: false,
                    ticks: {
                        font: {
                            size: 14,
                        },
                        maxRotation: 0,
                        minRotation: 0
                    }
                },
                y: {
                    stacked: false,
                    beginAtZero: true,
                    ticks: {
                        display: false,
                    }
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Higher is Better',
                    padding: {
                        top: 10,
                        bottom: 30
                    },
                    font: {
                        size: 18
                    },
                    color: '#353b4e'
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const actualValue = context.datasetIndex === 1 ? oursHighData[context.dataIndex] : theirsHighData[context.dataIndex];
                            let label = context.chart.data.labels[context.dataIndex];
                            let formattedValue;

                            if (Array.isArray(label)) {
                                label = label.join(" ");
                            }

                            if (label.includes('Compute Units')) {
                                formattedValue = `${Math.floor(actualValue).toLocaleString()} CU`;
                            } else if (label.includes('Low Latency') || label.includes('Consensus Votes')) {
                                formattedValue = percent_string(actualValue, 2);
                            } else if (label.includes('Vote Inclusion')) {
                                formattedValue = `${Math.floor(actualValue)} Tx`;
                            } else if (label.includes('APY')) {
                                formattedValue = percent_string(actualValue, 2);
                            } else if (label.includes('SOL Added')) {
                                formattedValue = percent_string(actualValue, 2);
                            } else {
                                formattedValue = `${actualValue}`;
                            }

                            return `${context.dataset.label}: ${formattedValue}`;
                        },
                        title: function (tooltip_item_array) {
                            if (tooltip_item_array.length > 0) {
                                return tooltip_item_array[0].label.replace(",", " ");
                            }
                            else {
                                return "";
                            }
                        }
                    }
                },
                legend: { display: false },
                datalabels: {
                    color: '#353b4e',
                    anchor: 'end',
                    align: 'top',
                    display: function (context) {
                        return context.datasetIndex === 1;
                    },
                    formatter: function (value, context) {
                        const oursValue = context.dataset.data[context.dataIndex];
                        const theirsValue = context.chart.data.datasets[0].data[context.dataIndex];
                        const percentageDifference = ((oursValue - theirsValue) / theirsValue * 100).toFixed(1);
                        return percentageDifference > 0 ? `+${percentageDifference}%` : `${percentageDifference}%`;
                    },
                    font: {
                        weight: 'bold',
                        size: 12
                    },
                    offset: 0,
                }
            },
			barPercentage: isMobile ? 0.32 : 0.8,
            categoryPercentage: isMobile ? 0.7 : 0.8
        },
        plugins: [awardPlugin, ChartDataLabels],
    };
}

//
// AWARDS
//

const awardCategories = [
    {
        id: "bestSkipRate",
        title: "Champion Leaders",
        description: "Best Skip Rate",
        trophyImage: "images/trophies/3d-icon-skip-rate-4.png",
        awardKey: "best_skip_rate",
        tooltip: "Skip rate is the percentage of leader slots in which a validator was scheduled to produce a block but failed to do so.  For validators with fewer than 100 leader slots, it is blended with the cluster average to reduce variance.",
        higherIsBetter: false
    },
    {
        id: "bestComputeUnits",
        title: "Best Block Packers",
        description: "Best Compute Units",
        trophyImage: "images/trophies/3d-icon-best-block-packer-3.png",
        awardKey: "best_cu",
        tooltip: "Compute Units measures the total execution size of transactions and smart contracts included in blocks produced by validators.  For validators with fewer than 25 blocks, it is blended with the cluster average to reduce variance.",
        higherIsBetter: true
    },
    {
        id: "bestLatency",
        title: "Voting Speed Demons",
        description: "Best Latency",
        trophyImage: "images/trophies/3d-icon-voting-speed-demons-3.png",
        awardKey: "best_latency",
        tooltip: "Latency in voting refers to the time it takes for a validator's vote to be recorded on the blockchain. Lower latency ensures faster consensus and strengthens network reliability.",
        higherIsBetter: false
    },
    {
        id: "bestLowLatencyVote",
        title: "Voting Quality Heroes",
        description: "Best Low Latency Vote Percentage",
        trophyImage: "images/trophies/3d-icon-voting-quality-heroes.png",
        awardKey: "best_llv",
        tooltip: "Low Latency Voting Percentage reflects the efficiency and speed at which validators confirm transactions and participate in consensus, crucial for network reliability and performance.  More specifically, it is the percentage of a validator's votes which are processed within 2 slots of the block they are voting on.",
        higherIsBetter: true
    },
    {
        id: "bestConsensusVoting",
        title: "Consensus Masters",
        description: "Best Consensus Voting Percentage",
        trophyImage: "images/trophies/3d-icon-consensus-masters.png",
        awardKey: "best_cv",
        tooltip: "Consensus Voting Percentage signifies a validator's participation and alignment with the network's consensus on transaction validation. More specifically, it is the percentage of votes that a validator casts before the block being voted on has achieved consensus.  It is crucial for sustaining network integrity and security.",
        higherIsBetter: true
    },
    {
        id: "bestVoteInclusion",
        title: "Best Vote Gatherers",
        description: "Most Votes Included in Blocks",
        trophyImage: "images/trophies/3d-icon-treasure-hunter.png",
        awardKey: "best_vote_inclusion",
        tooltip: "Validators should include as many vote transactions as possible in their blocks to ensure that blocks are confirmed and finalized quickly.  For validators with fewer than 25 blocks, it is blended with the cluster average to reduce variance.",
        higherIsBetter: true
    },
    {
        id: "bestAPY",
        title: "Superior Stake Rewarders",
        description: "Best APY",
        trophyImage: "images/trophies/3d-icon-superior-stake-rewarders.png",
        awardKey: "best_apy",
        tooltip: "APY (Annual Percentage Yield) measures the real rate of return on your principal amount by taking into account the effect of compounding interest. In the context of staking, it represents the annualized return a delegator can expect on their staked tokens.",
        higherIsBetter: true
    },
    {
        id: "bestMevSuperstars",
        title: "MEV Superstars",
        description: "Most Extra Added SOL",
        trophyImage: "images/trophies/3d-icon-mev-superstars.png",
        awardKey: "best_pool_extra_lamports",
        tooltip: "Extra Added SOL is the product of MEV activities that allow the validator to share tips with stakers.  Validators that share more of these tips with stakers have higher Extra Added SOL and earn more points in this category.",
        higherIsBetter: true
    },
    {
        id: "bestCityConcentration",
        title: "Lone Rangers",
        description: "Best City Concentration",
        trophyImage: "images/trophies/3d-icon-lone-rangers.png",
        awardKey: "best_city_concentration",
        tooltip: "City Concentration refers to the distribution of a validator's infrastructure within data centers in specific cities. A high City Concentration means that a validator's servers are located in the same city as many other validators, potentially increasing the risk to Solana of events that could affect an entire city.",
        higherIsBetter: false
    },
    {
        id: "bestOverall",
        title: "Shinobi Performance Royalty",
        description: "Best Overall",
        trophyImage: "images/trophies/3d-icon-performance-royalty.png",
        awardKey: "best_overall",
        tooltip: "The Overall rating encompasses a comprehensive evaluation of validators' performance, including uptime, voting accuracy, stake distribution, contribution to network security, and efforts towards promoting decentralization. It represents the pinnacle of excellence and reliability within the Solana validator community.",
        higherIsBetter: true
    }
];

function displayAwards() {
    const poolDetails = getAppState().cache.pool;
    if (!poolDetails) {
        console.error("Pool data is not available.");
        return;
    }
    const awardsContainer = document.getElementById('awards-section');
	awardsContainer.innerHTML = '';

    awardCategories.forEach((category) => {
        const awardContainer = document.createElement('div');
        awardContainer.className = 'award-container';

        const awardFlipper = document.createElement('div');
        awardFlipper.className = 'award-flipper';

        const awardFront = document.createElement('div');
        awardFront.className = 'award';

        const awardImage = document.createElement('img');
        awardImage.src = category.trophyImage;
        awardImage.alt = `${category.title} Award`;
        awardImage.className = 'award-image';
        awardFront.appendChild(awardImage);

        const awardTitle = document.createElement('h3');
        awardTitle.textContent = category.title;
        awardFront.appendChild(awardTitle);

        const awardDescription = document.createElement('p');
        awardDescription.innerHTML = `${category.description}`;
        awardFront.appendChild(awardDescription);

        const validatorIconsTable = document.createElement('table');
        validatorIconsTable.className = 'award-validators';
        const validatorIconsRow = document.createElement('tr');
        validatorIconsRow.className = 'validator';

        // Adding each validator icon to the row
        const winners = poolDetails[category.awardKey];
        if (winners && winners.length > 0) {
            winners.forEach(winner => {
                const validator = poolDetails.pool_voters.get(winner.pubkey) || {};
                const validatorDetails = validator.details || {};

                const validatorIconContainer = document.createElement('td');
                validatorIconContainer.className = 'validator-icon-container';

                const validatorIcon = document.createElement('img');
                let iconUrl = validatorDetails.icon_url ? validatorDetails.icon_url : 'images/validators/validator-icon_missing_3.png';
                let isNewStyleValidatorIcon = iconUrl.startsWith('**icon_url**');
                if (isNewStyleValidatorIcon) {
                    iconUrl = iconUrl.replace('**icon_url**', '');
                }
                validatorIcon.onerror = function() {
                    this.src = 'images/validators/validator-icon_missing_3.png';
                    this.className = 'validator-icon-circle validator-icon';
                    this.style.borderRadius = '50%';
                };
                validatorIcon.src = iconUrl;
                validatorIcon.alt = '';
                validatorIcon.className = (isNewStyleValidatorIcon ? '' : 'validator-icon-circle ') + 'validator-icon';
                validatorIcon.referrerPolicy = 'no-referrer';
                validatorIcon.setAttribute('data-pubkey', winner.pubkey);
                validatorIconContainer.appendChild(validatorIcon);

                validatorIconsRow.appendChild(validatorIconContainer);

                createMetricsCard(awardFront, validatorDetails, winner, category.id);
                attachHoverListener(validatorIconContainer, winner.pubkey, category.id);
            });
        }

        validatorIconsTable.appendChild(validatorIconsRow);
        awardFront.appendChild(validatorIconsTable);

        const awardBack = document.createElement('div');
        awardBack.className = 'award-back';
        awardBack.innerHTML = `
            <div class="validator-award-details">
            <h3>${category.title}</h3>
            <p>${category.tooltip}</p>
            <a href="#${category.id}" class="learn-more-link">Learn More</a>
            </div>
        `;

        awardFlipper.appendChild(awardFront);
        awardFlipper.appendChild(awardBack);
        awardContainer.appendChild(awardFlipper);

        awardsContainer.appendChild(awardContainer);

        attachFlipListener(awardContainer);
        attachValidatorIconListeners();
    });
}


function createMetricsCard(parentContainer, validatorDetails, winner, awardId) {
    const overlayId = `validator-details-overlay-${winner.pubkey}-${awardId}`;
    const overlay = document.createElement('div');
    overlay.id = overlayId;
    overlay.className = 'validator-details-overlay';

    // Determine the formatted metric based on the awardId
    let formattedMetric;
    switch (awardId) {
    case "bestSkipRate":
        formattedMetric = percent_string(winner.metric, 2);
        break;
    case "bestComputeUnits":
        formattedMetric = Math.floor(winner.metric).toString() + " CU";
        break;
    case "bestLatency":
        formattedMetric = winner.metric.toFixed(2) + " slots";
        break;
    case "bestLowLatencyVote":
    case "bestConsensusVoting":
    case "bestAPY":
    case "bestCityConcentration":
    case "bestCountryConcentration":
    case "bestMevSuperstars":
        formattedMetric = percent_string(winner.metric, 2);
        break;
    case "bestVoteInclusion":
        formattedMetric = Math.floor(winner.metric).toString() + " tx";
        break;
    default:
        formattedMetric = winner.metric.toFixed(2);
    }

    overlay.innerHTML = `
        <div class="overlay-content">
            <p class="validator-name">${validatorDetails.name || winner.pubkey}</p>
            <p class="validator-score"><strong>#${winner.ranking}:</strong> ${formattedMetric}</p>
        </div>
    `;

    parentContainer.appendChild(overlay);
}

function toggleValidatorIconsVisibility(flipper, isFlipped) {
    const validatorIcons = flipper.parentElement.querySelectorAll('.validator-icon');
    validatorIcons.forEach(icon => {
        icon.style.visibility = isFlipped ? 'hidden' : 'visible';
    });
}

function attachFlipListener(awardElement) {
    const flipper = awardElement.querySelector('.award-flipper');
    if (!flipper) {
        console.error('Flipper element not found within the award element.');
        return;
    }

    const awardFront = flipper.querySelector('.award');
    const awardBack = flipper.querySelector('.award-back');

    // Initialize the flipping state as false
    flipper.isFlipping = false;

    flipper.addEventListener('click', function (event) {
        const isFlipped = flipper.classList.toggle('flipped');
        flipper.isFlipping = true; // Indicate the card is currently flipping

        if (isFlipped) {
            awardFront.style.display = 'none';
            awardBack.style.display = 'flex';
        } else {
            awardFront.style.display = 'flex';
            awardBack.style.display = 'none';
        }

        toggleValidatorIconsVisibility(flipper, isFlipped);

        // Use setTimeout to reset the flipping state after the flip transition duration
        setTimeout(() => {
            flipper.isFlipping = false; // Reset the flipping state
        }, 300);
    });

    // Modify the mouseleave event
    flipper.addEventListener('mouseleave', function (event) {
        // Only proceed if the card is not currently flipping
        if (!flipper.isFlipping && flipper.classList.contains('flipped')) {
            flipper.classList.remove('flipped');
            awardFront.style.display = 'flex';
            awardBack.style.display = 'none';
            toggleValidatorIconsVisibility(flipper, false);
        }
    });

    // Consider the scroll behavior based on the new logic
    window.addEventListener('scroll', function () {
        if (!flipper.isFlipping && flipper.classList.contains('flipped')) {
            flipper.classList.remove('flipped');
            awardFront.style.display = 'flex';
            awardBack.style.display = 'none';
            toggleValidatorIconsVisibility(flipper, false);
        }
    }, { passive: true });
}

function attachValidatorIconListeners() {
    const validatorIcons = document.querySelectorAll('.validator-icon, .validator-award-icon');
    validatorIcons.forEach(icon => {
        icon.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            const pubkey = icon.getAttribute('data-pubkey');
            if (pubkey) {
                history.pushState(null, '', `#Validators?pubkey=${pubkey}&type=pool`);
                openTab(null, 'Validators');
            } else {
                console.error('No pubkey found for clicked validator icon');
            }
        });

        // For mobile devices, handle touchstart event
        icon.addEventListener('touchstart', (event) => {
            event.preventDefault();
            event.stopPropagation();
            icon.click();
        });
    });
}

function attachHoverListener(validatorIconContainer, pubkey, awardId) {
    validatorIconContainer.addEventListener('mouseenter', () => {
        const overlay = document.getElementById(`validator-details-overlay-${pubkey}-${awardId}`);
        if (overlay) {
            overlay.classList.add('show'); // Show the overlay
        }
    });
    validatorIconContainer.addEventListener('mouseleave', () => {
        const overlay = document.getElementById(`validator-details-overlay-${pubkey}-${awardId}`);
        if (overlay) {
            overlay.classList.remove('show'); // Hide the overlay
        }
    });
}

//
// VALIDATORS
//

function populateValidatorRows(data) {
    const validatorsTable = document.getElementById('validatorsTable');

    const validatorTableBody = document.getElementById('validatorTableBody');

    validatorTableBody.innerHTML = '';

    if (!Array.isArray(data) || data.length === 0) {
        console.log("Invalid or empty validator data:", data);
        const noDataRow = document.createElement('div');
        noDataRow.className = 'validator-row-container';
        noDataRow.innerHTML = '<div class="validator-row"><td colspan="100%">No validator data available</td></div>';
        validatorTableBody.appendChild(noDataRow);
        return;
    }

    data.forEach(([pubkey, validator], index) => {
        const row = createValidatorRow(pubkey, validator);
        row.style.order = index; // Set the order based on the sorted index
        validatorTableBody.appendChild(row);
    });

    attachRowClickListeners();

	sortValidators(getAppState('activeSortColumn') || 'details.total_score', getAppState('activeSortDirection') || 'desc');
}

function filterValidators(validatorType, searchQuery, validatorData) {
    let filteredData = validatorData;

    if (validatorType === 'pool') {
        filteredData = filteredData.filter(([_, validator]) => validator.pool_stake);
    }

    if (searchQuery) {
        const lowercaseQuery = searchQuery.toLowerCase();
        filteredData = filteredData.filter(([pubkey, validator]) => {
            const nameMatch = validator.details && validator.details.name &&
                              validator.details.name.toLowerCase().includes(lowercaseQuery);
            const pubkeyMatch = pubkey.toLowerCase().includes(lowercaseQuery);
            return nameMatch || pubkeyMatch;
        });
    }

    const sortColumn = getAppState('activeSortColumn') || 'details.total_score';
    const sortDirection = getAppState('activeSortDirection') || 'desc';
    filteredData.sort((a, b) => {
        const aValue = getNestedProperty(a[1], sortColumn);
        const bValue = getNestedProperty(b[1], sortColumn);
        return compareValues(aValue, bValue, sortDirection);
    });

    return filteredData;
}

function compareValues(a, b, direction) {
    if (a === b) return 0;
    if (a === undefined) return direction === 'asc' ? 1 : -1;
    if (b === undefined) return direction === 'asc' ? -1 : 1;

    // Handle BigInt values
    if (typeof a === 'bigint' || typeof b === 'bigint') {
        const aBigInt = BigInt(a);
        const bBigInt = BigInt(b);
        if (direction === 'asc') {
            return aBigInt < bBigInt ? -1 : aBigInt > bBigInt ? 1 : 0;
        } else {
            return bBigInt < aBigInt ? -1 : bBigInt > aBigInt ? 1 : 0;
        }
    }

    // Handle string values
    if (typeof a === 'string' && typeof b === 'string') {
        return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    }

    // Handle number values
    if (typeof a === 'number' && typeof b === 'number') {
        return direction === 'asc' ? a - b : b - a;
    }

    // If types are different or not handled above, convert to strings and compare
    const aStr = String(a);
    const bStr = String(b);
    return direction === 'asc' ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
}

function attachRowClickListeners() {
    document.querySelectorAll('.validator-row-container').forEach((row) => {
        const validatorRow = row.querySelector('.validator-row');
        if (validatorRow) {
            const pubkey = validatorRow.dataset.validatorPubkey;
            row.addEventListener('click', (event) => {
				selectValidatorRow(validatorRow);
                toggleDetailsPane(pubkey, false, event);
				// add a slight delay
				setTimeout(() => {
					scrollToRowBelowFixedHeader(validatorRow, true);
				}, 100);
				history.pushState(null, '', `#Validators?pubkey=${pubkey}`);
            });
        }
    });
}

function hideValidatorDetailsPane() {
    const detailsPane = document.getElementById('validator-details-pane');
    if (detailsPane) {
        detailsPane.innerHTML = '';
        detailsPane.style.display = 'none';
    }
}

async function toggleDetailsPane(pubkey, forceOpen = false, event) {
    let detailsPane = document.getElementById('validator-details-pane');

    // Get the container div for the validator
    const containerDiv = document.getElementById(`validator-${pubkey}`);
    if (!containerDiv) {
        console.error('Target validator row not found');
        return;
    }

    // Get the validator row inside the container
    const validatorRow = containerDiv.querySelector('.validator-row');
    if (!validatorRow) {
        console.error('Validator row not found in container');
        return;
    }

    if (event) {
        event.stopPropagation();
    }

    // Check if the details pane is already open for this validator
    const isAlreadyOpen = detailsPane && detailsPane.style.display === 'block' && detailsPane.dataset.pubkey === pubkey;

    if (isAlreadyOpen && !forceOpen) {
        // Close the details pane
        isDetailsOpen = false;
        currentOpenPubkey = null;

        detailsPane.style.opacity = '0';
        detailsPane.style.display = 'none';
        detailsPane.classList.remove('active');
        detailsPane.innerHTML = '';
        detailsPane.dataset.pubkey = '';

        // Reset the row styling
        validatorRow.style.backgroundColor = '';
        validatorRow.style.color = '';

        // Remove selection from the row
        containerDiv.classList.remove('active-row', 'open');
        validatorRow.classList.remove('selected');
        const iconNameDiv = containerDiv.querySelector('.validator-icon-name');
        if (iconNameDiv) {
            iconNameDiv.classList.remove('active');
        }
    } else {
        if (!detailsPane) {
            detailsPane = document.createElement('div');
            detailsPane.id = 'validator-details-pane';
            detailsPane.className = 'validator-details-pane';
        }

        isDetailsOpen = true;
        currentOpenPubkey = pubkey;

        selectValidatorRow(validatorRow);

        detailsPane.style.display = 'block';
        detailsPane.style.opacity = '1';
        detailsPane.classList.add('active');
        detailsPane.dataset.pubkey = pubkey;

        // Append the pane to the container div if it's not already there
        if (!containerDiv.contains(detailsPane)) {
            containerDiv.appendChild(detailsPane);
        }

        // Fetch the validator details asynchronously
        await populateValidatorInfo(pubkey);

        // Highlight the selected stat row
        highlightSelectedStat();
    }
}

async function scrollToValidator(pubkey, maxAttempts = 5) {
    if (isScrollingToValidator && currentOpenPubkey === pubkey) {
        return;
    }

    isScrollingToValidator = true;

    async function attemptScroll() {
        for (let attempt = 0; attempt < maxAttempts; attempt++) {

            // Ensure data is loaded
            if (!getAppState().dataLoaded) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                continue;
            }

            let validatorRow = document.querySelector(`.validator-row[data-validator-pubkey="${pubkey}"]`);

            if (!validatorRow) {
                // If not found in the current view, switch to "all" validators
                if (getAppState().validatorType !== 'all') {
                    await handleValidatorTypeChange('all');
                    validatorRow = document.querySelector(`.validator-row[data-validator-pubkey="${pubkey}"]`);
                }
            }

            if (validatorRow) {
                scrollToRowBelowFixedHeader(validatorRow, true);
                selectValidatorRow(validatorRow);
                toggleDetailsPane(pubkey, true);
                // Update URL without changing the pubkey parameter
                history.pushState(null, '', `#Validators?pubkey=${pubkey}&type=all`);
                return; // Exit the function if the validator row is found
            }

            await new Promise(resolve => setTimeout(resolve, 300));
        }

        // If validator is still not found after all attempts
        console.error(`Validator row not found after ${maxAttempts} attempts`);
        showNotFoundModal(pubkey);
        // Update URL to show 'all' validators, but keep the pubkey
        history.pushState(null, '', `#Validators?pubkey=${pubkey}&type=all`);
        throw new Error('Validator row not found');
    }

    try {
        await attemptScroll();
    } catch (error) {
        console.error("Error scrolling to validator:", error);
    } finally {
        isScrollingToValidator = false;
    }
}

function showNotFoundModal(pubkey) {
    const modal = document.createElement('div');
    modal.className = 'modal';
    modal.innerHTML = `
        <div class="modal-content">
            <p>Pubkey ${pubkey} not found</p>
        </div>
    `;
    document.body.appendChild(modal);

    setTimeout(() => {
        modal.remove();
    }, 3000);
}

function selectValidatorRow(row) {
    // Deselect all rows
    document.querySelectorAll('.validator-row-container').forEach(container => {
        container.classList.remove('active-row', 'open');
        container.querySelector('.validator-row').classList.remove('selected');
        const iconNameDiv = container.querySelector('.validator-icon-name');
        if (iconNameDiv) {
            iconNameDiv.classList.remove('active');
        }
    });

    // Select the target row
    const containerDiv = row.closest('.validator-row-container');
    containerDiv.classList.add('active-row', 'open');
    row.classList.add('selected');
    const iconNameDiv = containerDiv.querySelector('.validator-icon-name');
    if (iconNameDiv) {
        iconNameDiv.classList.add('active');
    }
}

async function searchValidators() {
    showLoadingSpinner();

    try {
        const input = document.getElementById('validatorSearch');
        const searchQuery = input.value.trim();

		updateAppState({ searchQuery: searchQuery });

        // Always work with the full dataset
        const validatorType = getAppState().validatorType;
        let validatorData = validatorType === 'pool' ? getAppState().cache.pool?.validators : mergeValidatorData();

		if (!validatorData) {
			console.error('Validator data not available');
			return;
		}

        // Convert Map to Array if necessary
        const validatorArray = Array.from(validatorData.entries());

        // Apply search filter
        const filteredData = searchQuery === '' ? validatorArray : filterValidators(validatorType, searchQuery, validatorArray);

        // Repopulate the table with filtered data
        await populateValidatorRows(filteredData);

        // Update visibility and highlighting
        const validatorsTable = document.getElementById('validatorsTable');
        if (!validatorsTable) {
            console.error('Validators table not found');
            return;
        }

        const validatorContainers = validatorsTable.getElementsByClassName('validator-row-container');
        let visibleRowCount = 0;

        Array.from(validatorContainers).forEach(container => {
            const validatorRow = container.querySelector('.validator-row');
            if (validatorRow) {
                const pubkey = validatorRow.dataset.validatorPubkey;
                const isVisible = filteredData.some(([key, _]) => key === pubkey);
                container.style.display = isVisible ? '' : 'none';
                if (isVisible) {
                    visibleRowCount++;
                    const validator = validatorData.get(pubkey);
                    if (validator) {
                        updateRowHighlight(pubkey, searchQuery, validator);
                    }
                }
            }
        });

        updateNoMatchMessage(visibleRowCount === 0);
        updateAppState({ searchQuery: searchQuery });

		// Update the radio button to match the current validator type
        const radioButton = document.getElementById(`${validatorType}-validators`);
        if (radioButton) {
            radioButton.checked = true;
        } else {
            console.warn(`Radio button for ${validatorType} validators not found`);
        }

        // Only close the details pane if the search query is not empty
        if (searchQuery !== '') {
            hideValidatorDetailsPane();
        }
    } catch (error) {
        console.error('Error during validator search:', error);
    } finally {
        hideLoadingSpinner();
    }
}

function updateNoMatchMessage(show) {
    const noMatchMessage = document.getElementById('noMatchMessage');
    if (noMatchMessage) {
        if (show) {
            const validatorType = getAppState().validatorType;
            const searchQuery = getAppState().searchQuery;
            if (validatorType === 'pool') {
                noMatchMessage.innerHTML = 'No validators in the <strong>pool</strong> match the query.<br>' +
                    `<a href="#" id="searchAllValidators">Search all validators?</a>`;
                document.getElementById('searchAllValidators').addEventListener('click', (e) => {
                    e.preventDefault();
                    document.getElementById('all-validators').checked = true;
                    handleValidatorTypeChange('all');
                });
            } else {
                noMatchMessage.innerHTML = 'No validators match the query. ' +
                    '<a href="#" id="clearSearchLink">Clear</a> your search or try another string.';
                document.getElementById('clearSearchLink').addEventListener('click', (e) => {
                    e.preventDefault();
                    clearSearch();
                });
            }
            noMatchMessage.style.display = 'block';
        } else {
            noMatchMessage.style.display = 'none';
        }
    }
}

   function updateRowHighlight(pubkey, searchQuery, validator) {
       const fullName = validator.details.name || pubkey;
       const highlightedText = highlightMatchingText(fullName, searchQuery);

       // Elide and highlight pubkey
       const maxLength = 20;
       const elidedPubkey = elideString(pubkey, maxLength, searchQuery);
       const highlightedPubkey = highlightMatchingText(elidedPubkey, searchQuery);

       // Desktop version
       const containerDiv = document.getElementById(`validator-${pubkey}`);
       if (!containerDiv) {
           console.warn(`Desktop container not found for pubkey: ${pubkey}`);
       } else {
           const nameElement = containerDiv.querySelector('.validator-name');
           if (nameElement) {
               nameElement.innerHTML = highlightedText;
               if (searchQuery && pubkey !== fullName && pubkey.toLowerCase().includes(searchQuery.toLowerCase())) {
                   nameElement.innerHTML += ` <span class="validator-pubkey">(${highlightedPubkey})</span>`;
               }
           }
       }

       // Mobile version
       const mobileContainerDiv = document.getElementById(`validator-mobile-${pubkey}`);
       if (!mobileContainerDiv) {
           console.warn(`Mobile container not found for pubkey: ${pubkey}`);
       } else {
           const mobileNameElement = mobileContainerDiv.querySelector('.validator-name-mobile');
           if (mobileNameElement) {
               mobileNameElement.innerHTML = highlightedText;
               if (searchQuery && pubkey !== fullName && pubkey.toLowerCase().includes(searchQuery.toLowerCase())) {
                   mobileNameElement.innerHTML += ` <span class="validator-pubkey">(${highlightedPubkey})</span>`;
               }
           }
       }

       // Ensure the containers are visible
       if (containerDiv) containerDiv.style.display = '';
       if (mobileContainerDiv) mobileContainerDiv.style.display = '';
   }

function highlightMatchingText(text, query) {
    if (!query) return text;
    const regex = new RegExp(`(${escapeRegExp(query)})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function clearSearch() {
	if (getAppState().validatorType !== getAppState().lastUsedDataset) {
        // We're in the process of switching validator types, don't clear the search
        return;
    }
    const searchInput = document.getElementById('validatorSearch');
    if (searchInput) {
        searchInput.value = '';
    }
    updateAppState({ searchQuery: '' });

    // Re-populate all validators based on current validator type
    const validatorType = getAppState().validatorType;
    let validatorData = validatorType === 'pool' ?
        getAppState().cache.pool?.validators :
        mergeValidatorData();

    if (validatorData) {
        populateValidatorRows(Array.from(validatorData.entries()));
    }

    // Ensure all rows are visible
    document.querySelectorAll('.validator-row-container').forEach(row => {
        row.style.display = '';
    });

    updateNoMatchMessage(false);
}

function createValidatorRow(pubkey, validator) {
    const containerDiv = document.createElement('div');
    containerDiv.className = 'validator-row-container';
    containerDiv.style.display = '';
    containerDiv.id = `validator-${pubkey}`;

    const div = document.createElement('div');
    div.className = 'validator-row';
    div.dataset.validatorPubkey = pubkey;

    const isNonPoolValidator = !getAppState().cache.pool.pool_voters.has(pubkey);
    const isNotEligibleValidator = validator.noneligibility_reasons && validator.noneligibility_reasons.length > 0;

    div.classList.toggle('pool-validator-row', !isNonPoolValidator);

    if (isNotEligibleValidator) {
        containerDiv.classList.add('not-eligible-validator-row');
        div.classList.add('not-eligible-validator-row');
    }

    let iconUrl = validator.details.icon_url ? validator.details.icon_url : 'images/validators/validator-icon_missing_3.png';

    let isNewStyleValidatorIcon = iconUrl.startsWith('**icon_url**');
    if (isNewStyleValidatorIcon) {
        iconUrl = iconUrl.replace('**icon_url**', '');
    }

    // Create the combined icon and name div
    const iconNameDiv = document.createElement('div');
    iconNameDiv.className = 'validator-icon-name';
    iconNameDiv.style.display = 'flex';
    iconNameDiv.style.alignItems = 'center';

    // Validator Icon
    const icon = document.createElement('img');
    icon.onerror = function() {
        this.src = 'images/validators/validator-icon_missing_3.png';
        this.className = 'validator-icon-circle validator-icon';
        this.style.borderRadius = '50%';
    };
    icon.src = iconUrl;
    icon.referrerPolicy = 'no-referrer';
    icon.alt = '';
    icon.className = (isNewStyleValidatorIcon ? '' : 'validator-icon-circle ') + 'validator-icon';
    icon.style.marginRight = '10px';

    // Validator Name (desktop version)
    const name = document.createElement('div');
    name.innerHTML = validator.details.name || elideString(pubkey, 20);
    name.className = 'validator-name';

    // Append icon and name to iconNameDiv
    iconNameDiv.appendChild(icon);
    iconNameDiv.appendChild(name);

    // Validator Score
    const score = document.createElement('div');
    score.textContent = (validator.details.total_score * 100).toFixed((validator.details.total_score == 1) ? 1 : 2);
    score.className = 'validator-score validator-data';

    // Total Stake
    const totalStake = document.createElement('div');
    const totalStakeLink = document.createElement('a');
    totalStakeLink.href = `https://stakeview.app/stakesof/${pubkey}`;
    totalStakeLink.target = '_blank';
    totalStakeLink.textContent = render_sol_value(lamports_to_sol(validator.details.stake.active), true);
    totalStake.appendChild(totalStakeLink);
    totalStake.className = 'validator-total-stake validator-data';

    // Pool Stake
    const poolStake = document.createElement('div');
    const poolStakeValue = validator.pool_stake ? lamports_to_sol(validator.pool_stake.active) : 0;
    poolStake.textContent = render_sol(poolStakeValue, true);
    poolStake.className = 'validator-pool-stake validator-data';

    // Target Stake
    const targetStake = document.createElement('div');
    const targetStakeValue = lamports_to_sol(validator.details.target_pool_stake);
    targetStake.textContent = render_sol(targetStakeValue, true);
    targetStake.className = 'validator-target-stake validator-data';

    // Create a span element for the arrow
    const arrowSpan = document.createElement('span');

    // Compare poolStakeValue and targetStakeValue to determine which arrow to display
    if (targetStakeValue < poolStakeValue) {
        // Target stake is lower than current pool stake, append a down arrow
        arrowSpan.textContent = " ↓";
        arrowSpan.className = 'arrow-down';
    } else if (targetStakeValue > poolStakeValue) {
        // Target stake is greater than current pool stake, append an up arrow
        arrowSpan.textContent = " ↑";
        arrowSpan.className = 'arrow-up';
    }

    // Color text of target stake based
    if (targetStakeValue < poolStakeValue) {
        targetStake.classList.add('stake-decrease');
    }
    else if (targetStakeValue > poolStakeValue)
    {
        targetStake.classList.add('stake-increase');
    }

    // Append the arrow span to the targetStake element
    targetStake.appendChild(arrowSpan);

    // Compare poolStakeValue and targetStakeValue to determine the class
    if (targetStakeValue < poolStakeValue) {
        // Target stake is lower than current pool stake
        targetStake.classList.add('stake-decrease');
    } else if (targetStakeValue > poolStakeValue) {
        // Target stake is greater than current pool stake
        targetStake.classList.add('stake-increase');
    }

    // Awards
    const awards = document.createElement('div');
    awards.className = 'validator-awards-row';
    awards.style.width = '180px';
    awards.style.display = 'flex';
    awards.style.justifyContent = 'flex-end';
    awards.style.alignItems = 'center';
    awards.style.position = 'relative'; // Ensure proper positioning

    const validatorAwards = getValidatorAwards(pubkey);
    if (validatorAwards.length == 0) {
        // Handle no awards case
    } else {
        validatorAwards.forEach(award => {
            const awardIconWrapper = document.createElement('div');
            awardIconWrapper.className = 'validator-award-icon-row-wrapper';
            awardIconWrapper.style.position = 'relative'; // Required for tooltip positioning

            const rowIcon = document.createElement('img');
            rowIcon.src = award.trophyImage;
            rowIcon.alt = award.title;
            rowIcon.className = 'validator-award-icon-row';
            awardIconWrapper.appendChild(rowIcon);

            const infoPopup = document.createElement('div');
            infoPopup.className = 'award-info-popup-row';
            infoPopup.textContent = `${award.title}`;
            awardIconWrapper.appendChild(infoPopup);

            rowIcon.addEventListener('mouseenter', () => {
                showTooltipRow(infoPopup);
            });

            rowIcon.addEventListener('mouseleave', () => {
                hideTooltipRow(infoPopup);
            });

            // Append the wrapper to the awards container
            awards.appendChild(awardIconWrapper);
        });
    }

    // Construct the row for desktop (includes icon and name in one column)
    div.appendChild(iconNameDiv);
    div.appendChild(score);
    div.appendChild(totalStake);
    div.appendChild(poolStake);
    div.appendChild(targetStake);
    div.appendChild(awards);

    // For mobile screens, we need to create a separate div for icon and name
    const mobileIconNameDiv = document.createElement('div');
    mobileIconNameDiv.className = 'validator-icon-name-mobile';
    mobileIconNameDiv.id = `validator-mobile-${pubkey}`;
    mobileIconNameDiv.dataset.validatorPubkey = pubkey;
    mobileIconNameDiv.style.display = 'flex';
    mobileIconNameDiv.style.alignItems = 'center';

    // Validator Icon (mobile version)
    const mobileIcon = document.createElement('img');
    mobileIcon.onerror = function() {
        this.src = 'images/validators/validator-icon_missing_3.png';
        this.className = 'validator-icon-circle validator-icon-mobile';
        this.style.borderRadius = '50%';
    };
    mobileIcon.src = iconUrl;
    mobileIcon.referrerPolicy = 'no-referrer';
    mobileIcon.alt = '';
    mobileIcon.className = (isNewStyleValidatorIcon ? '' : 'validator-icon-circle-mobile ') + 'validator-icon-mobile';
    mobileIcon.style.marginRight = '10px';

    // Validator Name (mobile version)
    const mobileName = document.createElement('div');
    mobileName.innerHTML = name.innerHTML;
    mobileName.className = 'validator-name-mobile';

    mobileIconNameDiv.appendChild(mobileIcon);
    mobileIconNameDiv.appendChild(mobileName);

    // Append mobile icon and name div to container
    containerDiv.appendChild(mobileIconNameDiv);

    // Append the row to the container
    containerDiv.appendChild(div);

    return containerDiv;
}

// sort validators
document.querySelectorAll('.validator-header[data-sort-key]').forEach(header => {
    header.addEventListener('click', async () => {
        const sortKey = header.getAttribute('data-sort-key');
        const isNewColumn = getAppState('activeSortColumn') !== sortKey;

        let newDirection;
        if (isNewColumn) {
            newDirection = 'desc';
        } else {
            newDirection = getAppState('activeSortDirection') === 'desc' ? 'asc' : 'desc';
        }

        await sortValidators(sortKey, newDirection);
    });
});

async function sortValidators(column, direction) {
    showLoadingSpinner();
    try {
        const appState = getAppState();
        const validatorType = appState.validatorType;
        const searchQuery = appState.searchQuery;

        let validatorData;
        if (validatorType === 'pool') {
            validatorData = appState.cache.pool?.validators;
        } else {
            validatorData = mergeValidatorData();
        }

        if (!validatorData || validatorData.size === 0) {
            console.error('No validator data available for sorting');
            return;
        }

        const validatorArray = Array.from(validatorData);
        const filteredData = filterValidators(validatorType, searchQuery, validatorArray);

        const sortedData = filteredData.sort((a, b) => {
			if (column === 'awards') {
				const aAwards = getValidatorAwards(a[0]).length;
				const bAwards = getValidatorAwards(b[0]).length;
				return (aAwards - bAwards) * (direction === 'asc' ? 1 : -1);
			}

			const aValue = getNestedProperty(a[1], column);
			const bValue = getNestedProperty(b[1], column);

			if (column === 'details.name') {
				const aName = (aValue || '').toString();
				const bName = (bValue || '').toString();
				return compareNames(aName, bName) * (direction === 'asc' ? 1 : -1);
			}

			if (aValue === undefined && bValue === undefined) return 0;
			if (aValue === undefined) return 1;
			if (bValue === undefined) return -1;

			const numA = parseFloat(aValue);
			const numB = parseFloat(bValue);

			if (!isNaN(numA) && !isNaN(numB)) {
				return (numA - numB) * (direction === 'asc' ? 1 : -1);
			} else {
				return aValue.toString().localeCompare(bValue.toString()) * (direction === 'asc' ? 1 : -1);
			}
		});

        updateAppState({
            activeSortColumn: column,
            activeSortDirection: direction
        });

        // Instead of repopulating, sort the existing rows
        const validatorTableBody = document.getElementById('validatorTableBody');
        const rows = Array.from(validatorTableBody.children);
        rows.sort((rowA, rowB) => {
            const validatorRowA = rowA.querySelector('.validator-row');
            const validatorRowB = rowB.querySelector('.validator-row');

            if (!validatorRowA || !validatorRowB) {
                console.warn('Validator row not found during sorting.');
                return 0;
            }

            const pubkeyA = validatorRowA.dataset.validatorPubkey;
            const pubkeyB = validatorRowB.dataset.validatorPubkey;

            const indexA = sortedData.findIndex(([key]) => key === pubkeyA);
            const indexB = sortedData.findIndex(([key]) => key === pubkeyB);

            return indexA - indexB;
        });

        // Clear the table body and append the sorted rows
        validatorTableBody.innerHTML = '';
        rows.forEach(row => validatorTableBody.appendChild(row));

        updateSortIndicator(column, direction);

        // After sorting, scroll to the top of the validator table with offset
        const headerOffset = document.querySelector('.header-main').offsetHeight;
        const validatorsTable = document.getElementById('validatorsTable');
        if (validatorsTable) {
            const tablePosition = validatorsTable.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = tablePosition - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    } catch (error) {
        console.error('Error during validator sorting:', error);
    } finally {
        hideLoadingSpinner();
    }
}

function compareNames(a, b) {
    const alphanumericRegex = /[a-zA-Z0-9]/;

    function findFirstAlphanumeric(str) {
        const match = str.match(alphanumericRegex);
        return match ? match.index : str.length;
    }

    const aIndex = findFirstAlphanumeric(a);
    const bIndex = findFirstAlphanumeric(b);

    const aSubstr = a.slice(aIndex).toLowerCase();
    const bSubstr = b.slice(bIndex).toLowerCase();

    if (aSubstr === bSubstr) {
        // If the alphanumeric parts are the same, compare the full strings
        return a.localeCompare(b);
    }

    return aSubstr.localeCompare(bSubstr);
}

function getNestedProperty(obj, path) {
    const value = path.split('.').reduce((current, key) => current && current[key] !== undefined ? current[key] : undefined, obj);

    // Convert BigInt to Number if the value is BigInt
    if (typeof value === 'bigint') {
        // Use Number() for small BigInt values, or toString() for large ones
        return Number(value) || value.toString();
    }

    return value;
}

function updateSortIndicator(sortKey, direction) {
    document.querySelectorAll('.validator-header').forEach(header => {
        header.classList.remove('sort-asc', 'sort-desc');
        const sortIcon = header.querySelector('.sort-icon');
        if (sortIcon) {
            sortIcon.textContent = '⇅';
        }
    });

    const activeHeader = document.querySelector(`.validator-header[data-sort-key="${sortKey}"]`);
    if (activeHeader) {
        activeHeader.classList.add(`sort-${direction}`);
        const sortIcon = activeHeader.querySelector('.sort-icon');
        if (sortIcon) {
            sortIcon.textContent = direction === 'asc' ? '↑' : '↓';
        }
    }
}

//
// Validators Cache
//

async function populateValidatorInfo(pubkey) {
    const detailsPane = document.getElementById('validator-details-pane');
    if (detailsPane && isDetailsOpen && currentOpenPubkey === pubkey) {
        detailsPane.innerHTML = ''; // Clear the existing content
        detailsPane.style.display = 'block';
        detailsPane.style.opacity = '1';

        const validatorData = getAppState().cache.pool.pool_voters.get(pubkey) || getAppState().cache.nonPoolVoters.voters.get(pubkey);

        if (!validatorData) {
            console.error('Validator data not found for pubkey:', pubkey);
            detailsPane.innerHTML = '<p>Error: Validator data not found.</p>';
            return;
        }

        const { details, pool_stake, noneligibility_reasons } = validatorData;

        const detailsContainer = document.createElement('div');
        detailsContainer.className = 'validator-details-container';

        // Add a loading indicator
        const loadingIndicator = document.createElement('p');
        loadingIndicator.textContent = 'Loading validator details...';
        detailsContainer.appendChild(loadingIndicator);

        if (noneligibility_reasons && noneligibility_reasons.length > 0) {
            const noneligibilitySection = document.createElement('div');
            noneligibilitySection.className = 'validator-noneligibility-section';

            const noneligibilityTitle = document.createElement('h3');
            noneligibilityTitle.textContent = 'Non-Eligibility Reasons:';
            noneligibilitySection.appendChild(noneligibilityTitle);

            const noneligibilityList = document.createElement('ul');
            noneligibility_reasons.forEach(reason => {
                const listItem = document.createElement('li');
                listItem.textContent = reason;
                noneligibilityList.appendChild(listItem);
            });
            noneligibilitySection.appendChild(noneligibilityList);

            detailsContainer.appendChild(noneligibilitySection);
        }

        // Fetch leader data, voter data, and pool data
        load_voter_metrics_group(getAppState('timestamp'), pubkey, (result) => {
            const validatorMetrics = findDataForPubkey(pubkey);

            if (validatorMetrics) {
                const leaderData = validatorMetrics.leader_data;
                const voterData = validatorMetrics.voter_data;
                const poolData = validatorMetrics.pool_data;

                // Remove the loading indicator
                loadingIndicator.remove();

                renderValidatorDetailsPane(details, pool_stake, leaderData, voterData, poolData, pubkey, noneligibility_reasons);
            } else {
                console.error('Validator metrics not found for pubkey:', pubkey);
                loadingIndicator.textContent = 'Error: Validator metrics not found.';
            }
        });

        detailsPane.appendChild(detailsContainer);
    } else {
        console.error('Details pane not found or pane is not open, skipping population');
    }
}

function findDataForPubkey(pubkey) {
    let groupKey = pubkey.slice(-1);
    let groupData = load_voter_metrics_group.cache.get(groupKey);
    if (groupData && groupData.value.voters) {
        return groupData.value.voters.get(pubkey);
    } else {
        console.error('Group Data not found for pubkey:', pubkey);
    }
    return null;
}

function displayValidatorAwards(container, validatorPubkey) {
    container.innerHTML = ''; // Clear existing awards

    const validatorAwards = getValidatorAwards(validatorPubkey);

    validatorAwards.forEach(award => {
        const awardIconWrapper = document.createElement('div');
        awardIconWrapper.className = 'award-icon-wrapper';

        const awardIcon = document.createElement('img');
        awardIcon.src = `${award.trophyImage}`;
        awardIcon.alt = award.title;
        awardIcon.className = 'validator-award-icon';
        awardIcon.setAttribute('award-key', award.awardKey);
		awardIcon.setAttribute('data-pubkey', validatorPubkey);
        awardIconWrapper.appendChild(awardIcon);

        const infoPopup = document.createElement('div');
        infoPopup.className = 'award-info-popup';
        infoPopup.textContent = `${award.title}: ${award.description}`;
        awardIconWrapper.appendChild(infoPopup);

        container.appendChild(awardIconWrapper);

        awardIcon.addEventListener('mouseenter', () => {
            highlightStatRow(award.awardKey);
            highlightGraphLine(award.awardKey);
            highlightAward(award.awardKey);
        });

        awardIcon.addEventListener('mouseleave', () => {
            unhighlightStatRow(award.awardKey);
            unhighlightGraphLine(award.awardKey);
            unhighlightAward(award.awardKey);
        });

		/*
        awardIcon.addEventListener('click', () => {
            openTab(null, 'FAQ');
            setTimeout(() => {
                const section = document.getElementById(award.name);
                section.scrollIntoView({ behavior: 'smooth' });
            }, 500);
        });
		*/
    });
}

function highlightAward(awardKey) {
    const awardIcon = document.querySelector(`.validator-award-icon[award-key="${awardKey}"]`);
    if (awardIcon) {
        awardIcon.classList.add('highlighted');
        showTooltip(awardIcon);
    }
}

function unhighlightAward(awardKey) {
    const awardIcon = document.querySelector(`.validator-award-icon[award-key="${awardKey}"]`);
    if (awardIcon) {
        awardIcon.classList.remove('highlighted');
        hideTooltip(awardIcon);
    }
}

function highlightStatRow(awardKey) {
    const statRow = document.querySelector(`.validator-details-summary-table tr[award-key="${awardKey}"]`);
    if (statRow) {
        statRow.classList.add('highlighted');
    }

}

function unhighlightStatRow(awardKey) {
    const statRow = document.querySelector(`.validator-details-summary-table tr[award-key="${awardKey}"]`);
    if (statRow) {
        statRow.classList.remove('highlighted');
    }
}

function highlightGraphLine(awardKey) {
    if (combinedChart) {
        combinedChart.data.datasets.forEach(dataset => {
            if (dataset.awardKey === awardKey) {
                dataset.borderWidth = 3;
                dataset.borderColor = dataset.borderColor.replace('0.8)', '1)');
            }
        });
        combinedChart.update();
    }
}

function unhighlightGraphLine(awardKey) {
    if (combinedChart) {
        combinedChart.data.datasets.forEach(dataset => {
            if (dataset.awardKey === awardKey) {
                dataset.borderWidth = dataset.metricKey.includes('_average') ? 2 : 1;
                dataset.borderColor = dataset.borderColor.replace('1)', '0.8)');
            }
        });
        combinedChart.update();
    }
}

   // Utility functions
   function showTooltipRow(tooltip) {
       tooltip.style.visibility = 'visible';
       tooltip.style.opacity = '1';
   }

   function hideTooltipRow(tooltip) {
       tooltip.style.visibility = 'hidden';
       tooltip.style.opacity = '0';
   }

function showTooltip(awardIcon) {
    // First, hide all other tooltips
    document.querySelectorAll('.award-info-popup').forEach(tooltip => {
        tooltip.style.visibility = 'hidden';
        tooltip.style.opacity = '0';
    });

    // Then, show the tooltip for the current award icon
    const tooltip = awardIcon.parentElement.querySelector('.award-info-popup');
    if (tooltip) {
        tooltip.style.visibility = 'visible';
        tooltip.style.opacity = '1';
    }
}

function hideTooltip(awardIcon) {
    const tooltip = awardIcon.parentElement.querySelector('.award-info-popup');
    if (tooltip) {
        // Check if either the awardIcon or its tooltip is being hovered over
        if (!awardIcon.matches(':hover') && !tooltip.matches(':hover')) {
            tooltip.style.visibility = 'hidden';
            tooltip.style.opacity = '0';
        }
    }
}

function showInfoTooltip(icon) {
    if (icon && icon.getAttribute) {
        const tooltipText = icon.getAttribute('data-tooltip');
        if (tooltipText) {
            const tooltipElement = document.createElement('div');
            tooltipElement.className = 'info-tooltip';
            tooltipElement.textContent = tooltipText;
            document.getElementById('tooltipContainer').appendChild(tooltipElement);

            const iconRect = icon.getBoundingClientRect();
            const tooltipRect = tooltipElement.getBoundingClientRect();

            const windowWidth = window.innerWidth;
            const tooltipWidth = tooltipRect.width;
            const iconCenter = iconRect.left + iconRect.width / 2;

            let left = iconCenter - tooltipWidth / 2;
            if (left < 0) {
                left = 0;
            } else if (left + tooltipWidth > windowWidth) {
                left = windowWidth - tooltipWidth;
            }

            const top = iconRect.top - tooltipRect.height - 110;
            tooltipElement.style.top = `${top}px`;
            tooltipElement.style.left = `${left}px`;
        }
    }
}

function hideInfoTooltip() {
    const tooltipContainer = document.getElementById('tooltipContainer');
    tooltipContainer.innerHTML = '';
}

document.addEventListener('DOMContentLoaded', () => {
    const infoIcons = document.querySelectorAll('.info-icon');

    infoIcons.forEach(icon => {
        icon.addEventListener('mouseenter', () => {
            showInfoTooltip(icon);
        });

        icon.addEventListener('mouseleave', () => {
            hideInfoTooltip();
        });
    });
});


function renderValidatorDetailsPane(validatorDetails, poolStake, leaderData, voterData, poolData, pubkey, noneligibility_reasons) {
    let detailsPane = document.getElementById('validator-details-pane');

    // Find the target validator row
    const targetContainerDiv = document.getElementById(`validator-${pubkey}`);

    if (!targetContainerDiv) {
        console.error('Target validator row not found');
        return;
    }

    if (!detailsPane) {
        detailsPane = document.createElement('div');
        detailsPane.id = 'validator-details-pane';
        detailsPane.className = 'validator-details-pane';

        // Insert the details pane after the target validator row
        targetContainerDiv.parentNode.insertBefore(detailsPane, targetContainerDiv.nextSibling);
    } else {
        // If the details pane already exists, move it to the correct position
        targetContainerDiv.parentNode.insertBefore(detailsPane, targetContainerDiv.nextSibling);
    }

    // Check if the details pane should still be open
    if (!isDetailsOpen || currentOpenPubkey !== pubkey) {
        return;
    }

    detailsPane.innerHTML = ''; // Clear the existing content

    const detailsContainer = document.createElement('div');
    detailsContainer.className = 'validator-details-container';

    const isNonPoolValidator = !getAppState().cache.pool.pool_voters.has(pubkey);
    const isNotEligibleValidator = noneligibility_reasons && noneligibility_reasons.length > 0;

    if (isNotEligibleValidator) {
        const noneligibilitySection = document.createElement('div');
        noneligibilitySection.className = 'validator-noneligibility-section';

        if (noneligibility_reasons.length > 0){
            const noneligibilityTitle = document.createElement('h3');
            noneligibilityTitle.textContent = 'Non-Eligibility Reasons:';
            noneligibilitySection.appendChild(noneligibilityTitle);

            const noneligibilityList = document.createElement('ul');
            noneligibility_reasons.forEach(reason => {
                const listItem = document.createElement('li');
                listItem.textContent = reason;
                noneligibilityList.appendChild(listItem);
            });
            noneligibilitySection.appendChild(noneligibilityList);
        }

        if (validatorDetails.target_pool_stake > 0) {
            const eligibilityNote = document.createElement('h3');
            eligibilityNote.textContent = 'Note: This validator will be awarded pool stake in the next epoch';
            noneligibilitySection.appendChild(eligibilityNote);
        }

        detailsContainer.appendChild(noneligibilitySection);
    }

    const maxStringLength = 200;
    const website_url_formatted = validatorDetails.website_url ?
          `<a href="${validatorDetails.website_url}" target="_blank">${elideString(validatorDetails.website_url.replace("https://", ""), maxStringLength)}</a>` : '';

    // Determine location
    let location = "Unknown";
    if (validatorDetails.city && validatorDetails.country) {
        location = `${validatorDetails.city}, ${validatorDetails.country}`;
    } else if (validatorDetails.city) {
        location = validatorDetails.city;
    } else if (validatorDetails.country) {
        location = validatorDetails.country;
    }

    const statsAndGraphContainer = document.createElement('div');
    statsAndGraphContainer.className = 'validator-stats-graph-container';

    const summarySection = document.createElement('div');
    summarySection.className = 'validator-summary-section';

    const summaryContent = document.createElement('div');
    summaryContent.className = 'validator-summary-content';

    const epochNumbers = [...leaderData.keys()].map(Number);
    epochNumbers.sort((a, b) => a - b);

    latestStatsData = populateValidatorSummary(validatorDetails, poolStake, summaryContent, pubkey, voterData, leaderData, epochNumbers, poolData);

    let lastSelectedMetric = getLastSelectedMetric();
    if (lastSelectedMetric) {
        highlightRow(summaryContent.querySelector(`tr[data-metric-key="${lastSelectedMetric}"]`));
        updateGraphVisibility(lastSelectedMetric);
    }

    const summaryRows = summaryContent.querySelectorAll('.validator-details-summary-row');
    summaryRows.forEach(row => {
        row.addEventListener('click', () => {
            const metricKey = row.dataset.metricKey;
            lastSelectedMetric = metricKey;
            highlightSelectedStat(metricKey);
            updateGraphVisibility(metricKey);
        });
    });

    summarySection.appendChild(summaryContent);
    statsAndGraphContainer.appendChild(summarySection);

    const chartsContainer = document.createElement('div');
    chartsContainer.className = 'validator-charts-container';

    const combinedChartSection = createValidatorDetailsSection('Performance Metrics', 'combined-chart');
    chartsContainer.appendChild(combinedChartSection);

    statsAndGraphContainer.appendChild(chartsContainer);

    detailsContainer.appendChild(statsAndGraphContainer);

    detailsPane.appendChild(detailsContainer);

    renderCombinedChart(leaderData, voterData, poolData, pubkey, latestStatsData, validatorDetails);

    const awardsContainer = document.createElement('div');
    awardsContainer.className = 'validator-awards-container';
    detailsContainer.appendChild(awardsContainer);

    const validatorAwards = getValidatorAwards(pubkey);

    const identityRow = document.createElement('div');
    identityRow.className = 'validator-details-identity';

    const stakeHistoryLink = document.createElement('div');
    stakeHistoryLink.className = 'validator-details-stake-history';
    stakeHistoryLink.innerHTML = `<a href="https://www.stakeview.app/stakesof/${pubkey}" target="_blank">View Stake History</a>`;
    identityRow.appendChild(stakeHistoryLink);

    const locationElement = document.createElement('div');
    locationElement.className = 'validator-details-location';
    locationElement.textContent = `Location: ${location}`;
    identityRow.appendChild(locationElement);

    const websiteElement = document.createElement('div');
    websiteElement.className = 'validator-details-website';
    if (website_url_formatted) {
        websiteElement.innerHTML = `Website: ${website_url_formatted}`;
    } else {
        websiteElement.textContent = 'Website: —';
    }
    identityRow.appendChild(websiteElement);

    detailsContainer.appendChild(identityRow);

    const descriptionElement = document.createElement('div');
	descriptionElement.className = 'details-text';
    const trimmedDetails = validatorDetails.details ? validatorDetails.details.trim() : '';
    if (trimmedDetails && trimmedDetails.toLowerCase() !== 'null') {
        descriptionElement.innerHTML = 'Self-Reported Details: <i>"' + trimmedDetails + '"</i>';
    } else {
        descriptionElement.textContent = 'Self-Reported Details: —';
    }
    identityRow.appendChild(descriptionElement);

    // Fetch the validator's stake account address
    let validatorStakeAddress;
    try {
        const [stakeAddress] = PublicKey.findProgramAddressSync(
            [
                new PublicKey(pubkey).toBuffer(),
                stakePool.toBuffer(),
                Buffer.alloc(0)
            ],
            STAKE_POOL_PROGRAM_ID
        );
        validatorStakeAddress = stakeAddress;
    } catch (error) {
        console.error('Error fetching validator stake address:', error);
    }

    if (validatorStakeAddress) {
        const voteAccountElement = document.createElement('div');
        voteAccountElement.innerHTML = `Vote Account: <a href="https://explorer.solana.com/address/${pubkey}" target="_blank" class="vote-account-pubkey">${elideString(pubkey, maxStringLength)}</a>`;

        const voteAccountCopyIcon = document.createElement('span');
        voteAccountCopyIcon.className = 'copy-icon';
        voteAccountCopyIcon.innerHTML = '&#128203;'; // Copy icon HTML entity
        voteAccountCopyIcon.addEventListener('click', () => {
            navigator.clipboard.writeText(pubkey).then(() => {
                voteAccountCopyIcon.setAttribute('data-tooltip', 'Copied to clipboard');
                voteAccountCopyIcon.classList.add('clicked');
                setTimeout(() => {
                    voteAccountCopyIcon.classList.remove('clicked');
                    voteAccountCopyIcon.removeAttribute('data-tooltip'); // Remove tooltip after brief display
                }, 2000); // Show tooltip for 2 seconds
            });
        });

        voteAccountElement.appendChild(voteAccountCopyIcon);
        identityRow.appendChild(voteAccountElement);

        const stakeAccountElement = document.createElement('div');
        stakeAccountElement.innerHTML = `Pool Stake Account: <a href="https://explorer.solana.com/address/${validatorStakeAddress.toBase58()}" target="_blank" class="pool-stake-account-pubkey">${elideString(validatorStakeAddress.toBase58(), maxStringLength)}</a>`;

        const stakeAccountCopyIcon = document.createElement('span');
        stakeAccountCopyIcon.className = 'copy-icon';
        stakeAccountCopyIcon.innerHTML = '&#128203;'; // Copy icon HTML entity
        stakeAccountCopyIcon.addEventListener('click', () => {
            navigator.clipboard.writeText(validatorStakeAddress.toBase58()).then(() => {
                stakeAccountCopyIcon.setAttribute('data-tooltip', 'Copied to clipboard');
                stakeAccountCopyIcon.classList.add('clicked');
                setTimeout(() => {
                    stakeAccountCopyIcon.classList.remove('clicked');
                    stakeAccountCopyIcon.removeAttribute('data-tooltip'); // Remove tooltip after brief display
                }, 2000); // Show tooltip for 2 seconds
            });
        });

        stakeAccountElement.appendChild(stakeAccountCopyIcon);
        identityRow.appendChild(stakeAccountElement);
    }

    const tabCurrentEpochLink = document.getElementById('tabCurrentEpoch');
    const tabAverageLink = document.getElementById('tabAverage');
    const contentCurrentEpoch = document.getElementById('contentCurrentEpoch');
    const contentAverage = document.getElementById('contentAverage');

    tabCurrentEpochLink.addEventListener('click', (e) => {
        e.preventDefault();
        switchTab(tabCurrentEpochLink, contentCurrentEpoch);
        maintainSelectedStat();
    });

    tabAverageLink.addEventListener('click', (e) => {
        e.preventDefault();
        switchTab(tabAverageLink, contentAverage);
        maintainSelectedStat();
    });

    // Initially select the 10 Epoch Average tab
    switchTab(tabAverageLink, contentAverage);

    detailsPane.appendChild(detailsContainer);

    displayValidatorAwards(awardsContainer, pubkey);
    // Final check to ensure the pane is visible
    detailsPane.style.display = 'block';
    detailsPane.style.opacity = '1';
    detailsPane.classList.add('active');
}

function calculateYAxisRange(datasets) {
    if (!datasets || datasets.length === 0) {
        return { min: 0, max: 1 }; // Default range if no data
    }

    let min = Infinity;
    let max = -Infinity;
    datasets.forEach(dataset => {
        if (!dataset.hidden) {
            const values = dataset.data.filter(v => v !== null && !isNaN(v));
            if (values.length > 0) {
                min = Math.min(min, Math.min(...values));
                max = Math.max(max, Math.max(...values));
            }
        }
    });

    if (min === Infinity || max === -Infinity) {
        return { min: 0, max: 1 }; // Default range if no valid data
    }

    const padding = (max - min) * 0.1;
    return {
        min: Math.max(0, min - padding),  // Ensure min is not negative
        max: max + padding
    };
}


function renderCombinedChart(leaderData, voterData, poolData, validatorPubkey, latestStatsData, validatorDetails) {
	if (!getAppState().dataLoaded) {
        console.error("Data not loaded for rendering chart");
        return;
    }
    const initialMetric = lastSelectedMetric || 'llv';
    highlightSelectedStat(initialMetric);
    updateGraphVisibility(initialMetric);
    const chartCanvas = document.getElementById('combined-chart');
    if (!chartCanvas) {
        console.error('Combined chart canvas not found.');
        return;
    }

	const dataTagPlugin = {
		id: 'dataTag',
		afterDatasetsDraw(chart, args, options) {
			const ctx = chart.ctx;
			const activeDatasets = chart.data.datasets.filter(ds => !ds.hidden);
			const activeMetricKey = activeDatasets[0]?.metricKey?.replace('_average', '').replace('_pool_average', '').replace('_all_validators_average', '');

			const poolAverageDataset = chart.data.datasets.find(dataset => dataset.metricKey === `${activeMetricKey}_pool_average`);
			const epochAverageDataset = chart.data.datasets.find(dataset => dataset.metricKey === `${activeMetricKey}_average`);
			const allValidatorsAverageDataset = chart.data.datasets.find(dataset => dataset.metricKey === `${activeMetricKey}_all_validators_average`);

			if (poolAverageDataset && epochAverageDataset) {
				const poolAverageMeta = chart.getDatasetMeta(chart.data.datasets.indexOf(poolAverageDataset));
				const epochAverageMeta = chart.getDatasetMeta(chart.data.datasets.indexOf(epochAverageDataset));

				const minOffset = 25; // Minimum distance between data tags

				chart.data.datasets.forEach((dataset, i) => {
					if (dataset.metricKey &&
						(dataset.metricKey === `${activeMetricKey}_pool_average` ||
						 dataset.metricKey === `${activeMetricKey}_average` ||
						 (dataset.metricKey === `${activeMetricKey}_all_validators_average` &&
						  activeMetricKey !== 'prior_skip_rate' &&
						  activeMetricKey !== 'subsequent_skip_rate'))) {
						const meta = chart.getDatasetMeta(i);
						if (!meta.hidden) {
							ctx.save();
							const yValue = dataset.data[0];
							const xPos = chart.chartArea.left + 10;
							let yPos = meta.data[0].y - 5;

							if (dataset.metricKey === `${activeMetricKey}_pool_average`) {
								const yOffset = Math.abs(poolAverageMeta.data[0].y - epochAverageMeta.data[0].y);
								if (yOffset < minOffset) {
									yPos = poolAverageMeta.data[0].y < epochAverageMeta.data[0].y ?
										epochAverageMeta.data[0].y + minOffset :
										epochAverageMeta.data[0].y - minOffset;
								}
							} else if (dataset.metricKey === `${activeMetricKey}_all_validators_average` && allValidatorsAverageDataset) {
								const allValidatorsAverageMeta = chart.getDatasetMeta(chart.data.datasets.indexOf(allValidatorsAverageDataset));
								const yOffset = Math.abs(allValidatorsAverageMeta.data[0].y - epochAverageMeta.data[0].y);
								if (yOffset < minOffset) {
									yPos = allValidatorsAverageMeta.data[0].y < epochAverageMeta.data[0].y ?
										epochAverageMeta.data[0].y + minOffset * 2 :
										epochAverageMeta.data[0].y - minOffset * 2;
								}
							}

							const text = dataset.metricKey.includes('_pool_average')
								? `Pool Average: ${formatMetricValueByMetricKey(dataset.metricKey.replace('_pool_average', ''), yValue)}`
								: dataset.metricKey.includes('_all_validators_average')
									? `All Validators Average: ${formatMetricValueByMetricKey(dataset.metricKey.replace('_all_validators_average', ''), yValue)}`
									: `10 Epoch Average: ${formatMetricValueByMetricKey(dataset.metricKey.replace('_average', ''), yValue)}`;

							const textWidth = ctx.measureText(text).width;
							const padding = 4;
							const borderWidth = 1;

							ctx.fillStyle = 'white';
							ctx.fillRect(xPos, yPos - 16, textWidth + padding * 2, 20);

							ctx.strokeStyle = 'lightgray';
							ctx.lineWidth = borderWidth;
							ctx.strokeRect(xPos, yPos - 16, textWidth + padding * 2, 20);

							ctx.fillStyle = dataset.borderColor;
							ctx.font = '12px Arial';
							ctx.textAlign = 'left';
							ctx.textBaseline = 'bottom';
							ctx.fillText(text, xPos + padding, yPos);

							ctx.restore();
						}
					}
				});
			}
		}
	};

	Chart.register(dataTagPlugin);

    const epochNumbers = [...leaderData.keys()].map(Number);
    epochNumbers.sort((a, b) => a - b);

	const globalMetricsToDisplay = [
		{ key: 'skip_rate', label: 'Skip Rate', color: 'rgba(244, 67, 54, 1)', backgroundColor: 'rgba(244, 67, 54, 0.2)', awardKey: 'best_skip_rate', isLowerBetter: true },
		{ key: 'cu', label: 'Compute Units', color: 'rgba(0, 188, 212, 1)', backgroundColor: 'rgba(0, 188, 212, 0.2)', awardKey: 'best_cu', isLowerBetter: false },
		{ key: 'cv', label: 'Consensus Voting', color: 'rgba(156, 39, 176, 1)', backgroundColor: 'rgba(156, 39, 176, 0.2)', awardKey: 'best_cv', isLowerBetter: false },
		{ key: 'vote_inclusion', label: 'Vote Inclusion', color: 'rgba(76, 175, 80, 1)', backgroundColor: 'rgba(76, 175, 80, 0.2)', awardKey: 'best_vote_inclusion', isLowerBetter: false },
		{ key: 'latency', label: 'Average Vote Latency', color: 'rgba(233, 30, 99, 1)', backgroundColor: 'rgba(233, 30, 99, 0.2)', awardKey: 'best_latency', isLowerBetter: true },
		{ key: 'llv', label: 'Low Latency Vote', color: 'rgba(0, 150, 136, 1)', backgroundColor: 'rgba(0, 150, 136, 0.2)', awardKey: 'best_llv', isLowerBetter: false },
		{ key: 'prior_skip_rate', label: 'Prior Skip Rate', color: 'rgba(255, 87, 34, 1)', backgroundColor: 'rgba(255, 87, 34, 0.2)', awardKey: 'best_prior_skip_rate', isLowerBetter: true },
		{ key: 'subsequent_skip_rate', label: 'Subsequent Skip Rate', color: 'rgba(121, 85, 72, 1)', backgroundColor: 'rgba(121, 85, 72, 0.2)', awardKey: 'best_subsequent_skip_rate', isLowerBetter: true },
		{ key: 'city_concentration', label: 'City Concentration', color: 'rgba(194, 179, 14, 1)', backgroundColor: 'rgba(194, 179, 14, 0.2)', awardKey: 'best_city_concentration', isLowerBetter: true },
		{ key: 'apy', label: 'APY', color: 'rgba(76, 175, 80, 1)', backgroundColor: 'rgba(76, 175, 80, 0.2)', awardKey: 'best_apy', isLowerBetter: false },
	];

	getAppState().cache.poolAverageDatasets = globalMetricsToDisplay.map(metric => {
		const poolAverageValue = getAppState().cache.pool.compare_by_current.spp_raw[metric.key];
		const formattedPoolAverageValue = metric.format ? metric.format(poolAverageValue) : poolAverageValue;

		return {
			label: 'Pool Average',
			data: [poolAverageValue],
			borderColor: '#dfa754',
			backgroundColor: 'rgba(223, 167, 84, 0.2)',
			borderWidth: 3,
			pointRadius: 0,
			fill: false,
			hidden: false,
			metricKey: `${metric.key}_pool_average`,
			awardKey: metric.awardKey,
			tooltipLabel: `Pool Average: ${formattedPoolAverageValue}`
		};
	});

    const datasets = globalMetricsToDisplay.map(metric => {
		const data = epochNumbers.map(epoch => {
			const voterDataValue = voterData.get(BigInt(epoch));
			const leaderDataValue = leaderData.get(BigInt(epoch));

			let value;
			let blocks, slots;
			if (leaderDataValue) {
				blocks = Number(leaderDataValue.blocks);
				slots = Number(leaderDataValue.leader_slots);
			}

			switch(metric.key) {
				case 'skip_rate':
					value = blocks !== null && slots !== null && slots !== 0 ? 1 - (blocks / slots) : null;
					break;
				case 'cu':
					const totalCU = leaderDataValue ? Number(leaderDataValue.total_cu) : null;
					value = (totalCU !== null && blocks !== null && blocks !== 0) ? totalCU / blocks : null;
					break;
				case 'cv':
					value = voterDataValue ? Number(voterDataValue.total_consensus_vote_tx) / Number(voterDataValue.total_successful_vote_tx) : null;
					break;
				case 'vote_inclusion':
					value = Number(leaderDataValue.total_vote_tx) / Number(leaderDataValue.blocks) ;
					break;
				case 'latency':
					value = voterDataValue ? Number(voterDataValue.total_fork_slot_vote_latency) / Number(voterDataValue.total_fork_slots_voted_on) : null;
					break;
				case 'llv':
					value = voterDataValue ? Number(voterDataValue.total_low_latency_fork_slots) / Number(voterDataValue.total_fork_slots_voted_on) : null;
					break;
				case 'prior_skip_rate':
					value = leaderDataValue ? Number(leaderDataValue.prior_skips) / Number(leaderDataValue.leader_groups) : null;
					break;
				case 'subsequent_skip_rate':
					value = leaderDataValue ? Number(leaderDataValue.subsequent_skips) / Number(leaderDataValue.leader_groups) : null;
					break;
				case 'city_concentration':
					value = voterDataValue ? Number(voterDataValue.geo_concentration_city) : null;
					break;
				case 'apy':
					value = voterDataValue ? Number(voterDataValue.apy) : null;
					break;
				default:
					value = null;
			}
			return value;
		});

		return {
			label: metric.label,
			data: data,
			borderColor: metric.color,
			backgroundColor: metric.backgroundColor,
			hidden: metric.key !== initialMetric,
			fill: true,
			pointRadius: 3,
			pointHoverRadius: 5,
			borderWidth: 1,
			yAxisID: 'y',
			metricKey: metric.key,
			awardKey: metric.awardKey,
		};
	}).filter(dataset => dataset !== null);


	if (getAppState().cache.poolAverageDatasets) {
        getAppState().cache.poolAverageDatasets.forEach(dataset => {
            dataset.data = Array(epochNumbers.length).fill(dataset.data[0]);
            datasets.push(dataset);
        });
    }

	const averageDatasets = globalMetricsToDisplay.map(metric => {
		const averageValue = validatorDetails.raw_score[metric.key];
		return {
			label: `${metric.label} 10 Epoch Average`,
			data: Array(epochNumbers.length).fill(averageValue),
			borderColor: metric.color,
			borderDash: [5, 5],
			borderWidth: 2,
			pointRadius: 0,
			fill: false,
			hidden: metric.key !== initialMetric,
			metricKey: `${metric.key}_average`,
			awardKey: metric.awardKey
		};
	});
	datasets.push(...averageDatasets);

	const allValidatorsAverageData = epochNumbers.map(() => globalAllValidatorsAverage[globalMetricsToDisplay[0].key]);

        // Don't include APY in All Validators Average metrics because we only track APY for pool validators
	const allValidatorsAverageDatasets = globalMetricsToDisplay
        .filter(metric => metric.key !== 'prior_skip_rate' && metric.key !== 'subsequent_skip_rate' && metric.key !== 'apy')
        .map(metric => ({
            label: `All Validators Average (${metric.label})`,
            data: Array(epochNumbers.length).fill(globalAllValidatorsAverage[metric.key]),
            borderColor: '#b2b2b2',
            borderDash: [5,5],
            backgroundColor: 'rgba(53, 59, 78, 0.2)',
            borderWidth: 2,
            pointRadius: 0,
            fill: false,
            hidden: false,
            metricKey: `${metric.key}_all_validators_average`,
            awardKey: metric.awardKey,
            showLine: true,
            spanGaps: true,
            tension: 0.1,
            tooltipLabel: `All Validators Average (${metric.label}): ${formatMetricValueByMetricKey(metric.key, globalAllValidatorsAverage[metric.key])}`
        }));

	datasets.push(...allValidatorsAverageDatasets);

	const chartData = {
		labels: epochNumbers,
		datasets: datasets
	};

    combinedChart = new Chart(chartCanvas, {
		type: 'line',
		data: chartData,
		options: {
			responsive: true,
			maintainAspectRatio: false,
			animation: false,
			interaction: {
				mode: 'nearest',
				intersect: true,
				axis: 'x'
			},
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						callback: function(value, index, values) {
							const activeDataset = this.chart.data.datasets.find(ds => !ds.hidden);
							if (!activeDataset) return value;
							return formatMetricValueByLabel(activeDataset.label, value);
						}
					},
					grid: {
						drawBorder: false,
						color: function(context) {
							return context.tick.value === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)';
						}
					},
					afterDataLimits: (scale) => {
						if (combinedChart && combinedChart.data && combinedChart.data.datasets) {
							const newLimits = calculateYAxisRange(combinedChart.data.datasets);
							const activeDataset = combinedChart.data.datasets.find(ds => !ds.hidden);
							const allValidatorsAverageDataset = combinedChart.data.datasets.find(ds => ds.label === 'All Validators Average');

							if (activeDataset && (activeDataset.metricKey === 'cv' || activeDataset.metricKey === 'llv')) {
								scale.min = newLimits.min;
							} else {
								scale.min = 0;
							}

							if (allValidatorsAverageDataset && !allValidatorsAverageDataset.hidden) {
								const allValidatorsAverageValues = allValidatorsAverageDataset.data;
								scale.max = Math.max(newLimits.max, Math.max(...allValidatorsAverageValues));
							}
						}
					}
				},
				x: {
					title: {
                        display: true,
                        text: 'Epochs'
                    },
                    grid: {
                        drawBorder: false,
                        color: 'rgba(0, 0, 0, 0.05)'
                    }
				}
			},
            plugins: {
				dataTag: {},
                datalabels: {
                    display: false,
                },
                tooltip: {
					callbacks: {
						title: function(context) {
							return `Epoch ${context[0]?.label || ''}`;
						},
						label: function(context) {
							if (!context.dataset || context.dataIndex === undefined) return '';
							const dataset = context.dataset;
							const value = dataset.data[context.dataIndex];
							let formattedValue;
							if (dataset.metricKey.includes('_all_validators_average')) {
								formattedValue = formatMetricValueByMetricKey(dataset.metricKey.replace('_all_validators_average', ''), value);
							} else if (dataset.metricKey.includes('_pool_average')) {
								formattedValue = formatMetricValueByMetricKey(dataset.metricKey.replace('_pool_average', ''), value);
							} else {
								formattedValue = value !== null && value !== undefined ? formatMetricValueByLabel(dataset.label, value) : 'N/A';
							}
							return `${dataset.label}: ${formattedValue}`;
						},
					},
				},
                legend: {
					display: true,
					position: 'top',
					labels: {
						generateLabels: function(chart) {
							const datasets = chart.data.datasets;
							const mainDataset = datasets.find(ds => !ds.hidden && !ds.metricKey.includes('_average') && !ds.metricKey.includes('_pool_average'));
							const averageDataset = datasets.find(ds => !ds.hidden && ds.metricKey.includes('_average'));
							const poolAverageDataset = datasets.find(ds => !ds.hidden && ds.metricKey.includes('_pool_average'));
							const allValidatorsAverageDataset = datasets.find(ds => !ds.hidden && ds.metricKey.includes('_all_validators_average'));


							if (!mainDataset) return [];

							const legendItems = [
								{
									text: mainDataset.label,
									fillStyle: mainDataset.borderColor,
									strokeStyle: mainDataset.borderColor,
									lineWidth: 2,
									hidden: false
								},
								{
									text: '10 Epoch Average',
									fillStyle: 'transparent',
									strokeStyle: mainDataset.borderColor,
									lineWidth: 2,
									lineDash: [5, 5],
									hidden: false
								},
								{
									text: 'Pool Average',
									fillStyle: 'transparent',
									strokeStyle: '#dfa754',
									lineWidth: 3,
									hidden: false
								},
							];

							if (allValidatorsAverageDataset && mainDataset.metricKey !== 'prior_skip_rate' && mainDataset.metricKey !== 'subsequent_skip_rate') {
                                legendItems.push({
                                    text: 'All Validators Average',
                                    fillStyle: 'transparent',
                                    strokeStyle: '#b2b2b2',
                                    lineWidth: 2,
                                    lineDash: [5, 5],
                                    hidden: false
                                });
                            }

                            return legendItems;
						}
					},
                    onClick: function(event, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const chart = legend.chart;
                        const dataset = chart.data.datasets[index];

                        // Turn off all highlighting
                        globalMetricsToDisplay.forEach(metric => {
                            unhighlightGraphLine(metric.awardKey);
                            unhighlightStatRow(metric.awardKey);
                            unhighlightAward(metric.awardKey);
                        });

                        if (legendItem.text === 'Show All' || legendItem.text === 'Hide All') {
                            // Handle the "Show All/Hide All" click event
                            const isAllHidden = chart.data.datasets.every(dataset => dataset.hidden);
                            chart.data.datasets.forEach(dataset => {
                                dataset.hidden = !isAllHidden;
                            });
                            legendItem.text = isAllHidden ? 'Hide All' : 'Show All';
                        } else {
                            // Handle individual dataset click event
                            const awardKey = dataset.awardKey;
                            dataset.hidden = !dataset.hidden;
                            highlightGraphLine(awardKey);
                            highlightStatRow(awardKey);
                            highlightAward(awardKey);

                            // Update the user's graph preferences
                            userGraphPreferences[dataset.awardKey] = !dataset.hidden;
                        }

                        // Update the chart
                        chart.update();

                        // Trigger the updateChartLines function
                        updateChartLines();
                    },
					onHover: function(event, elements) {
						if (elements.length > 0) {
							const datasetIndex = elements[0].datasetIndex;
							const dataset = combinedChart.data.datasets[datasetIndex];
							dimOtherLines(combinedChart, datasetIndex);
							highlightGraphLine(dataset.awardKey);
							highlightStatRow(dataset.awardKey);
							highlightAward(dataset.awardKey);
						} else {
							combinedChart.data.datasets.forEach(dataset => {
								dataset.borderColor = dataset.borderColor.replace('0.3)', '1)');
							});
							combinedChart.update();
							globalMetricsToDisplay.forEach(metric => {
								unhighlightGraphLine(metric.awardKey);
								unhighlightStatRow(metric.awardKey);
								unhighlightAward(metric.awardKey);
							});
						}
					},

                    onLeave: function(event, legendItem) {
						if (legendItem) {
							const datasetIndex = legendItem.datasetIndex;
							if (datasetIndex !== undefined && datasetIndex < combinedChart.data.datasets.length) {
								const dataset = combinedChart.data.datasets[datasetIndex];

								if (legendItem.text === 'Show All' || legendItem.text === 'Hide All') {
									// Do nothing
								} else if (dataset && dataset.awardKey) {
									const awardKey = dataset.awardKey;
									unhighlightGraphLine(awardKey);
									unhighlightStatRow(awardKey);
									unhighlightAward(awardKey);
								}
							}
						}
					}
                }
            },
            elements: {
                line: {
                    tension: 0,
                    borderWidth: 1,

                },
				point: {
					hitRadius: 10,
					hoverRadius: 5
				}
            }
        },
		plugins: [dataTagPlugin],
    });

    chartCanvas.addEventListener('mousemove', (event) => {
        const activePoints = combinedChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        // Turn off all highlighting
        globalMetricsToDisplay.forEach(metric => {
            unhighlightGraphLine(metric.awardKey);
            unhighlightStatRow(metric.awardKey);
            unhighlightAward(metric.awardKey);
        });

        if (activePoints.length > 0) {
            const datasetIndex = activePoints[0].datasetIndex;
            const dataset = combinedChart.data.datasets[datasetIndex];
            highlightGraphLine(dataset.awardKey);
            highlightStatRow(dataset.awardKey);
            highlightAward(dataset.awardKey);
        }
    });

    chartCanvas.addEventListener('mouseout', () => {
        globalMetricsToDisplay.forEach(metric => {
            unhighlightGraphLine(metric.awardKey);
            unhighlightStatRow(metric.awardKey);
            unhighlightAward(metric.awardKey);
        });
    });

	highlightSelectedStat();

	return combinedChart;
}

function dimOtherLines(chart, activeDatasetIndex) {
    chart.data.datasets.forEach((dataset, index) => {
        if (index !== activeDatasetIndex) {
            dataset.borderColor = dataset.borderColor.replace('1)', '0.3)');
        } else {
            dataset.borderColor = dataset.borderColor.replace('0.3)', '1)');
        }
    });
    chart.update();
}

function updateChartLines() {
    if (combinedChart) {
        const checkboxes = document.querySelectorAll('.validator-details-summary-checkbox');
        checkboxes.forEach(checkbox => {
            const metricKey = checkbox.dataset.metricKey;
            const dataset = combinedChart.data.datasets.find(ds => ds.metricKey === metricKey);
            if (dataset) {
                dataset.hidden = !checkbox.checked;
            }
        });
        combinedChart.update();
    }
}

function formatMetricValueByLabel(label, value) {
    switch (label) {
		case 'Skip Rate':
		case 'Skip Rate 10 Epoch Average':
		case 'APY':
		case 'APY 10 Epoch Average':
		case 'City Concentration':
		case 'City Concentration 10 Epoch Average':
		case 'Country Concentration':
		case 'Country Concentration 10 Epoch Average':
		case 'Consensus Voting':
		case 'Consensus Voting 10 Epoch Average':
		case 'Low Latency Vote':
		case 'Low Latency Vote 10 Epoch Average':
		case 'Prior Skip Rate':
		case 'Prior Skip Rate 10 Epoch Average':
		case 'Subsequent Skip Rate':
			return `${(value * 100).toFixed(2)}%`;
		case 'Subsequent Skip Rate 10 Epoch Average':
			return `${(value * 100).toFixed(2)}%`;
		case 'Compute Units':
			return Math.floor(value).toLocaleString();
		case 'Compute Units 10 Epoch Average':
			return Math.floor(value).toLocaleString();
		case 'Vote Inclusion':
			return `${Number(value.toFixed(0)).toLocaleString()} tx`;
		case 'Vote Inclusion 10 Epoch Average':
			return `${Number(value.toFixed(0)).toLocaleString()} tx`;
		case 'Average Vote Latency':
			return `${value.toFixed(2)} slots`;
		case 'Average Vote Latency 10 Epoch Average':
			return `${value.toFixed(2)} slots`;
		case 'Extra Lamports':
			return render_sol(lamports_to_sol(value), true);
		default:
			return value.toString();
	}
}

function formatMetricValueByMetricKey(metricKey, value) {
    const metricLabel = metricKey.replace('_average', '').replace('_pool_average', '');

    switch (metricLabel) {
        case 'skip_rate':
        case 'apy':
        case 'city_concentration':
        case 'country_concentration':
        case 'cv':
        case 'llv':
        case 'prior_skip_rate':
        case 'subsequent_skip_rate':
            return `${(value * 100).toFixed(2)}%`;
        case 'cu':
            return Math.floor(value).toLocaleString();
        case 'vote_inclusion':
            return `${Number(value.toFixed(0)).toLocaleString()} tx`;
        case 'latency':
            return `${value.toFixed(2)} slots`;
        case 'pool_extra_lamports':
            return render_sol(lamports_to_sol(value), true);
        default:
            return value.toString();
    }
}

function lamports_to_sol(lamports) {
    if (typeof lamports === "number") {
        return lamports / 1e9;
    } else {
        return Number(lamports) / 1e9;
    }
}

function getValidatorAwards(pubkey) {
    const validatorAwards = [];
    awardCategories.forEach(category => {
        const winners = getAppState().cache.pool[category.awardKey];
        if (winners && winners.some(winner => winner.pubkey === pubkey)) {
            validatorAwards.push(category);
        }
    });
    return validatorAwards;
}


function createValidatorDetailsSection(title, chartId) {
    const section = document.createElement('div');
    section.classList.add('validator-details-section');

    const chartContainer = document.createElement('div');
    chartContainer.className = 'validator-details-chart-container';

    const chartCanvas = document.createElement('canvas');
    chartCanvas.id = chartId;
    chartCanvas.className = 'validator-details-chart';
    chartContainer.appendChild(chartCanvas);

    section.appendChild(chartContainer);

    return section;
}

const tooltipContent = {
	'Skip Rate': 'The number of blocks produced, divided by total number of leader slots.',
	'Compute Units': 'The total compute units in all transactions included in the leader\'s blocks, divided by the total number of leader blocks.',
	'Consensus Voting': 'The total blocks voted on where the vote landed in a block for which the voted-on block was not already confirmed at the beginning of the block, divided by the total number of slots voted on.',
	'Vote Inclusion': 'The number of votes included in subsequent blocks.',
	'Average Vote Latency': 'The total latency of all votes that the validator cast in the epoch, divided by the total number of slots voted on.',
	'Low Latency Vote': 'The total votes included in the leader\'s blocks, divided by the total number of leader blocks.',
	'Prior Skip Rate': 'The number of leader groups for which the first block of the leader group skipped a prior slot, divided by total number of leader groups.',
	'Subsequent Skip Rate': 'The number of leader groups for which the last block of the leader group has a child block which is not the next slot, divided by total number of leader groups.',
	'City Concentration': 'The fraction of total stake that is in the same city as the validator.  An unknown city is assigned 1.0, the worst possible city concentration.',
	'APY': 'Staking APY plus MEV APY. Annual Percentage Yield, the rate of return on staked SOL. ',
	'Location': 'Geographical location where the validator node is hosted.',
};

function populateValidatorSummary(validatorDetails, poolStake, summaryContent, pubkey, voterData, leaderData, epochNumbers, poolData) {
    summaryContent.innerHTML = '';

    const metricsContainer = document.createElement('div');
    metricsContainer.className = 'validator-details-metrics';

    // Determine location
    let location = "Unknown";
    if (validatorDetails.city && validatorDetails.country) {
        location = `${validatorDetails.city}, ${validatorDetails.country}`;
    } else if (validatorDetails.city) {
        location = validatorDetails.city;
    } else if (validatorDetails.country) {
        location = validatorDetails.country;
    }

    const tabContainer = document.createElement('div');
    tabContainer.className = 'validator-details-tab-container';

    const tabList = document.createElement('ul');
    tabList.className = 'validator-details-tab-list';

    const tab10EpochAvg = document.createElement('li');
    const tab10EpochAvgLink = document.createElement('a');
    tab10EpochAvgLink.textContent = '10 Epoch Average';
    tab10EpochAvgLink.href = '#';
    tab10EpochAvgLink.id = 'tabAverage';
    tab10EpochAvg.appendChild(tab10EpochAvgLink);

    const tabCurrentEpoch = document.createElement('li');
    const tabCurrentEpochLink = document.createElement('a');
    tabCurrentEpochLink.textContent = 'Current Epoch';
    tabCurrentEpochLink.href = '#';
    tabCurrentEpochLink.id = 'tabCurrentEpoch';
    tabCurrentEpoch.appendChild(tabCurrentEpochLink);

    tabList.appendChild(tab10EpochAvg);
    tabList.appendChild(tabCurrentEpoch);

    const poolDetails = getAppState().cache.pool;

    const isPoolValidator = poolDetails.pool_voters.has(pubkey);

    const tabContent = document.createElement('div');
	tabContent.className = 'validator-details-tab-content';

    const content10EpochAvg = createMetricsTable(validatorDetails, true, location, leaderData, poolData, isPoolValidator);
    content10EpochAvg.id = 'contentAverage';

    const currentEpoch = Math.max(...epochNumbers);
    const currentEpochData = voterData.get(BigInt(currentEpoch));
    const contentCurrentEpoch = createMetricsTable(currentEpochData, false, location, leaderData, poolData, isPoolValidator);
    contentCurrentEpoch.id = 'contentCurrentEpoch';

    tabContent.appendChild(content10EpochAvg);
    tabContent.appendChild(contentCurrentEpoch);

    tabContainer.appendChild(tabList);
    tabContainer.appendChild(tabContent);

    metricsContainer.appendChild(tabContainer);

    // Add event listeners for tab switching
	tab10EpochAvgLink.addEventListener('click', (e) => {
		e.preventDefault();
		switchTab(tab10EpochAvgLink, content10EpochAvg);
	});
	tabCurrentEpochLink.addEventListener('click', (e) => {
		e.preventDefault();
		switchTab(tabCurrentEpochLink, contentCurrentEpoch);
	});

	summaryContent.appendChild(metricsContainer);

	// Initially select the 10 Epoch Average tab
	switchTab(tab10EpochAvgLink, content10EpochAvg);

	return currentEpochData;
}

function switchTab(tabLink, tabContent) {
    // Get the closest tab container to limit the scope
    const tabContainer = tabLink.closest('.validator-details-tab-container');
    if (tabContainer) {
        // Hide all tab contents within this tab container
        tabContainer.querySelectorAll('.validator-details-tab-content > div').forEach(content => {
            content.style.display = 'none';
        });

        // Remove active class from all tab links within this tab container
        tabContainer.querySelectorAll('.validator-details-tab-list a').forEach(link => {
            link.classList.remove('active');
        });

        // Show the selected tab content
        tabContent.style.display = 'block';

        // Add active class to the clicked tab link
        tabLink.classList.add('active');

        // Maintain the selected stat row
        maintainSelectedStat();

        // Trigger chart resize if the chart is in the displayed tab
        if (tabContent.contains(document.getElementById('combined-chart'))) {
            if (combinedChart) {
                combinedChart.resize();
                combinedChart.update();
            }
        }
    }
}


function maintainSelectedStat() {
    const metricKey = getLastSelectedMetric();
    if (metricKey) {
        const rows = document.querySelectorAll('.validator-details-summary-table tr');
        rows.forEach(row => {
            if (row.getAttribute('data-metric-key') === metricKey) {
                row.style.backgroundColor = '#dfa754';
            } else {
                row.style.backgroundColor = '';
            }
        });
    }
}

function createMetricsTable(validatorData, is10EpochAvg, location, leaderData, poolData, isPoolValidator) {
    const table = document.createElement('table');
    table.className = 'validator-details-summary-table';

    let metricsToDisplay = [
        { key: 'skip_rate', awardKey: 'best_skip_rate', label: 'Skip Rate', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(244, 67, 54, 1)', backgroundColor: 'rgba(244, 67, 54, 0.2)', initiallyShown: true },
        { key: 'cu', awardKey: 'best_cu', label: 'Compute Units', format: (value) => value !== undefined ? Math.floor(value).toLocaleString() : '-', color: 'rgba(0, 188, 212, 1)', backgroundColor: 'rgba(0, 188, 212, 0.2)', initiallyShown: true },
        { key: 'cv', label: 'Consensus Voting', color: 'rgba(153, 102, 255, 1)', backgroundColor: 'rgba(153, 102, 255, 0.2)', awardKey: 'best_cv', isLowerBetter: false },
		{ key: 'vote_inclusion', awardKey: 'best_vote_inclusion', label: 'Vote Inclusion', format: (value) => value !== undefined ? `${Number(value.toFixed(0)).toLocaleString()} tx` : '-', color: 'rgba(76, 175, 80, 1)', backgroundColor: 'rgba(76, 175, 80, 0.2)', initiallyShown: true },
        { key: 'latency', awardKey: 'best_latency', label: 'Average Vote Latency', format: (value) => value !== undefined ? `${value.toFixed(2)} slots` : '-', color: 'rgba(233, 30, 99, 1)', backgroundColor: 'rgba(233, 30, 99, 0.2)', initiallyShown: true },
        { key: 'llv', awardKey: 'best_llv', label: 'Low Latency Vote', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(0, 150, 136, 1)', backgroundColor: 'rgba(0, 150, 136, 0.2)', initiallyShown: true },
        { key: 'prior_skip_rate', label: 'Prior Skip Rate', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(255, 87, 34, 1)', backgroundColor: 'rgba(255, 87, 34, 0.2)', initiallyShown: false },
        { key: 'subsequent_skip_rate', label: 'Subsequent Skip Rate', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(121, 85, 72, 1)', backgroundColor: 'rgba(121, 85, 72, 0.2)', initiallyShown: false },
        { key: 'city_concentration', awardKey: 'best_city_concentration', label: 'City Concentration', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(194, 179, 14, 1)', backgroundColor: 'rgba(194, 179, 14, 0.2)', initiallyShown: false },
        { key: 'apy', awardKey: 'best_apy', label: 'APY', format: (value) => value !== undefined ? percent_string(value, 2) : '-', color: 'rgba(76, 175, 80, 1)', backgroundColor: 'rgba(76, 175, 80, 0.2)', initiallyShown: false }
    ];

    // Remove APY from the metrics to display for non-pool validators, because APY can only be accurately computed
    // for pool validators.
    if (!isPoolValidator) {
        metricsToDisplay.splice(metricsToDisplay.findIndex((element) => element.key == 'ay'));
    }

    metricsToDisplay.forEach(metric => {
        const row = document.createElement('tr');
		row.setAttribute('data-metric-key', metric.key);
        const labelCell = document.createElement('td');
        labelCell.className = 'validator-details-summary-label';

        const infoIcon = document.createElement('span');
		infoIcon.className = 'info-icon-validator-details';
		infoIcon.innerHTML = 'i';

		labelCell.appendChild(infoIcon);

		const labelText = document.createElement('span');
		labelText.textContent = metric.label;
		labelCell.appendChild(labelText);

        let tooltipText = awardCategories.find(category => category.awardKey === metric.awardKey)?.tooltip;
        if (!tooltipText) {
            tooltipText = tooltipContent[metric.label] || 'Description not available';
        }
        infoIcon.setAttribute('data-tooltip', tooltipText);

        const valueCell = document.createElement('td');
        valueCell.className = 'validator-details-summary-value';

        if (metric.key === 'location') {
            valueCell.textContent = metric.value;
        } else {
            let value;
            if (is10EpochAvg) {
                value = validatorData.raw_score ? validatorData.raw_score[metric.key] : undefined;
            } else {
                const currentEpoch = getCurrentEpoch(leaderData);
                const leaderDataValue = leaderData.get(BigInt(currentEpoch));
                let blocks, slots;
                if (leaderDataValue) {
                    blocks = Number(leaderDataValue.blocks);
                    slots = Number(leaderDataValue.leader_slots);
                }
                switch(metric.key) {
                    case 'skip_rate':
                        value = blocks !== null && slots !== null && slots !== 0 ? 1 - (blocks / slots) : undefined;
                        break;
                    case 'cu':
                        const totalCU = leaderDataValue ? Number(leaderDataValue.total_cu) : null;
                        value = (totalCU !== null && blocks !== null && blocks !== 0) ? totalCU / blocks : undefined;
                        break;
                    case 'cv':
                        value = Number(validatorData.total_consensus_vote_tx) / Number(validatorData.total_successful_vote_tx);
                        break;
					case 'vote_inclusion':
						value = Number(leaderDataValue.total_vote_tx) / Number(leaderDataValue.blocks) ;
						break;
                    case 'latency':
                        value = Number(validatorData.total_fork_slot_vote_latency) / Number(validatorData.total_fork_slots_voted_on);
                        break;
                    case 'llv':
                        value = Number(validatorData.total_low_latency_fork_slots) / Number(validatorData.total_fork_slots_voted_on);
                        break;
                    case 'prior_skip_rate':
                        value = leaderDataValue ? Number(leaderDataValue.prior_skips) / Number(leaderDataValue.leader_groups) : undefined;
                        break;
                    case 'subsequent_skip_rate':
                        value = leaderDataValue ? Number(leaderDataValue.subsequent_skips) / Number(leaderDataValue.leader_groups) : undefined;
                        break;
                    case 'city_concentration':
                        value = Number(validatorData.geo_concentration_city);
                        break;
                    case 'apy':
                        value = Number(validatorData.apy);
                        break;
                    default:
                        value = validatorData[metric.key] !== undefined ? Number(validatorData[metric.key]) : undefined;
                }
            }
			valueCell.textContent = formatMetricValueByLabel(metric.label, value);
        }

        row.appendChild(labelCell);
        row.appendChild(valueCell);
        table.appendChild(row);

        if (metric.awardKey) {
            row.setAttribute('award-key', metric.awardKey);
            row.addEventListener('mouseenter', () => {
                highlightAward(metric.awardKey);
                highlightGraphLine(metric.awardKey);
            });
            row.addEventListener('mouseleave', () => {
                unhighlightAward(metric.awardKey);
                unhighlightGraphLine(metric.awardKey);
            });
        }
		row.addEventListener('click', () => {
			highlightRow(row);
			updateGraphVisibility(metric.key);
		});
    });

    const buttonContainer = document.createElement('div');
	buttonContainer.className = 'score-calculation-container';

	const buttonScoreContainer = document.createElement('div');
	buttonScoreContainer.className = 'button-score-container';

	const toggleButton = document.createElement('button');
	toggleButton.textContent = 'Show Score Calculation';
	toggleButton.className = 'score-calculation-button';

	const totalScoreDisplay = document.createElement('span');
	totalScoreDisplay.className = 'total-score-display';

	buttonScoreContainer.appendChild(toggleButton);
	buttonScoreContainer.appendChild(totalScoreDisplay);

	const scoreInfoContainer = document.createElement('div');
	scoreInfoContainer.className = 'score-info-container';
	scoreInfoContainer.style.display = 'none';

	const scoringKey = document.createElement('div');
	scoringKey.className = 'scoring-key';
	scoringKey.style.display = 'none';
	scoringKey.innerHTML = `
		<p><strong>Scoring Key: <i>(A * B = C)</i></strong></p>
		<p>A = Percentile of this value in the pool</p>
		<p>B = Score weighting of this stat</p>
		<p>C = Total contribution towards score</p>
	`;

	buttonContainer.appendChild(buttonScoreContainer);
	buttonContainer.appendChild(scoreInfoContainer);
	buttonContainer.appendChild(scoringKey);

	if (!is10EpochAvg) {
		buttonContainer.style.display = 'none';
	}

	let isCalculationVisible = false;

	const poolDetails = getAppState().cache.pool;

	toggleButton.addEventListener('click', () => {
		isCalculationVisible = !isCalculationVisible;
		toggleButton.textContent = isCalculationVisible ? 'Hide Score Calculation' : 'Show Score Calculation';

		metricsToDisplay.forEach(metric => {
			const row = table.querySelector(`tr[data-metric-key="${metric.key}"]`);
			if (row) {
				const valueCell = row.querySelector('.validator-details-summary-value');
				if (valueCell) {
					if (isCalculationVisible && validatorData.normalized_score && poolDetails.inclusion_weights) {
						const normalized_score = validatorData.normalized_score[metric.key];
						const weight = poolDetails.inclusion_weights[metric.key + "_weight"];
						if (normalized_score !== undefined && weight !== undefined) {
							const weighted_score = normalized_score * weight;
							const raw_score = validatorData.raw_score[metric.key];
							const formattedScore = formatMetricValueByLabel(metric.label, raw_score);
							valueCell.innerHTML = `${formattedScore} <span class="calculation">(${(normalized_score * 100).toFixed(2)} * ${weight.toFixed(3)} = ${(weighted_score * 100).toFixed(2)})</span>`;
						} else {
							const value = validatorData.raw_score ? validatorData.raw_score[metric.key] : undefined;
							valueCell.textContent = formatMetricValueByLabel(metric.label, value);
						}
					} else {
						const value = validatorData.raw_score ? validatorData.raw_score[metric.key] : undefined;
						valueCell.textContent = formatMetricValueByLabel(metric.label, value);
					}
				}
			}
		});

		if (isCalculationVisible) {
			totalScoreDisplay.textContent = `Total Score: ${(validatorData.total_score * 100).toFixed(2)}`;
			scoreInfoContainer.style.display = 'flex';
			scoringKey.style.display = 'block';
			totalScoreDisplay.style.display = 'inline-block';
		} else {
			scoreInfoContainer.style.display = 'none';
			scoringKey.style.display = 'none';
			totalScoreDisplay.style.display = 'none';
		}
	});



	const container = document.createElement('div');
	container.className = 'validator-details-content';
	container.appendChild(table);
	container.appendChild(buttonContainer);

	return container;
}

function setLastSelectedMetric(metricKey) {
    lastSelectedMetric = metricKey;
}

function getLastSelectedMetric() {
    return lastSelectedMetric;
}

function highlightSelectedStat() {
    const metricKey = getLastSelectedMetric() || 'llv'; // Default to 'skip_rate' if no metric is selected
    document.querySelectorAll('.validator-details-summary-table tr').forEach(row => {
        if (row.getAttribute('data-metric-key') === metricKey) {
            row.style.backgroundColor = '#dfa754';
        } else {
            row.style.backgroundColor = '';
        }
    });
    updateGraphVisibility(metricKey);
}

function highlightRow(clickedRow) {
    if (!clickedRow) return;
    const metricKey = clickedRow.getAttribute('data-metric-key');
    if (!metricKey) return;
    document.querySelectorAll('.validator-details-summary-table tr').forEach(row => {
        if (row === clickedRow) {
            row.style.backgroundColor = '#dfa754';
        } else {
            row.style.backgroundColor = '';
        }
    });
    setLastSelectedMetric(metricKey);
    updateGraphVisibility(metricKey);
}

function updateGraphVisibility(clickedMetricKey) {
    if (combinedChart) {
        combinedChart.data.datasets.forEach(dataset => {
            const isMainDataset = dataset.metricKey === clickedMetricKey;
            const isAverageDataset = dataset.metricKey === `${clickedMetricKey}_average`;
            const isPoolAverageDataset = dataset.metricKey === `${clickedMetricKey}_pool_average`;
            const isAllValidatorsAverageDataset = dataset.metricKey === `${clickedMetricKey}_all_validators_average`;
            dataset.hidden = !(isMainDataset || isAverageDataset || isPoolAverageDataset || isAllValidatorsAverageDataset);
        });
        combinedChart.update();
    }
}

function debounce(func, wait) {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

document.querySelectorAll('.validator-details-metrics-item').forEach((item, index) => {
    // Randomize the order for each item
    item.style.setProperty('--animation-order', Math.floor(Math.random() * 10));
});

//
// HELPER FUNCTIONS
//

function getCurrentEpoch(leaderData) {
    const epochNumbers = [...leaderData.keys()].map(Number);
    return Math.max(...epochNumbers);
}

   function elideString(str, maxLength, highlightQuery = '') {
       if (!str) return '';
       if (str.length <= maxLength) return str;

       const highlightIndex = str.toLowerCase().indexOf(highlightQuery.toLowerCase());
       if (highlightIndex === -1 || !highlightQuery) {
           // No highlight or no query, elide from the middle
           const start = str.slice(0, Math.floor(maxLength / 2) - 1);
           const end = str.slice(-(Math.ceil(maxLength / 2) - 2));
           return `${start}...${end}`;
       }

       const highlightEnd = highlightIndex + highlightQuery.length;
       if (highlightEnd <= maxLength - 3) {
           // Highlight is near the start, elide from the end
           return `${str.slice(0, maxLength - 3)}...`;
       } else if (str.length - highlightIndex <= maxLength - 3) {
           // Highlight is near the end, elide from the start
           return `...${str.slice(-(maxLength - 3))}`;
       } else {
           // Highlight is in the middle, elide from both ends
           const leftPad = Math.floor((maxLength - highlightQuery.length) / 2) - 2;
           const rightPad = Math.ceil((maxLength - highlightQuery.length) / 2) - 2;
           return `...${str.slice(highlightIndex - leftPad, highlightEnd + rightPad)}...`;
       }
   }

// Compact format means that < 100 shows fractional, >= 100 does not
function render_sol(sol, compact) {
    return ('\u25CE' + render_sol_value(sol, compact));
}

function render_sol_value(sol, compact) {
    if (compact && (sol >= 100) || (sol <= -100)) {
        return render_integer(sol);
    }
    else {
        return render_number(sol);
    }
}

function render_number(n) {
    return Number(n).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function render_integer(n) {
    return Number(n).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

// Generating percent strings
function percent_string(value, f) {
    if (f == null) {
        f = 3;
    }
    return (value * 100).toLocaleString(undefined, { minimumFractionDigits: f, maximumFractionDigits: f }) + "%";
}

//
// PAGE INITIALIZATION
//

function updateStakeAccountDropdown(stakeAccounts) {
    const stakeAccountDropdown = document.querySelector('.stake-account-dropdown');
    stakeAccountDropdown.innerHTML = '';

    if (stakeAccounts && stakeAccounts.length > 0) {
        stakeAccounts.forEach((account, index) => {
            const stakeAccountItem = document.createElement('div');
            stakeAccountItem.className = 'stake-account-item';
            stakeAccountItem.style.backgroundColor = index % 2 === 0 ? '#f1f1f1' : 'white';

            let iconUrl = account.validatorIcon || 'images/validators/validator-icon_missing_3.png';
            let isNewStyleValidatorIcon = iconUrl.startsWith('**icon_url**');
            if (isNewStyleValidatorIcon) {
                iconUrl = iconUrl.replace('**icon_url**', '');
            }

            // Create the elements for stake account item
            const stakeAccountLine1 = document.createElement('div');
            stakeAccountLine1.className = 'stake-account-item-line1';

            const img = document.createElement('img');
            img.src = iconUrl;
            img.alt = account.validatorName;
            img.referrerPolicy = 'no-referrer';
            img.className = (isNewStyleValidatorIcon ? '' : 'validator-icon-circle ') + 'validator-icon';
            img.style.marginRight = '10px';

            // Add onerror handler for missing images
            img.onerror = function () {
                this.src = 'images/validators/validator-icon_missing_3.png';
                this.className = 'validator-icon-circle validator-icon';
                this.style.borderRadius = '50%';
            };

            const span = document.createElement('span');
            span.textContent = `${account.balance.toFixed(4)} SOL`;

            stakeAccountLine1.appendChild(img);
            stakeAccountLine1.appendChild(span);

            const stakeAccountLine2 = document.createElement('div');
            stakeAccountLine2.className = 'stake-account-item-line2';
            stakeAccountLine2.textContent = elideString(account.validatorName, 20);

            stakeAccountItem.appendChild(stakeAccountLine1);
            stakeAccountItem.appendChild(stakeAccountLine2);

            stakeAccountItem.addEventListener('click', () => navigateToStakingTab(account, index));
            stakeAccountDropdown.appendChild(stakeAccountItem);
        });
    } else {
        const noStakeAccountsMessage = document.createElement('div');
        noStakeAccountsMessage.className = 'stake-account-item';
        noStakeAccountsMessage.textContent = 'No stake accounts found';
        stakeAccountDropdown.appendChild(noStakeAccountsMessage);
    }
}

function initMobileMessageClose() {
    const mobileMessageOverlay = document.querySelector('.mobile-message-overlay');
    const mobileMessage = document.querySelector('.mobile-message');
    const closeButton = document.querySelector('.mobile-message .close-button');

    function closeMobileMessage() {
        mobileMessageOverlay.style.display = 'none';
        mobileMessage.style.display = 'none';
    }

    if (closeButton && mobileMessage && mobileMessageOverlay) {
        closeButton.addEventListener('click', closeMobileMessage);
        mobileMessageOverlay.addEventListener('click', closeMobileMessage);
    }
}

document.addEventListener('DOMContentLoaded', initMobileMessageClose);

//
// FAQ SECTION
//

// Render the awards in the FAQ section
async function renderAwards() {
    const poolDetails = getAppState().cache.pool;
    if (poolDetails) {
        const awardContainer = document.getElementById('awardContainer');
		if (awardContainer.innerHTML) {
			awardContainer.innerHTML = '';
		}

        for (const category of awardCategories) {
            const awardData = poolDetails[category.awardKey];
            if (awardData) {
                const awardElement = createAwardElement(category, awardData);
                awardContainer.appendChild(awardElement);
            }
        }
    } else {
        console.error("Failed to load pool details from app state cache.");
    }
}

function createAwardElement(category, awardData) {
    const awardElement = document.createElement('div');
    awardElement.className = 'award-item';

    const awardImage = document.createElement('img');
    awardImage.src = category.trophyImage;
    awardImage.alt = category.title;

    const awardDetails = document.createElement('div');
    awardDetails.className = 'award-details';

    const awardTitle = document.createElement('h3');
    awardTitle.textContent = category.title;

    const awardCriterion = document.createElement('p');
    awardCriterion.textContent = `Criterion: ${category.description}`;

    const awardTooltip = document.createElement('p');
    awardTooltip.textContent = category.tooltip;

    awardDetails.appendChild(awardTitle);
    awardDetails.appendChild(awardCriterion);
    awardDetails.appendChild(awardTooltip);

    awardElement.appendChild(awardImage);
    awardElement.appendChild(awardDetails);

    return awardElement;
}

function displayRandomCharacterImage() {
    const numberOfImages = 7; // Total number of images in the folder
    const imageContainer = document.getElementById('characterImageContainer');
    imageContainer.innerHTML = ''; // Clear existing images

    if (numberOfImages === 0) {
        return; // If there are no images, do nothing
    }

    const randomIndex = Math.floor(Math.random() * numberOfImages) + 1000;
    const randomImage = `images/z_and_mu/z-and-mu_${randomIndex}.jpg`;

    // Create a container for centering
    const centerContainer = document.createElement('div');
    centerContainer.style.display = 'flex';
    centerContainer.style.justifyContent = 'center';
    centerContainer.style.alignItems = 'center';
    centerContainer.style.height = '200px';
    centerContainer.style.backgroundColor = '#1a202c'; // Dark blue background
    centerContainer.style.width = '100%';
    centerContainer.style.maxWidth = '100vw';
    centerContainer.style.overflow = 'hidden';

    // Create a container div
    const container = document.createElement('div');
    container.style.height = '200px';
    container.style.position = 'relative';
    container.style.display = 'flex';
    container.style.justifyContent = 'center';
    container.style.alignItems = 'center';

    // Create the image
    const image = document.createElement('img');
    image.src = randomImage;
    image.style.height = '100%';
    image.style.width = 'auto';
    image.style.maxWidth = 'none'; // Prevent image from shrinking
    image.style.transition = 'transform 0.3s ease';

    // Add hover effect
    container.addEventListener('mouseenter', () => {
        image.style.transform = 'scale(1.1)';
    });
    container.addEventListener('mouseleave', () => {
        image.style.transform = 'scale(1)';
    });

    // Set correct aspect ratio once image is loaded
    image.onload = function() {
        const aspectRatio = this.naturalWidth / this.naturalHeight;
        container.style.width = (200 * aspectRatio) + 'px';
    };

    // Append image to container
    container.appendChild(image);

    // Append container to center container
    centerContainer.appendChild(container);

    // Append center container to the image container
    imageContainer.appendChild(centerContainer);
}

async function renderDefiProjects() {
    const defiProjects = getAppState().cache.defiProjects;
    if (defiProjects) {
        const defiProjectsContainer = document.getElementById('defiProjectsContainer');
        defiProjectsContainer.innerHTML = '';

        for (const project of defiProjects) {
            const projectElement = createDefiProjectElement(project);
            defiProjectsContainer.appendChild(projectElement);
        }
    } else {
        console.error("Failed to load DeFi projects from app state cache.");
    }
}

function createDefiProjectElement(project) {
    const projectElement = document.createElement('div');
    projectElement.className = 'defi-project';

    const projectImage = document.createElement('img');
    projectImage.src = project.image;
    projectImage.alt = project.name;

    const projectName = document.createElement('h3');
    projectName.textContent = project.name;

    const projectDescription = document.createElement('p');
    projectDescription.textContent = project.description;

    const projectLink = document.createElement('a');
    projectLink.href = project.link;
    projectLink.target = '_blank';
    projectLink.textContent = 'Learn More';

    projectElement.appendChild(projectImage);
    projectElement.appendChild(projectName);
    projectElement.appendChild(projectDescription);
    projectElement.appendChild(projectLink);

    return projectElement;
}

async function renderPress() {
    const pressContainer = document.getElementById('Press');
    pressContainer.innerHTML = `
        <div class="faq-container">
            <h2>Press</h2>
            <p>Shinobi Performance Pool: Empowering Solana Validators</p>
            <p>The Shinobi Performance Pool is a cutting-edge staking solution designed to optimize validator performance and rewards on the Solana blockchain. By leveraging advanced metrics and a data-driven approach, the pool ensures that delegators receive the best possible returns while contributing to the overall health and security of the Solana network.</p>

            <div class="press-logos">
            <!-- Add press logos here -->
            </div>
        </div>
    `;

    const pressItems = getAppState().cache.pressItems;
    if (pressItems) {
        const pressLogosContainer = pressContainer.querySelector('.press-logos');

        for (const item of pressItems) {
            const pressElement = createPressElement(item);
            pressLogosContainer.appendChild(pressElement);
        }
    } else {
        console.error("Failed to load press items from app state cache.");
    }
}

function createPressElement(item) {
    const pressElement = document.createElement('div');
    pressElement.className = 'press-item';

    const pressLogo = document.createElement('img');
    pressLogo.src = item.logo;
    pressLogo.alt = item.title;

    const pressTitle = document.createElement('h3');
    pressTitle.textContent = item.title;

    const pressDescription = document.createElement('p');
    pressDescription.textContent = item.description;

    const pressLink = document.createElement('a');
    pressLink.href = item.link;
    pressLink.target = '_blank';
    pressLink.textContent = 'Read More';

    pressElement.appendChild(pressLogo);
    pressElement.appendChild(pressTitle);
    pressElement.appendChild(pressDescription);
    pressElement.appendChild(pressLink);

    return pressElement;
}

function renderPrivacy() {
    const privacyContainer = document.getElementById('Privacy');
    privacyContainer.innerHTML = `
        <div class="faq-container">
            <h2>Privacy Policy</h2>
            <p>At Shinobi Performance Pool, we respect your privacy and are committed to protecting your personal information. We do not use cookies or store any of your data. Your interactions with our website remain anonymous, and we do not track or collect any personally identifiable information.</p>
            <p>Please note that as a blockchain-based service, your transactions and interactions with the Solana network may be publicly visible on the blockchain. However, this data is not associated with your identity on our website.</p>
            <p>If you have any questions or concerns about our privacy practices, please contact us on
                    <a href="https://discord.gg/2wxRbzd66P" target="_blank">Discord</a>.</p>
        </div>
        `;
}

function renderTerms() {
    const termsContainer = document.getElementById('Terms');
    termsContainer.innerHTML = `
        <div class="faq-container">
            <h2>Terms of Service</h2>
            <p>By using the Shinobi Performance Pool website and services, you agree to the following terms and conditions:</p>
            <ol>
            <li>Your investment risks are your own responsibility. The Shinobi Performance Pool does not provide any guarantees or assurances regarding the performance of your investments.</li>
            <li>You are solely responsible for the security of your Solana wallet and private keys. The Shinobi Performance Pool is not liable for any loss or damage resulting from unauthorized access to your wallet.</li>
            <li>The Shinobi Performance Pool reserves the right to update or modify these terms of service at any time without prior notice. It is your responsibility to review the terms periodically for any changes.</li>
            </ol>
            <p>By continuing to use our services, you acknowledge and agree to the updated terms of service.</p>
        </div>
    `;
}

function updateCurrentYear() {
    const currentYear = new Date().getFullYear();
    document.getElementById('currentYear').textContent = currentYear;
    document.getElementById('currentYear2').textContent = currentYear;
}

//
// STAKING
//

function openWalletSelectionModal() {
    disableAllWalletButtons();
    const modalContainer = document.createElement('div');
    modalContainer.className = 'modal-container';

    // Add mobile detection
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    modalContainer.innerHTML = `
        <div class="modal-blur-overlay"></div>
        <div class="modal-content">
            <div class="spp-wallet-selection-popup">
                <div class="spp-wallet-selection-content">
                    <h3>Select Wallet Provider</h3>
                    <div class="mobile-wallet-note">
                        <p>
                            <b>Note:</b> some mobile devices and some wallet apps can't connect through the stand-alone browser.
                        </p>
                        <p>
                            If necessary, open your wallet app and launch its built-in browser (often the bottom-right icon).
                        </p>
                        <p>
                            From there, navigate to <b>xshin.fi</b>
                        </p>
                    </div>
                    <ul class="spp-wallet-list connected"></ul>
                    <ul class="spp-wallet-list installed"></ul>
                    <ul class="spp-wallet-list available"></ul>
                    <button class="spp-wallet-close-button">&times;</button>
                </div>
            </div>
        </div>
    `;

    let fixedContainer = document.getElementById('fixed-modal-container');
    if (!fixedContainer) {
        fixedContainer = document.createElement('div');
        fixedContainer.id = 'fixed-modal-container';
        fixedContainer.style.position = 'fixed';
        fixedContainer.style.top = '0';
        fixedContainer.style.left = '0';
        fixedContainer.style.width = '100%';
        fixedContainer.style.height = '100%';
        fixedContainer.style.pointerEvents = 'none';
        fixedContainer.style.zIndex = '1000';
        document.body.appendChild(fixedContainer);
    }

    fixedContainer.appendChild(modalContainer);

    const closeButton = modalContainer.querySelector('.spp-wallet-close-button');
    closeButton.addEventListener('click', closeAllModals);

    const overlay = modalContainer.querySelector('.modal-blur-overlay');
    overlay.addEventListener('click', closeAllModals);

    const connectedList = modalContainer.querySelector('.spp-wallet-list.connected');
    const installedList = modalContainer.querySelector('.spp-wallet-list.installed');
    const availableList = modalContainer.querySelector('.spp-wallet-list.available');

    // Helper function to create wallet item
    const createWalletItem = (wallet, isAvailable, statusText) => {
        const walletItem = document.createElement('li');
        walletItem.className = `spp-wallet-item ${isAvailable ? 'available' : 'not-installed'}`;

        const isBackpack = wallet.name === 'Backpack';
        const walletUrl = isBackpack ? BACKPACK_DOWNLOAD_URL : wallet.url || 'No URL';

        walletItem.innerHTML = `
            <img src="${wallet.icon}" alt="${wallet.name}" class="spp-wallet-logo">
            <span class="spp-wallet-name">${wallet.name}</span>
            <span class="spp-wallet-status">${statusText}</span>
        `;

        walletItem.addEventListener('click', async (event) => {
            event.preventDefault();
            if (isAvailable) {
                closeAllModals();
                showHeaderMessage('Connecting', true);

                const inWalletBrowser = isInWalletBrowser(wallet);

                if (inWalletBrowser) {
                    await connectWallet(wallet);
                } else {
                    await connectWallet(wallet);
                }

                updateWalletDisplay(true);
                hideHeaderMessage();
            } else {
                if (walletUrl !== 'No URL') {
                    window.open(walletUrl, '_blank');
                }
                closeAllModals();
            }
        });

        return walletItem;
    };

    // Sort and add wallets to appropriate lists
    getWallets().forEach(wallet => {
        const isBackpack = wallet.name === 'Backpack';

        // Check if wallet is available
        let isAvailable = wallet.readyState === WalletReadyState.Installed ||
                         wallet.readyState === WalletReadyState.Loadable;

        // Check if wallet is connected
        const isConnected = wallet.connected;

        // Create wallet item with appropriate status
        let statusText = 'Click to Install';
        if (isConnected) {
            statusText = 'Connected';
        } else if (isAvailable) {
            statusText = 'Installed';
        }

        const walletItem = createWalletItem(wallet, isAvailable, statusText);

        // Add to appropriate list
        if (isConnected) {
            connectedList.appendChild(walletItem);
        } else if (isAvailable) {
            installedList.appendChild(walletItem);
        } else {
            availableList.appendChild(walletItem);
        }
    });

    // Hide empty lists
    if (!connectedList.children.length) connectedList.style.display = 'none';
    if (!installedList.children.length) installedList.style.display = 'none';
    if (!availableList.children.length) availableList.style.display = 'none';

    // Add section headers if lists have content
    if (connectedList.children.length) {
        connectedList.insertAdjacentHTML('beforebegin', '<h4>Connected Wallets</h4>');
    }
    if (installedList.children.length) {
        installedList.insertAdjacentHTML('beforebegin', '<h4>Installed Wallets</h4>');
    }
    if (availableList.children.length) {
        availableList.insertAdjacentHTML('beforebegin', '<h4>Available Wallets</h4>');
    }
}

function isInWalletBrowser(wallet) {
    const walletDetectionMap = {
        'Alpha': {
            browser: ['alpha'],
            mobile: ['alpha']
        },
        'Avana': {
            browser: ['avana'],
            mobile: ['avana']
        },
        'Backpack': {
            browser: ['backpack', 'getBackpack'],
            mobile: ['backpack', 'getBackpack']
        },
        'BitKeep': {
            browser: ['bitkeep'],
            mobile: ['bitkeep']
        },
        'Bitpie': {
            browser: ['bitpie'],
            mobile: ['bitpie']
        },
        'Clover': {
            browser: ['clover'],
            mobile: ['clover']
        },
        'Coin98': {
            browser: ['coin98'],
            mobile: ['coin98']
        },
        'Coinbase': {
            browser: ['coinbaseWallet'],
            mobile: ['ethereum', 'coinbaseWallet'] // Coinbase often injects ethereum provider
        },
        'Coinhub': {
            browser: ['coinhub'],
            mobile: ['coinhub']
        },
        'Fractal': {
            browser: ['fractal'],
            mobile: ['fractal']
        },
        'Huobi': {
            browser: ['huobiWallet'],
            mobile: ['huobiWallet']
        },
        'HyperPay': {
            browser: ['hyperpay'],
            mobile: ['hyperpay']
        },
        'Krystal': {
            browser: ['krystal'],
            mobile: ['krystal']
        },
        'Math Wallet': {
            browser: ['solletWallet'],
            mobile: ['solletWallet', 'mathwallet']
        },
        'Neko': {
            browser: ['neko'],
            mobile: ['neko']
        },
        'Nightly': {
            browser: ['nightly'],
            mobile: ['nightly']
        },
        'Nufi': {
            browser: ['nufi'],
            mobile: ['nufi']
        },
        'Onto': {
            browser: ['onto'],
            mobile: ['onto']
        },
        'Particle': {
            browser: ['particle'],
            mobile: ['particle']
        },
        'Phantom': {
            browser: ['phantom'],
            mobile: ['phantom', 'ethereum', 'solana'] // Phantom mobile can inject multiple providers
        },
        'SafePal': {
            browser: ['safepal'],
            mobile: ['safepal']
        },
        'Saifu': {
            browser: ['saifu'],
            mobile: ['saifu']
        },
        'Salmon': {
            browser: ['salmon'],
            mobile: ['salmon']
        },
        'Sky': {
            browser: ['skyWallet'],
            mobile: ['skyWallet']
        },
        'Solong': {
            browser: ['solongWallet'],
            mobile: ['solongWallet']
        },
        'Solflare': {
            browser: ['solflare'],
            mobile: ['solflare']
        },
        'Spot': {
            browser: ['spotWallet'],
            mobile: ['spotWallet']
        },
        'Tokenary': {
            browser: ['tokenary'],
            mobile: ['tokenary']
        },
        'TokenPocket': {
            browser: ['tokenPocket'],
            mobile: ['tokenPocket']
        },
        'Trust': {
            browser: ['trustWallet'],
            mobile: ['trustWallet', 'ethereum'] // Trust often injects ethereum provider
        },
        'XDEFI': {
            browser: ['xdefi'],
            mobile: ['xdefi']
        }
    };

    const detectionInfo = walletDetectionMap[wallet.name];
    if (!detectionInfo) return false;

    // Check browser-specific properties
    if (detectionInfo.browser) {
        const hasBrowserProps = detectionInfo.browser.some(prop => {
            if (prop.startsWith('get')) {
                return typeof window[prop] === 'function';
            }
            return window[prop] !== undefined;
        });
        if (hasBrowserProps) return true;
    }

    // Check mobile-specific properties
    if (detectionInfo.mobile) {
        const hasMobileProps = detectionInfo.mobile.some(prop =>
            window[prop] !== undefined
        );
        if (hasMobileProps) return true;
    }

    return false;
}


function closeAllModals() {
    const fixedContainer = document.getElementById('fixed-modal-container');
    while (fixedContainer.firstChild) {
        fixedContainer.removeChild(fixedContainer.firstChild);
    }
    enableAllWalletButtons();
}

async function fetchXshinToSolRate() {
    async function getPoolReserves(connection, poolAddress) {
        const stakePoolAccount = await getStakePoolAccount(connection, poolAddress);
        const stakePool = stakePoolAccount.account.data;

        const solReserve = Number(stakePool.totalLamports);
        const xshinReserve = Number(stakePool.poolTokenSupply);

        return { solReserve, xshinReserve };
    }

    async function connectToRpcNode() {
        let rpcUrl = defaultRpcUrl;

        if (selectedRpcUrl) {
            rpcUrl = selectedRpcUrl;
        }

        try {
            const connection = new solanaWeb3.Connection(rpcUrl);
            await connection.getEpochInfo();
            return connection;
        } catch (error) {
            if (rpcUrl === defaultRpcUrl) {
                console.error(`Failed to connect to default RPC URL ${defaultRpcUrl}. Trying backup RPC URL.`);
                try {
                    const connection = new solanaWeb3.Connection(backupRpcUrl);
                    await connection.getEpochInfo();
                    console.warn('Using backup RPC URL due to failure of the default RPC URL.');
                    return connection;
                } catch (backupError) {
                    showTransactionDetail('Both default and backup RPC URLs failed to connect. Please try again later or try a custom RPC URL.', 'error');
                    throw new Error('Both default and backup RPC URLs failed. Please try again later or use a custom RPC URL.');
                }
            } else {
                showTransactionDetail('Failed to connect to RPC URL: ' + selectedRpcUrl, 'error');
                throw new Error('Invalid RPC URL: ' + selectedRpcUrl);
            }
        }
    }

    async function getXshinPriceFromPool() {
        try {
            const connection = await connectToRpcNode();
            const { solReserve, xshinReserve } = await getPoolReserves(connection, stakePool);
            const priceInSOL = solReserve / xshinReserve;
            return priceInSOL;
        } catch (error) {
            console.error('Error getting xSHIN price from pool:', error);
        }
    }

    return getXshinPriceFromPool();
}


async function renderStaking() {
    const connectedWallet = globalWallets.find(wallet => wallet.connected);

    if (connectedWallet) {
        await displayWalletInfo(connectedWallet);
        populateStakeAccountDropdown(connectedWallet.publicKey);

        const stakeAccountPubkey = document.getElementById('stakeAccountSelect').value;
        if (stakeAccountPubkey) {
            const { balance } = JSON.parse(stakeAccountPubkey);
            const estimate = await simulateStakeSolToStakeAccount(balance);
            document.getElementById('stakeToXshinEstimate').textContent = `~${estimate.toFixed(3)} `;
        }
    } else {
        populateStakeAccountDropdown(null);
    }

    let estimates = await getPriorityFeeEstimates();

    if (estimates) {
        updatePriorityFeeLevelDropdown(estimates, stakeSOL_EstimatedCU);
        // And set the global for estimates, to be used when submitting tx
        globalPriorityFees = estimates;
    }

    // Show initial estimate for the selected stake account
    const stakeAccountSelect = document.getElementById('stakeAccountSelect');
    if (stakeAccountSelect.value) {
        const selectedOption = stakeAccountSelect.selectedOptions[0];
        const balance = parseFloat(selectedOption.dataset.balance);
        if (!isNaN(balance)) {
            simulateStakeSolToStakeAccount(balance).then(estimate => {
                document.getElementById('stakeToXshinEstimate').textContent = `~${estimate.toFixed(3)}`;
            });
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const tabs = document.querySelectorAll('.stakingTabList li a');
    const contents = document.querySelectorAll('.stakingTabContent > div');

    tabs.forEach(function(tab) {
        tab.addEventListener('click', function(event) {
            event.preventDefault();
            const target = tab.getAttribute('data-tab');

            // Remove active class from all tabs and contents
            tabs.forEach(function(t) { t.classList.remove('active'); });
            contents.forEach(function(c) { c.classList.remove('active'); });

            // Add active class to clicked tab and corresponding content
            tab.classList.add('active');
            document.getElementById(target).classList.add('active');
        });
    });

    // Initial load: show the first tab content
    document.querySelector('.stakingTabList li a.active').click();
});

function updateStakeToXshinUI(isWalletConnected) {
    const stakeStakeAccountButton = document.getElementById('stakeStakeAccountButton');
    const stakeAccountSelect = document.getElementById('stakeAccountSelect');

    if (isWalletConnected) {
        stakeStakeAccountButton.textContent = 'Submit Transaction to Wallet';
        stakeAccountSelect.disabled = false;
        stakeStakeAccountButton.disabled = false;
    } else {
        stakeStakeAccountButton.textContent = 'Connect Wallet';
        stakeAccountSelect.disabled = true;
        stakeStakeAccountButton.disabled = false;
    }
}

function updateStakingUI(isWalletConnected) {
    const stakeAmount = document.getElementById('stakeAmount');
    const maxStakeButton = document.getElementById('maxStakeButton');
    const stakeButton = document.getElementById('stakeButton');

    if (isWalletConnected) {
        stakeAmount.disabled = false;
        maxStakeButton.disabled = false;
        stakeButton.disabled = false;
        stakeButton.textContent = 'Submit Transaction to Wallet';
    } else {
        stakeAmount.disabled = true;
        maxStakeButton.disabled = true;
        stakeButton.disabled = false;
        stakeButton.textContent = 'Connect Wallet';
    }
    updateStakeToXshinUI(isWalletConnected);
}

function updateUnstakingUI(isWalletConnected) {
    const unstakeXshinAmount = document.getElementById('unstakeXshinAmount');
    const unstakeMaxButton = document.getElementById('unstakeMaxButton');
    const unstakeXshinButton = document.getElementById('unstakeXshinButton');
    const unstakeToStakeAmount = document.getElementById('unstakeToStakeAmount');
    const unstakeToStakeMaxButton = document.getElementById('unstakeToStakeMaxButton');
    const unstakeToStakeButton = document.getElementById('unstakeToStakeButton');

    if (isWalletConnected) {
        unstakeXshinAmount.disabled = false;
        unstakeMaxButton.disabled = false;
        unstakeXshinButton.disabled = false;
        unstakeXshinButton.textContent = 'Submit Transaction to Wallet';
        unstakeToStakeAmount.disabled = false;
        unstakeToStakeMaxButton.disabled = false;
        unstakeToStakeButton.disabled = false;
        unstakeToStakeButton.textContent = 'Submit Transaction to Wallet';
    } else {
        unstakeXshinAmount.disabled = true;
        unstakeMaxButton.disabled = true;
        unstakeXshinButton.disabled = false;
        unstakeXshinButton.textContent = 'Connect Wallet';
        unstakeToStakeAmount.disabled = true;
        unstakeToStakeMaxButton.disabled = true;
        unstakeToStakeButton.disabled = false;
        unstakeToStakeButton.textContent = 'Connect Wallet';
    }
    updateStakeToXshinUI(isWalletConnected);
}

document.getElementById('maxStakeButton').addEventListener('click', async function() {
    if (!selectedWallet) {
        showTransactionDetail('Please connect your wallet first.', 'error');
        return;
    }

    try {
        const connection = await connectToRpcNode();
        const publicKey = await getPublicKey();
        const balance = await connection.getBalance(publicKey);
        const maxStakeAmount = (balance / solanaWeb3.LAMPORTS_PER_SOL) - 0.01; // Leave 0.01 SOL for fees

        if (maxStakeAmount <= 0) {
            showTransactionDetail('Insufficient balance for staking.', 'error');
            return;
        }

        document.getElementById('stakeAmount').value = maxStakeAmount.toFixed(9);
        document.getElementById('stakeAmount').dispatchEvent(new Event('input'));
    } catch (error) {
        showTransactionDetail('Failed to fetch max stake amount.', 'error');
    }
});

document.getElementById('unstakeMaxButton').addEventListener('click', async function() {
    if (!selectedWallet) {
        showTransactionDetail('Please connect your wallet first.', 'error');
        return;
    }

    try {
        const connection = await connectToRpcNode();
        const publicKey = await getPublicKey();
        const xShinBalance = await getXShinBalance(publicKey, poolMint);

        if (xShinBalance <= 0) {
            showTransactionDetail('No xSHIN balance available for unstaking.', 'error');
            return;
        }

        document.getElementById('unstakeXshinAmount').value = xShinBalance.toFixed(9);
        document.getElementById('unstakeXshinAmount').dispatchEvent(new Event('input'));
    } catch (error) {
        showTransactionDetail('Failed to fetch max unstake amount.', 'error');
    }
});

document.getElementById('unstakeToStakeMaxButton').addEventListener('click', async function() {
    if (!selectedWallet) {
        showTransactionDetail('Please connect your wallet first.', 'error');
        return;
    }

    try {
        const connection = await connectToRpcNode();
        const publicKey = await getPublicKey();
        const xShinBalance = await getXShinBalance(publicKey, poolMint);

        if (xShinBalance <= 0) {
            showTransactionDetail('No xSHIN balance available for unstaking to stake.', 'error');
            return;
        }

        document.getElementById('unstakeToStakeAmount').value = xShinBalance.toFixed(9);
        document.getElementById('unstakeToStakeAmount').dispatchEvent(new Event('input'));
    } catch (error) {
        showTransactionDetail('Failed to fetch max unstake to stake amount.', 'error');
    }
});

function disableAllWalletButtons() {
    const walletButtons = document.querySelectorAll('.connect-wallet-button, .reset-wallet-button, .disconnect-wallet-button, #stakeButton, #unstakeXshinButton, #unstakeToStakeButton, #maxStakeButton, #unstakeMaxButton, #unstakeToStakeMaxButton, #stakeStakeAccountButton');
    walletButtons.forEach(button => {
        button.disabled = true;
    });
    document.getElementById('stakeStakeAccountButton').disabled = true;
}

function enableAllWalletButtons() {
    const walletButtons = document.querySelectorAll('.connect-wallet-button, .reset-wallet-button, .disconnect-wallet-button, #stakeButton, #unstakeXshinButton, #unstakeToStakeButton, #maxStakeButton, #unstakeMaxButton, #unstakeToStakeMaxButton, #stakeStakeAccountButton');
    walletButtons.forEach(button => {
        button.disabled = false;
    });
    document.getElementById('stakeStakeAccountButton').disabled = false;
}

async function connectWallet(wallet) {
    disableAllWalletButtons();
    try {
        showHeaderMessage('Connecting', true);
        document.getElementById('walletButtonContainer').style.display = 'none';

        // Check if we're inside a wallet browser
        const inWalletBrowser = isInWalletBrowser(wallet);

        // If we're in the wallet's browser, try to auto-connect first
        if (inWalletBrowser && !wallet.connected) {
            try {
                await wallet.connect({ onlyIfTrusted: true });
            } catch (error) {
                // If auto-connect fails, proceed with normal connect
                if (!error.message.includes('User rejected')) {
                    await wallet.connect();
                }
            }
        } else {
            // Normal connection flow for non-wallet browsers
            await wallet.connect();
        }

        // Add one-time connect event listener for mobile wallet flow
        wallet.once('connect', async () => {
            connectedWallet = wallet;
            selectedWallet = wallet;
            showTransactionDetail('Wallet connected: ' + formatWalletName(wallet.name), 'success');

            // Clear any custom pubkey state
            selectedPubkey = '';
            document.getElementById('pubkeyInput').value = '';

            // Update UI elements for pubkey input
            document.getElementById('applyPubkeyButton').style.display = 'inline-block';
            document.getElementById('disconnectPubkeyButton').style.display = 'none';
            document.getElementById('pubkeyInput').disabled = false;
            document.getElementById('pubkeyInput').style.color = '';

            // Update wallet display and UI states
            await displayWalletInfo(wallet);
            updateWalletDisplay(true);
            updateStakingUI(true);
            updateUnstakingUI(true);

            enableAllWalletButtons();

            // Populate stake accounts if wallet has a public key
            if (wallet.publicKey) {
                const stakeAccounts = await getStakeAccounts(wallet.publicKey);
                populateStakeAccountDropdown(wallet.publicKey, stakeAccounts);
                updateStakeAccountDropdown(stakeAccounts);
            }

            hideHeaderMessage();
        });

        // For non-mobile or immediate connections, handle success immediately
        if (wallet.connected) {
            connectedWallet = wallet;
            selectedWallet = wallet;
            showTransactionDetail('Wallet connected: ' + formatWalletName(wallet.name), 'success');

            selectedPubkey = '';
            document.getElementById('pubkeyInput').value = '';

            document.getElementById('applyPubkeyButton').style.display = 'inline-block';
            document.getElementById('disconnectPubkeyButton').style.display = 'none';
            document.getElementById('pubkeyInput').disabled = false;
            document.getElementById('pubkeyInput').style.color = '';

            await displayWalletInfo(wallet);
            updateWalletDisplay(true);
            updateStakingUI(true);
            updateUnstakingUI(true);

            enableAllWalletButtons();

            if (wallet.publicKey) {
                const stakeAccounts = await getStakeAccounts(wallet.publicKey);
                populateStakeAccountDropdown(wallet.publicKey, stakeAccounts);
                updateStakeAccountDropdown(stakeAccounts);
            }

            hideHeaderMessage();
        }

    } catch (error) {
        if (error.name === 'WalletConnectionError') {
            showTransactionDetail('Wallet connection cancelled.', 'info');
        } else {
            showTransactionDetail('Failed to connect wallet: ' + error.message, 'error');
        }
        revertToDefaultHeader();
    } finally {
        if (!wallet.connected) {
            hideHeaderMessage();
            enableAllWalletButtons();
            document.getElementById('maxStakeButton').disabled = true;
            document.getElementById('unstakeMaxButton').disabled = true;
            document.getElementById('unstakeToStakeMaxButton').disabled = true;
            document.querySelector('.wallet-info-container').style.display = 'none';
            document.querySelector('.wallet-label').textContent = 'Wallet';
            updateWalletButtons(false);
        }
    }
}

// Detect wallet connection on page load
document.addEventListener('DOMContentLoaded', async () => {
    // Get list of available wallets
    const wallets = getWallets();

    // Check if we're on mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Only attempt auto-connect on mobile
    if (isMobile) {
        // Check each wallet adapter
        for (const wallet of wallets) {
            // Check if we're in this wallet's browser environment
            if (isInWalletBrowser(wallet)) {
                try {
                    // Try to reconnect without prompting
                    const connected = await wallet.connect({ silent: true })
                        .then(() => true)
                        .catch(() => false);

                    if (connected && wallet.connected) {
                        selectedWallet = wallet;
                        await displayWalletInfo(wallet);
                        updateWalletDisplay(true);
                        updateStakingUI(true);
                        updateUnstakingUI(true);

                        if (wallet.publicKey) {
                            const stakeAccounts = await getStakeAccounts(wallet.publicKey);
                            populateStakeAccountDropdown(wallet.publicKey, stakeAccounts);
                            updateStakeAccountDropdown(stakeAccounts);
                        }
                        break; // Exit after first successful connection
                    }
                } catch (error) {
                    // Silently handle auto-connect failures
                    console.log('Auto-connect attempt failed:', error);
                }
            }
        }
    }
    // On desktop, do nothing - wait for user to click connect button
});

function formatWalletName(name) {
    return name.toLowerCase().includes('wallet') ? name : `${name} Wallet`;
}

function revertToDefaultHeader() {
    const walletInfo = document.getElementById('walletInfo');
    const walletButtonContainer = document.getElementById('walletButtonContainer');

    walletInfo.style.display = 'none';
    walletButtonContainer.style.display = 'flex';
    document.querySelector('.wallet-label').textContent = 'Wallet';
    hideWalletAddress();
    resetBalance();
    updateWalletButtons(false);
}

async function stakeSOL() {
    setSubmitButtonsState(true);
    const amount = parseFloat(document.getElementById('stakeAmount').value);
    if (isNaN(amount) || amount <= 0) {
        showTransactionDetail('Please enter a valid stake amount.', 'error');
        setSubmitButtonsState(false);
        return;
    }
    try {
        const connection = await connectToRpcNode();
        const userPublicKey = await getPublicKey();
        const lamports = amount * solanaWeb3.LAMPORTS_PER_SOL;
        const transaction = new Transaction();
        const priorityFeeLevel = document.getElementById('priorityFeeLevel').value;
        if (priorityFeeLevel !== 'none') {
            await addPriorityFeeToTransaction(connection, transaction, stakeSOL_EstimatedCU, priorityFeeLevel);
        }
        const { instructions, signers } = await depositSol(connection, stakePool, userPublicKey, lamports);
        instructions.forEach((instruction) => transaction.add(instruction));
        transaction.feePayer = userPublicKey;
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        if (signers.length > 0) {
            transaction.partialSign(...signers);
        }
        let signature;
        if (selectedWallet.signAndSendTransaction) {
            const { signature: signatureResult } = await selectedWallet.signAndSendTransaction(transaction);
            signature = signatureResult;
        } else if (selectedWallet.signTransaction) {
            const signedTransaction = await selectedWallet.signTransaction(transaction);
            const rawTransaction = signedTransaction.serialize();
            signature = await connection.sendRawTransaction(rawTransaction);
        } else {
            throw new Error('Wallet does not support signAndSendTransaction or signTransaction');
        }
        monitorTransaction(connection, transaction, signature, userPublicKey);
    } catch (error) {
        showTransactionDetail('Failed to stake SOL.', 'error');
        setSubmitButtonsState(false);
    }
}

async function stakeStakeAccount() {
    setSubmitButtonsState(true);
    const selectedOption = document.getElementById('stakeAccountSelect').selectedOptions[0];
    if (!selectedOption) {
        showTransactionDetail('Please select a stake account from the dropdown.', 'error');
        setSubmitButtonsState(false);
        return;
    }

    const stakeAccountPublicKey = new PublicKey(selectedOption.dataset.pubkey);
    const validatorVoteAccount = new PublicKey(selectedOption.dataset.validatorVoteAccount);
    const amount = parseFloat(selectedOption.dataset.balance);

    if (isNaN(amount) || amount <= 0) {
        showTransactionDetail('Invalid stake account balance.', 'error');
        return;
    }

    try {
        const connection = await connectToRpcNode();
        const userPublicKey = await getPublicKey();
        const transaction = new Transaction();
        const priorityFeeLevel = document.getElementById('priorityFeeLevel').value;
        if (priorityFeeLevel !== 'none') {
            await addPriorityFeeToTransaction(connection, transaction, stakeAccount_EstimatedCU, priorityFeeLevel);
        }
        const { instructions, signers } = await depositStake(
            connection,
            stakePool,
            userPublicKey,
            validatorVoteAccount,
            stakeAccountPublicKey
        );
        instructions.forEach((instruction) => transaction.add(instruction));
        transaction.feePayer = userPublicKey;
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        if (signers.length > 0) {
            transaction.partialSign(...signers);
        }
        let signature;
        if (selectedWallet.signAndSendTransaction) {
            const { signature: signatureResult } = await selectedWallet.signAndSendTransaction(transaction);
            signature = signatureResult;
        } else if (selectedWallet.signTransaction) {
            const signedTransaction = await selectedWallet.signTransaction(transaction);
            const rawTransaction = signedTransaction.serialize();
            signature = await connection.sendRawTransaction(rawTransaction);
        } else {
            throw new Error('Wallet does not support signAndSendTransaction or signTransaction');
        }
        monitorTransaction(connection, transaction, signature, userPublicKey);
    } catch (error) {
        showTransactionDetail('Failed to stake stake account.', 'error');
        setSubmitButtonsState(false);
    }
}

async function monitorTransaction(connection, transaction, signature, publicKey) {
    showLoading();

    showTransactionDetail(`Transaction submitted: <a href="https://explorer.solana.com/tx/${signature}" target="_blank">view on explorer</a>`, 'info');

    let confirmed = false;
    let retryCount = 0;
    const maxRetries = 5;
    const initialTimeout = 1000; // Initial timeout in milliseconds
    const maxTimeout = 60000; // Maximum timeout in milliseconds (1 minute)

    while (!confirmed && retryCount < maxRetries) {
        try {
            let confirmationLevel = 'processed';
            const startTime = Date.now();

            while (!confirmed) {
                const confirmation = await connection.confirmTransaction(signature, confirmationLevel);

                if (confirmation.value.err) {
                    throw new Error('Transaction failed: ' + confirmation.value.err);
                }

                switch (confirmationLevel) {
                case 'processed':
                    showTransactionDetail('Transaction received by cluster leader and included in a block.', 'info');
                    confirmationLevel = 'confirmed';
                    break;
                case 'confirmed':
                    showTransactionDetail('Transaction confirmed by cluster majority.', 'info');
                    confirmationLevel = 'finalized';
                    break;
                case 'finalized':
                    showTransactionDetail('Success. Transaction finalized and cannot be rolled back.  ', 'success');
                    confirmed = true;
                    break;
                default:
                    showTransactionDetail('Unknown confirmation level.', 'error');
                    break;
                }

                const elapsedTime = Date.now() - startTime;

                if (!confirmed) {
                    if (elapsedTime > 60000) {
                        showTransactionDetail('When the Solana Network is congested, transactions can be delayed. If your transaction fails, please try again later.', 'warning');
                    } else if (elapsedTime > 30000) {
                        showTransactionDetail('Transactions can take several minutes to go through. Please stay on this screen.', 'warning');
                    }

                    const timeout = Math.min(initialTimeout * Math.pow(2, retryCount), maxTimeout);
                    await new Promise(resolve => setTimeout(resolve, timeout));
                    retryCount++;
                }
            }

            if (confirmed) {
                hideLoading();
                showHeaderMessage('Loading wallet...', true);
                xShinBalance_Last = 0;
                solBalance_Last = 0;
                stakeBalance_Last = 0;
                await fetchAndDisplayWalletBalances(publicKey);
                hideHeaderMessage();

                // Reset input boxes on successful transaction
                document.getElementById('stakeAmount').value = '';
                document.getElementById('unstakeXshinAmount').value = '';
                document.getElementById('unstakeToStakeAmount').value = '';

                // Clear estimate displays
                document.getElementById('solToXshinEstimate').textContent = '';
                document.getElementById('xshinToSolEstimate').textContent = '';
                document.getElementById('xshinToStakeEstimate').innerHTML = '';
            }
        } catch (error) {
            showTransactionDetail('Confirmation check error: ' + (error.message || 'Unknown error'), 'warning');

            const timeout = Math.min(initialTimeout * Math.pow(2, retryCount), maxTimeout);
            await new Promise(resolve => setTimeout(resolve, timeout));
            retryCount++;
        }
    }

    if (!confirmed) {
        showTransactionDetail('Transaction timed out. Please check the transaction link for the latest status.', 'error');
        hideLoading();
        setSubmitButtonsState(false);
    } else {
        setSubmitButtonsState(false);
    }
	await displayWalletInfo(selectedWallet);
}


async function simulateStakeSolToXshin(amount) {
    if (xshinToSolRate !== null) {
        const estimatedXshin = amount / xshinToSolRate;
        return estimatedXshin;
    } else {
        console.error('Failed to fetch xSHIN to SOL rate');
        return null;
    }
}

async function simulateStakeSolToStakeAccount(amount) {
    if (xshinToSolRate !== null) {
        const estimatedStake = amount / xshinToSolRate;
        return estimatedStake;
    } else {
        console.error('Failed to fetch xSHIN to SOL rate');
        return null;
    }
}


async function simulateUnstakeXshinToSol(amount) {
    if (xshinToSolRate !== null) {
        const estimatedSol = amount * xshinToSolRate;
        return estimatedSol;
    } else {
        console.error('Failed to fetch xSHIN to SOL rate');
        return null;
    }
}

async function simulateUnstakeXshinToStakeAccount(amount) {
    if (xshinToSolRate !== null) {
        const estimatedStake = amount * xshinToSolRate;
        return estimatedStake;
    } else {
        console.error('Failed to fetch xSHIN to SOL rate');
        return null;
    }
}

async function loadStakeAccounts(publicKey) {
    if (!publicKey) {
        globalSortedStakeAccounts = [];
        return;
    }
    const stakeAccounts = await getStakeAccounts(publicKey);
    globalSortedStakeAccounts = stakeAccounts.sort((a, b) => b.balance - a.balance);
}

function populateStakeAccountDropdown(publicKey = null, stakeAccounts = null) {
    const stakeAccountSelect = document.getElementById('stakeAccountSelect');
    const stakeAccountDropdown = document.querySelector('.stake-account-dropdown');

    // If stakeAccounts are provided, use them. Otherwise, use globalSortedStakeAccounts
    const accounts = stakeAccounts || globalSortedStakeAccounts;

    // Store the currently selected value
    const currentSelectedValue = stakeAccountSelect.value;

     if (!accounts || accounts.length === 0) {
        stakeAccountSelect.innerHTML = '<option disabled selected>No stake accounts found</option>';
        stakeAccountDropdown.innerHTML = '<div class="stake-account-item">No stake accounts found</div>';
        return;
    }

    stakeAccountSelect.innerHTML = '<option disabled value="">Select a stake account</option>';
    stakeAccountDropdown.innerHTML = '';

    accounts.forEach((account, index) => {
        const option = document.createElement('option');
        const optionValue = JSON.stringify({
            pubkey: account.pubkey,
            balance: account.balance,
            validatorVoteAccount: account.validatorPubkey
        });
        option.value = optionValue;
        option.text = `${account.balance.toFixed(4)} SOL - ${account.validatorName}`;
        option.dataset.pubkey = account.pubkey;
        option.dataset.balance = account.balance;
        option.dataset.validatorVoteAccount = account.validatorPubkey;
        stakeAccountSelect.appendChild(option);

        const stakeAccountItem = document.createElement('div');
        stakeAccountItem.className = 'stake-account-item';

        let iconUrl = account.validatorIcon || 'images/validators/validator-icon_missing_3.png';
        let isNewStyleValidatorIcon = iconUrl.startsWith('**icon_url**');
        if (isNewStyleValidatorIcon) {
            iconUrl = iconUrl.replace('**icon_url**', '');
        }

        // Create the elements for stake account item
        const stakeAccountLine1 = document.createElement('div');
        stakeAccountLine1.className = 'stake-account-item-line1';

        const img = document.createElement('img');
        img.src = iconUrl;
        img.alt = account.validatorName;
        img.referrerPolicy = 'no-referrer';
        img.className = (isNewStyleValidatorIcon ? '' : 'validator-icon-circle ') + 'validator-icon';
        img.style.marginRight = '10px';

        // Add onerror handler for missing images
        img.onerror = function () {
            this.src = 'images/validators/validator-icon_missing_3.png';
            this.className = 'validator-icon-circle validator-icon';
            this.style.borderRadius = '50%';
        };

        const span = document.createElement('span');
        span.textContent = `${account.balance.toFixed(4)} SOL`;

        stakeAccountLine1.appendChild(img);
        stakeAccountLine1.appendChild(span);

        const stakeAccountLine2 = document.createElement('div');
        stakeAccountLine2.className = 'stake-account-item-line2';
        stakeAccountLine2.textContent = elideString(account.validatorName, 20);

        stakeAccountItem.appendChild(stakeAccountLine1);
        stakeAccountItem.appendChild(stakeAccountLine2);

        stakeAccountItem.addEventListener('click', () => navigateToStakingTab(account, index));
        stakeAccountDropdown.appendChild(stakeAccountItem);
    });

    // Restore the previously selected value if it still exists
    if (currentSelectedValue) {
        for (let i = 0; i < stakeAccountSelect.options.length; i++) {
            if (stakeAccountSelect.options[i].value === currentSelectedValue) {
                stakeAccountSelect.selectedIndex = i;
                break;
            }
        }
    }

    // If no option was selected, select the first non-disabled option
    if (stakeAccountSelect.selectedIndex === 0) {
        for (let i = 1; i < stakeAccountSelect.options.length; i++) {
            if (!stakeAccountSelect.options[i].disabled) {
                stakeAccountSelect.selectedIndex = i;
                break;
            }
        }
    }
}


// Returns:
// {
//    low: xxx,
//    medium: xxx_lamports,
//    high: xxx_lamports
// }
//
// All of the above are microlamports per compute unit.
async function getPriorityFeeEstimates() {
    try {
        // Only Helius RPC provides the getPriorityFeeEstimate function.
        // Queries for an estimate using the Jupiter swap API, which frequently has priority fee tx so is a good
        // candidate for estimating priority fees.
        const response = await fetch(heliusRpcUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jsonrpc: '2.0',
                id: 1,
                method: 'getPriorityFeeEstimate',
                params: [{
                    accountKeys: ['JUP6LkbZbjS1jKKwapdHNy74zcZ3tLUZoi5QNyVTaV4'],
                    options: {
                        includeAllPriorityFeeLevels: true
                    }
                }]
            })
        });

        const data = await response.json();
        const priorityFeeLevels = data.result.priorityFeeLevels;

        return {
            low : priorityFeeLevels.low,
            medium : priorityFeeLevels.medium,
            high : priorityFeeLevels.high
        };
    } catch (error) {
        console.error('Error fetching priority fee estimates:', error);
        return { };
    }
}

function updatePriorityFeeLevelDropdown(estimates, cu)
{
    // Convert the fee estimates from lamports to SOL
    const lowFee = (((estimates.low * cu) / (1000 * 1000)) / solanaWeb3.LAMPORTS_PER_SOL).toFixed(9);
    const mediumFee = (((estimates.medium * cu) / (1000 * 1000)) / solanaWeb3.LAMPORTS_PER_SOL).toFixed(9);
    const highFee = (((estimates.high * cu) / (1000 * 1000)) / solanaWeb3.LAMPORTS_PER_SOL).toFixed(9);

    // Update the dropdown options with the fee estimates in SOL
    const dropdown = document.getElementById('priorityFeeLevel');
    dropdown.options[1].text = `Low (${lowFee} SOL)`;
    dropdown.options[2].text = `Medium (${mediumFee} SOL)`;
    dropdown.options[3].text = `High (${highFee} SOL)`;
}

async function addPriorityFeeToTransaction(connection, transaction, cu, priorityLevel) {
    try {
        let microlamports_per_cu = null;
        if (priorityLevel == "low") {
            microlamports_per_cu = Math.floor(globalPriorityFees.low);
        }
        else if (priorityLevel == "medium") {
            microlamports_per_cu = Math.floor(globalPriorityFees.medium);
        }
        else if (priorityLevel == "high") {
            microlamports_per_cu = Math.floor(globalPriorityFees.high);
        }
        if (microlamports_per_cu == null) {
            throw new Error("Failed to set priority fee level");
        }
        transaction.add(solanaWeb3.ComputeBudgetProgram.setComputeUnitLimit({ units: cu }));
        transaction.add(solanaWeb3.ComputeBudgetProgram.setComputeUnitPrice({ microLamports: microlamports_per_cu }));
    } catch (error) {
        console.error('Error adding priority fee to transaction:', error);
    }
}

async function unstakeXshinToSol() {
    setSubmitButtonsState(true);
    const amount = parseFloat(document.getElementById('unstakeXshinAmount').value);
    if (isNaN(amount) || amount <= 0) {
        showTransactionDetail('Please enter a valid unstake amount.', 'error');
        setSubmitButtonsState(false);
        return;
    }
    try {
        const connection = await connectToRpcNode();
        const userPublicKey = await getPublicKey();
        const solReceiver = userPublicKey;
        const transaction = new Transaction();
        const priorityFeeLevel = document.getElementById('priorityFeeLevel').value;
        if (priorityFeeLevel !== 'none') {
            await addPriorityFeeToTransaction(connection, transaction, unstakeToSOL_EstimatedCU, priorityFeeLevel);
        }
        const { instructions, signers } = await withdrawSol(connection, stakePool, userPublicKey, solReceiver, amount);
        instructions.forEach((instruction) => transaction.add(instruction));
        transaction.feePayer = userPublicKey;
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        if (signers.length > 0) {
            transaction.partialSign(...signers);
        }
        let signature;
        if (selectedWallet.signAndSendTransaction) {
            const { signature: signatureResult } = await selectedWallet.signAndSendTransaction(transaction);
            signature = signatureResult;
        } else if (selectedWallet.signTransaction) {
            const signedTransaction = await selectedWallet.signTransaction(transaction);
            const rawTransaction = signedTransaction.serialize();
            signature = await connection.sendRawTransaction(rawTransaction);
        } else {
            throw new Error('Wallet does not support signAndSendTransaction or signTransaction');
        }
        monitorTransaction(connection, transaction, signature, userPublicKey);
    } catch (error) {
        showTransactionDetail('Failed to unstake xSHIN to SOL.', 'error');
        setSubmitButtonsState(false);
    }
}


async function unstakeXshinToStakeAccount() {
    setSubmitButtonsState(true);
    const amount = parseFloat(document.getElementById('unstakeToStakeAmount').value);
    if (isNaN(amount) || amount <= 0) {
        showTransactionDetail('Please enter a valid unstake amount.', 'error');
        setSubmitButtonsState(false);
        return;
    }
    try {
        const connection = await connectToRpcNode();
        const stakePoolAddress = new PublicKey(stakePool);
        const userPublicKey = await getPublicKey();
        const transaction = new Transaction();
        const priorityFeeLevel = document.getElementById('priorityFeeLevel').value;
        if (priorityFeeLevel !== 'none') {
            await addPriorityFeeToTransaction(connection, transaction, unstakeToStake_EstimatedCU, priorityFeeLevel);
        }
        const { instructions, signers } = await withdrawStake(connection, stakePoolAddress, userPublicKey, amount);
        instructions.forEach((instruction) => transaction.add(instruction));
        transaction.feePayer = userPublicKey;
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        if (signers.length > 0) {
            transaction.partialSign(...signers);
        }
        let signature;
        if (selectedWallet.signAndSendTransaction) {
            const { signature: signatureResult } = await selectedWallet.signAndSendTransaction(transaction);
            signature = signatureResult;
        } else if (selectedWallet.signTransaction) {
            const signedTransaction = await selectedWallet.signTransaction(transaction);
            const rawTransaction = signedTransaction.serialize();
            signature = await connection.sendRawTransaction(rawTransaction);
        } else {
            throw new Error('Wallet does not support signAndSendTransaction or signTransaction');
        }
        monitorTransaction(connection, transaction, signature, userPublicKey);
    } catch (error) {
        showTransactionDetail('Failed to unstake xSHIN to stake account.', 'error');
        setSubmitButtonsState(false);
    }
}


document.getElementById('stakeButton').addEventListener('click', async function () {
    if (!selectedWallet) {
        openWalletSelectionModal();
    } else {
        const amount = parseFloat(document.getElementById('stakeAmount').value);
        await stakeSOL();
    }
});

document.getElementById('stakeStakeAccountButton').addEventListener('click', async function () {
    if (!selectedWallet) {
        openWalletSelectionModal();
        return;
    }

    const selectedOption = document.getElementById('stakeAccountSelect').selectedOptions[0];
    const amount = selectedOption.dataset.balance;

    if (isNaN(amount) || amount <= 0) {
        showTransactionDetail('Invalid stake amount. Please enter a valid number.', 'error');
        return;
    }

    await stakeStakeAccount();
});

document.getElementById('unstakeXshinButton').addEventListener('click', async function () {
    if (!selectedWallet) {
        openWalletSelectionModal();
    } else {
        const amount = parseFloat(document.getElementById('unstakeXshinAmount').value);
        await unstakeXshinToSol();
    }
});

document.getElementById('unstakeToStakeButton').addEventListener('click', async function () {
    if (!selectedWallet) {
        openWalletSelectionModal();
    } else {
        const amount = parseFloat(document.getElementById('unstakeToStakeAmount').value);
        await unstakeXshinToStakeAccount();
    }
});

function showTransactionDetail(message, type) {
    const transactionDetails = document.getElementById('transactionDetails');
    const alertClass = `alert-${type}`;

    const alertElement = document.createElement('div');
    alertElement.className = `alert ${alertClass}`;
    alertElement.innerHTML = `
            <span class="alert-message">${message}</span>
            <span class="alert-timestamp">${new Date().toLocaleTimeString()} ${new Date().toLocaleDateString()}</span>
        `;

    transactionDetails.insertBefore(alertElement, transactionDetails.firstChild);
}

async function getXShinBalance(publicKey, poolMint) {
    try {
        let connection;
        try {
            connection = await connectToRpcNode();
        } catch (error) {
            console.error('Error connecting to RPC node:', error);
            showTransactionDetail('Failed to fetch xSHIN balance.', 'error');
            return;  // Return early if RPC connection fails
        }

        const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
            mint: poolMint,
        });

        let xShinBalance = 0;

        if (tokenAccounts.value.length === 0) {
            console.error('No xSHIN token accounts found.');
        } else {
            for (const { account } of tokenAccounts.value) {
                const accountInfo = account.data.parsed.info;
                xShinBalance += parseFloat(accountInfo.tokenAmount.uiAmountString);
            }
        }
        return xShinBalance;
    } catch (error) {
        showTransactionDetail('Error getting xSHIN token accounts: ' + error.message, 'error');
        return 0;
    }
}

async function applyPubkey() {
    const pubkeyInput = document.getElementById('pubkeyInput');
    const selectedPubkey_ToTest = pubkeyInput.value.trim();

    if (!selectedPubkey_ToTest) {
        return;
    }

    if (isValidPubkey(selectedPubkey_ToTest)) {
        if (selectedWallet) {
            await disconnectWallet();
        }

		const walletInfoContainer = document.querySelector('.wallet-info-container');
        walletInfoContainer.classList.add('custom-pubkey');

        selectedPubkey = selectedPubkey_ToTest;
    } else {
        showTransactionDetail('Invalid pubkey: ' + selectedPubkey_ToTest, 'error');
        return;
    }

    try {
        const walletInfoElement = document.getElementById('walletInfo');

        showHeaderMessage('Loading pubkey...', true);
        walletInfoElement.style.display = 'none';

        let connection = await connectToRpcNode();

        const publicKey = new solanaWeb3.PublicKey(selectedPubkey);

        showTransactionDetail('Custom pubkey applied: <a href="https://explorer.solana.com/address/' + selectedPubkey + '" target="_blank">' + selectedPubkey + '</a>', 'success');

        displayWalletAddress(selectedPubkey, true);
        document.querySelector('.wallet-label').textContent = 'Custom Pubkey';

        const solBalance = await connection.getBalance(publicKey) / 1e9;
        const xShinBalance = await getXShinBalance(publicKey, poolMint);
        const stakeAccounts = await getStakeAccounts(publicKey);
        const stakeBalance = stakeAccounts.reduce((total, account) => total + account.balance, 0);

        await displayBalance(solBalance, xShinBalance, stakeBalance, stakeAccounts);

        hideHeaderMessage();
        walletInfoElement.style.display = 'block';

        document.getElementById('applyPubkeyButton').style.display = 'none';
        document.getElementById('disconnectPubkeyButton').style.display = 'inline-block';
        document.getElementById('pubkeyInput').disabled = true;
        document.getElementById('pubkeyInput').style.color = 'green';

        document.getElementById('connectWalletButton').style.display = 'none';

        populateStakeAccountDropdown(publicKey, stakeAccounts);
		updateStakeAccountDropdown(stakeAccounts);

        const stakeAccountSelect = document.getElementById('stakeAccountSelect');
        stakeAccountSelect.innerHTML = '<option disabled selected>Connect wallet to see stake accounts</option>';
    } catch (error) {
        showTransactionDetail('Invalid pubkey2: ' + error, 'error');
    }
    updatePubkeyStatus();
}

async function applyRpcUrl() {
    const rpcUrlInput = document.getElementById('rpcUrlInput');
    let selectedRpcUrl_ToTest = rpcUrlInput.value;

    if (!selectedRpcUrl_ToTest) {
        showTransactionDetail('RPC URL cannot be empty', 'error');
        return;
    }

    // Trim the URL to remove any leading or trailing spaces
    selectedRpcUrl_ToTest = selectedRpcUrl_ToTest.trim();

    let error = await getInvalidRpcUrlDescription(selectedRpcUrl_ToTest);
    if (error) {
        showTransactionDetail('Invalid RPC URL: ' + error, 'error');
    }
    else {
        // Try to connect to test it out
        try {
            const connection = new solanaWeb3.Connection(selectedRpcUrl_ToTest);
            await connection.getEpochInfo();
        }
        catch (error) {
            showTransactionDetail('Failed to connect to RPC URL: ' + error, 'error');
            return;
        }
        selectedRpcUrl = selectedRpcUrl_ToTest;
        showTransactionDetail('RPC URL applied: ' + selectedRpcUrl, 'success');
        document.getElementById('applyRpcButton').style.display = 'none';
        document.getElementById('resetRpcButton').style.display = 'inline-block';
        rpcUrlInput.disabled = true;
        rpcUrlInput.style.color = 'green';
    }
    updateRpcUrlStatus();
}

function displayWalletAddress(address, isCustomPubkey = false) {
    const walletAddressElement = document.getElementById('walletAddress');
    if (walletAddressElement) {
        const elidedAddress = `${address.slice(0, 5)}...${address.slice(-5)}`;
        walletAddressElement.innerHTML = `
            <img src="images/disconnect-button_64.png" alt="Disconnect" class="disconnect-icon" onclick="handleDisconnect()">
            <a href="https://explorer.solana.com/address/${address}" target="_blank" id="walletAddressLink">${elidedAddress}</a>
            <img src="images/refresh-button_64.png" alt="Refresh" class="refresh-icon" onclick="handleRefresh()">
        `;
        walletAddressElement.style.display = 'flex';
    }
}

function hideWalletAddress() {
    const walletAddressElement = document.getElementById('walletAddress');
    if (walletAddressElement) {
        walletAddressElement.style.display = 'none';
    }
}

async function displayBalance(solBalance, xShinBalance, stakeBalance, stakeAccounts) {
	resetBalance();

    const solBalanceElement = document.getElementById('solBalanceValue');
    const xShinBalanceElement = document.getElementById('xShinBalanceValue');
    const stakeBalanceElement = document.getElementById('stakeBalanceValue');
    const walletBalanceCard = document.getElementById('walletBalance');
    const stakeBalanceContainer = document.querySelector('.stake-balance-container');
    const stakeValidatorIcon = document.querySelector('.stake-validator-icon');
    const stakeAccountDropdown = document.querySelector('.stake-account-dropdown');

    animateBalance(solBalanceElement, solBalance);
    animateBalance(xShinBalanceElement, xShinBalance);
    animateBalance(stakeBalanceElement, stakeBalance);

    walletBalanceCard.style.display = 'flex';
    stakeBalanceContainer.style.display = stakeBalance > 0 ? 'flex' : 'none';

    if (stakeBalance > 0) {
        stakeValidatorIcon.src = 'images/solana_logo.png';
        stakeValidatorIcon.alt = 'Solana Logo';
    }

    stakeAccountDropdown.innerHTML = '';
	if (stakeAccounts && stakeAccounts.length > 0) {
        stakeAccounts.forEach((account, index) => {
            const stakeAccountItem = document.createElement('div');
            stakeAccountItem.className = 'stake-account-item';
            stakeAccountItem.style.backgroundColor = index % 2 === 0 ? '#f1f1f1' : 'white';

            let iconUrl = account.validatorIcon || 'images/validators/validator-icon_missing_3.png';
            let isNewStyleValidatorIcon = iconUrl.startsWith('**icon_url**');
            if (isNewStyleValidatorIcon) {
                iconUrl = iconUrl.replace('**icon_url**', '');
            }

            stakeAccountItem.innerHTML = `
                <div class="stake-account-item-line1">
                    <img class="${isNewStyleValidatorIcon ? '' : 'validator-icon-circle '}validator-icon" src="${iconUrl}" alt="${account.validatorName}">
                    <span>${account.balance.toFixed(4)} SOL</span>
                </div>
                <div class="stake-account-item-line2">${elideString(account.validatorName, 20)}</div>
            `;

			stakeAccountItem.addEventListener('click', () => navigateToStakingTab(account, index));
            stakeAccountDropdown.appendChild(stakeAccountItem);
        });
	} else {
		const noStakeAccountsMessage = document.createElement('div');
		noStakeAccountsMessage.className = 'stake-account-item';
		noStakeAccountsMessage.textContent = 'No stake accounts found3';
		stakeAccountDropdown.appendChild(noStakeAccountsMessage);
	}

    if (!selectedPubkey && selectedWallet && selectedWallet.publicKey) {
		const stakeAccountSelect = document.getElementById('stakeAccountSelect');
		if (stakeAccountSelect && stakeAccountSelect.options.length === 0) {
			await populateStakeAccountDropdown(selectedWallet.publicKey);
		}
	} else if (!selectedWallet || !selectedWallet.publicKey) {
		const stakeAccountSelect = document.getElementById('stakeAccountSelect');
		if (stakeAccountSelect && stakeAccountSelect.options.length === 0) {
			stakeAccountSelect.innerHTML = '<option disabled selected>Connect wallet to see stake accounts</option>';
		}
	}
}

async function navigateToStakingTab(account, index) {
    const stakeAccountDropdown = document.querySelector('.stake-account-dropdown');
    stakeAccountDropdown.classList.remove('show');

    if (selectedPubkey) {
        return;
    }

    openTab(null, 'Staking');

    // Wait for the tab to open and the content to be rendered
    await new Promise(resolve => setTimeout(resolve, 100));

    // Click the "Stake" tab
    document.querySelector('.stakingTabList a[data-tab="stake"]').click();

    // Wait for the "Stake" tab to be active
    await new Promise(resolve => setTimeout(resolve, 100));

    // Click the "Stake → xSHIN" sub-tab
    document.querySelector('.sub-tab-list li a[data-tab="stakeToXshin"]').click();

    // Wait for the sub-tab to open
    await new Promise(resolve => setTimeout(resolve, 100));

    const stakeAccountSelect = document.getElementById('stakeAccountSelect');

    // Add 1 to the index to account for the default "Select a stake account" option
    const selectIndex = index + 1;
    if (selectIndex < stakeAccountSelect.options.length) {
        stakeAccountSelect.selectedIndex = selectIndex;

        // Trigger the change event on the select element
        const event = new Event('change');
        stakeAccountSelect.dispatchEvent(event);

        // Update the estimate
        const balance = parseFloat(stakeAccountSelect.options[selectIndex].dataset.balance);
        const estimate = await simulateStakeSolToStakeAccount(balance);
        document.getElementById('stakeToXshinEstimate').textContent = `~${estimate.toFixed(3)}`;
    }
}

function resetBalance(){
    solBalance_Last = 0;
    xShinBalance_Last = 0;
    stakeBalance_Last = 0;
	document.getElementById('solBalanceValue').textContent = '0';
    document.getElementById('xShinBalanceValue').textContent = '0';
    document.getElementById('stakeBalanceValue').textContent = '0';
    document.querySelector('.stake-balance-container').style.display = 'none';
}

// Save custom RPC and pubkey when the page is unloaded
window.addEventListener('beforeunload', () => {
    customRpcUrl = selectedRpcUrl;
    customPubkey = selectedPubkey;
});

// Load custom RPC and pubkey when the page is loaded
window.addEventListener('load', () => {
    if (customRpcUrl) {
        selectedRpcUrl = customRpcUrl;
        document.getElementById('rpcUrlInput').value = customRpcUrl;
    }
    if (customPubkey) {
        selectedPubkey = customPubkey;
        document.getElementById('pubkeyInput').value = customPubkey;
    }
    if (customRpcUrl || customPubkey) {
        document.getElementById('advancedOptionsCheckbox').checked = true;
        document.getElementById('advancedOptionsContainer').style.display = 'block';
    }

	handleUrlParams();
});

async function displayWalletInfo(wallet, prevBalances = {}, isCustomPubkey = false) {
    const walletButtonContainer = document.getElementById('walletButtonContainer');
    const walletInfoElement = document.getElementById('walletInfo');
    const defaultHeaderElement = document.getElementById('defaultHeader');

    if (wallet.publicKey || selectedPubkey) {
        const publicKey = wallet.publicKey || new solanaWeb3.PublicKey(selectedPubkey);

		const walletInfoContainer = document.querySelector('.wallet-info-container');
		if (isCustomPubkey) {
            walletInfoContainer.classList.add('custom-pubkey');
        } else {
            walletInfoContainer.classList.remove('custom-pubkey');
        }

        // Load stake accounts
        await loadStakeAccounts(publicKey);

        // Fetch and display wallet balances
        await fetchAndDisplayWalletBalances(publicKey, prevBalances);

        // Display wallet address
        displayWalletAddress(publicKey.toString(), isCustomPubkey);

        // Update UI elements
        walletButtonContainer.style.display = 'none';
        defaultHeaderElement.style.display = 'block';
        walletInfoElement.style.display = 'flex';

        // Populate stake account dropdown
        populateStakeAccountDropdown();

        hideHeaderMessage();
    } else {
        // Reset UI for disconnected state
        walletButtonContainer.style.display = 'flex';
        walletInfoElement.style.display = 'none';
        defaultHeaderElement.style.display = 'block';

		walletInfoContainer.classList.remove('custom-pubkey');

        // Clear stake accounts
        globalSortedStakeAccounts = [];
        populateStakeAccountDropdown();
    }
}

async function fetchAndDisplayWalletBalances(publicKey, prevBalances = {}) {
    try {
        let connection = await connectToRpcNode();
        const solBalance = await connection.getBalance(publicKey) / 1e9;
        const xShinBalance = await getXShinBalance(publicKey, poolMint);
        const stakeAccounts = await getStakeAccounts(publicKey);
        const stakeBalance = stakeAccounts.reduce((total, account) => total + account.balance, 0);

        await displayBalance(solBalance, xShinBalance, stakeBalance, stakeAccounts, prevBalances);
		updateStakeAccountDropdown(stakeAccounts);

        // Update wallet address display
        const walletAddressLink = document.getElementById('walletAddressLink');
        const fullAddress = publicKey.toString();
        const elidedAddress = `${fullAddress.slice(0, 5)}...${fullAddress.slice(-5)}`;
        walletAddressLink.textContent = elidedAddress;
        walletAddressLink.href = `https://explorer.solana.com/address/${fullAddress}`;

        document.getElementById('walletInfo').style.display = 'flex';
    } catch (error) {
        showTransactionDetail('Failed to fetch wallet balances.', 'error');
    }
}

globalThis.handleDisconnect = function handleDisconnect() {
    if (selectedPubkey && selectedPubkey !== '') {
        disconnectPubkey();
    } else {
        disconnectWallet();
    }
}

globalThis.handleRefresh = function handleRefresh() {
    if (selectedPubkey && selectedPubkey !== '') {
        refreshCustomPubkey();
    } else {
        refreshWallet();
    }
}

function updateWalletDisplay(isConnected) {
    const walletInfo = document.getElementById('walletInfo');
    const walletButtonContainer = document.getElementById('walletButtonContainer');
    const headerMessage = document.getElementById('headerMessage');

    if (isConnected) {
        walletInfo.style.display = 'flex';
        walletButtonContainer.style.display = 'none';
        headerMessage.style.display = 'none';
    } else {
        walletInfo.style.display = 'none';
        walletButtonContainer.style.display = 'flex';
        headerMessage.style.display = 'none';
    }
}

async function getStakeAccounts(publicKey) {
    try {
        let connection;
        try {
            connection = await connectToRpcNode();
        } catch (error) {
            showTransactionDetail('Failed to fetch stake accounts.', 'error');
            return [];
        }

        const stakeAccounts = await connection.getParsedProgramAccounts(solanaWeb3.StakeProgram.programId, {
            filters: [
                { dataSize: 200 },
                { memcmp: { offset: 12, bytes: publicKey.toBase58() } },
            ],
        });

        const parsedStakeAccounts = stakeAccounts.map(({ pubkey, account }) => ({
            pubkey,
            balance: account.lamports / solanaWeb3.LAMPORTS_PER_SOL,
            validatorPubkey: account.data.parsed.info.stake.delegation.voter,
        }));

        const stakeAccountsWithMeta = await Promise.all(parsedStakeAccounts.map(async (account) => {
            const validatorInfo = getAppState().cache.pool.pool_voters.get(account.validatorPubkey);
            return {
                ...account,
                validatorName: validatorInfo ? validatorInfo.details.name : account.validatorPubkey,
                validatorIcon: validatorInfo ? validatorInfo.details.icon_url : 'images/validators/validator-icon_missing_3.png',
            };
        }));

		stakeAccountsWithMeta.sort((a, b) => b.balance - a.balance);
        return stakeAccountsWithMeta;
    } catch (error) {
        showTransactionDetail('Error getting stake accounts: ' + error.message, 'error');
        return [];
    }

}

export function openStakeTab(evt, tabName) {
    const stakeTabContent = document.getElementsByClassName("stake-tab-content");
    for (let i = 0; i < stakeTabContent.length; i++) {
        stakeTabContent[i].classList.remove("active");
    }

    const stakeTabLinks = document.getElementsByClassName("stake-tab-link");
    for (let i = 0; i < stakeTabLinks.length; i++) {
        stakeTabLinks[i].classList.remove("active");
    }

    document.getElementById(tabName).classList.add("active");
    evt.currentTarget.classList.add("active");
}

export function openUnstakeTab(evt, tabName) {
    const unstakeTabContent = document.getElementsByClassName("unstake-tab-content");
    for (let i = 0; i < unstakeTabContent.length; i++) {
        unstakeTabContent[i].classList.remove("active");
    }

    const unstakeTabLinks = document.getElementsByClassName("unstake-tab-link");
    for (let i = 0; i < unstakeTabLinks.length; i++) {
        unstakeTabLinks[i].classList.remove("active");
    }

    document.getElementById(tabName).classList.add("active");
    evt.currentTarget.classList.add("active");
}


function animateBalance(element, targetValue) {
    const startValue = parseFloat(element.textContent) || 0;
    const duration = 1000;
    const decimals = 4;
    const factor = Math.pow(10, decimals);

    const startTime = performance.now();
    const endTime = startTime + duration;

    const step = (timestamp) => {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const value = startValue + (targetValue - startValue) * progress;
        element.textContent = (Math.round(value * factor) / factor).toFixed(decimals);

        if (progress < 1) {
            requestAnimationFrame(step);
        }
    };

    requestAnimationFrame(step);
}

function resetRpcUrl() {
    document.getElementById('rpcUrlInput').value = '';
    showTransactionDetail('Custom RPC URL reset.', 'success');
    selectedRpcUrl = '';
    updateRpcUrlStatus();
    document.getElementById('applyRpcButton').style.display = 'inline-block';
    document.getElementById('resetRpcButton').style.display = 'none';
    document.getElementById('rpcUrlInput').disabled = false;
    document.getElementById('rpcUrlInput').style.color = '';
}

function disconnectPubkey() {
    showTransactionDetail('Custom Pubkey disconnected.', 'success');
    updatePubkeyStatus();
    hideWalletAddress();
	resetBalance();
    clearInputsAndEstimates();
    clearStakeAccountDropdown();

	globalSortedStakeAccounts = [];
    populateStakeAccountDropdown();

	const walletInfoContainer = document.querySelector('.wallet-info-container');
    walletInfoContainer.classList.remove('custom-pubkey');

    document.getElementById('applyPubkeyButton').style.display = 'inline-block';
    document.getElementById('disconnectPubkeyButton').style.display = 'none';

    const pubkeyInput = document.getElementById('pubkeyInput');
    pubkeyInput.disabled = false;
    pubkeyInput.style.color = '';
    pubkeyInput.style.backgroundColor = '';
    pubkeyInput.value = '';

    setTimeout(() => {
        pubkeyInput.value = selectedPubkey.toString();
        pubkeyInput.value = '';
    }, 10);

    xShinBalance_Last = 0;
    solBalance_Last = 0;
    stakeBalance_Last = 0;
    document.getElementById('defaultHeader').style.display = 'block';
    document.getElementById('walletInfo').style.display = 'none';

    document.querySelector('.wallet-label').textContent = 'Wallet';
    selectedPubkey = '';

	document.getElementById('walletButtonContainer').style.display = 'flex';
    document.getElementById('connectWalletButton').style.display = 'inline-block';
	updateWalletDisplay(false);

	const stakeAccountSelect = document.getElementById('stakeAccountSelect');
    stakeAccountSelect.innerHTML = '<option disabled selected>Connect wallet to see stake accounts</option>';

    // Ensure the 'Connect Wallet' button is displayed
    const walletButtonContainer = document.getElementById('walletButtonContainer');
    walletButtonContainer.style.display = 'flex';
    const walletInfo = document.getElementById('walletInfo');
    walletInfo.style.display = 'none';

}

async function updateRpcUrlStatus() {
    const statusIcon = document.getElementById('rpcStatusIcon');
    const input = document.getElementById('rpcUrlInput');

    if (!input.value.length) {
        statusIcon.textContent = '';
        return;
    }

    if (statusIcon && input.value) {
        statusIcon.textContent = '✓';
        statusIcon.style.color = 'green';
    } else {
        statusIcon.textContent = '✗';
        statusIcon.style.color = 'red';
    }
}

function updatePubkeyStatus(event) {
    const statusIcon = document.getElementById('pubkeyStatusIcon');
    const input = event ? event.target : document.getElementById('pubkeyInput');

    if (!input.value.length) {
        statusIcon.textContent = '';
        return;
    } else if (statusIcon && input.value && isValidPubkey(input.value)) {
        statusIcon.textContent = '✓';
        statusIcon.style.color = 'green';
    } else {
        statusIcon.textContent = '✗';
        statusIcon.style.color = 'red';
    }
}

function setSubmitButtonsState(disabled) {
    const buttons = [
        document.getElementById('stakeButton'),
        document.getElementById('unstakeXshinButton'),
        document.getElementById('unstakeToStakeButton'),
        document.getElementById('stakeStakeAccountButton')
    ];
    buttons.forEach(button => {
        if (button) {
            button.disabled = disabled;
        }
    });
}

async function disconnectWallet() {
    if (selectedPubkey) {
        disconnectPubkey();
        return;
    }

    disableAllWalletButtons();
    hideWalletInfo();
    showDefaultHeader();

	globalSortedStakeAccounts = [];
    populateStakeAccountDropdown();

    try {
        await selectedWallet.disconnect();
        connectedWallet = null;
        selectedWallet = null;

        clearInputsAndEstimates();
        clearStakeAccountDropdown();

        showTransactionDetail('Wallet disconnected', 'success');
        updateStakingUI(false);
        updateUnstakingUI(false);
        hideWalletBalance();
        hideWalletAddress();
        hideHeaderMessage();
		updateWalletDisplay(false);
    } catch (error) {
        showTransactionDetail('Error disconnecting wallet: ' + error.message, 'error');
    } finally {
        enableAllWalletButtons();
        document.getElementById('maxStakeButton').disabled = true;
        document.getElementById('unstakeMaxButton').disabled = true;
        document.getElementById('unstakeToStakeMaxButton').disabled = true;
        document.querySelector('.wallet-info-container').style.display = 'none';
        document.querySelector('.wallet-label').textContent = 'Wallet';
        updateWalletButtons(false);
    }

    // Ensure the 'Connect Wallet' button is displayed
    const walletButtonContainer = document.getElementById('walletButtonContainer');
    walletButtonContainer.style.display = 'flex';
    const walletInfo = document.getElementById('walletInfo');
    walletInfo.style.display = 'none';
}

function hideWalletBalance() {
    const walletBalanceCard = document.getElementById('walletBalance');
    if (walletBalanceCard) {
        walletBalanceCard.style.display = 'none';
    }
}

function hideDefaultHeader() {
    document.getElementById('defaultHeader').style.display = 'none';
}

function showDefaultHeader() {
    const defaultHeaderElement = document.getElementById('defaultHeader');
    const headerMessageElement = document.getElementById('headerMessage');
    if (defaultHeaderElement) {
        defaultHeaderElement.style.display = 'block';
    }
    if (headerMessageElement) {
        headerMessageElement.style.display = 'none';
    }
	document.getElementById('defaultHeader').style.display = 'block';

}

function hideWalletInfo() {
    const walletBalanceCard = document.getElementById('walletBalance');
    const walletAddressElement = document.getElementById('walletAddress');
    const defaultHeaderElement = document.getElementById('defaultHeader');
    const walletInfoElement = document.getElementById('walletInfo');

    if (walletBalanceCard) walletBalanceCard.style.display = 'none';
    if (walletAddressElement) walletAddressElement.style.display = 'none';
    if (walletInfoElement) walletInfoElement.style.display = 'none';
    if (defaultHeaderElement) defaultHeaderElement.style.display = 'block';

	document.querySelector('.wallet-info-container').style.display = 'none';

}

async function refreshCustomPubkey() {
    disableAllWalletButtons();
    try {
        showHeaderMessage('Refreshing', true);
		hideDefaultHeader();
        resetBalance();
		hideWalletBalance();

        clearInputsAndEstimates();

        const publicKey = new solanaWeb3.PublicKey(selectedPubkey);
        await displayWalletInfo({ publicKey, name: 'Custom Pubkey' }, {}, true); // Add a third parameter to indicate it's a custom pubkey
        showTransactionDetail('Custom pubkey refreshed successfully', 'success');

        updateStakingUI(false);
        updateUnstakingUI(false);

        await loadStakeAccounts(publicKey);
        populateStakeAccountDropdown();
    } catch (error) {
        showTransactionDetail('Error refreshing custom pubkey: ' + error.message, 'error');
    } finally {
        hideHeaderMessage();
        enableAllWalletButtons();
        updateWalletButtons(false);
    }
}

async function refreshWallet() {
    if (selectedPubkey) {
        await refreshCustomPubkey();
        return;
    }

    disableAllWalletButtons();
    try {
        showHeaderMessage('Refreshing', true);

        // Store previous balance values
        const prevSolBalance = parseFloat(document.getElementById('solBalanceValue').textContent) || 0;
        const prevXShinBalance = parseFloat(document.getElementById('xShinBalanceValue').textContent) || 0;
        const prevStakeBalance = parseFloat(document.getElementById('stakeBalanceValue').textContent) || 0;

        hideWalletBalance();
        clearInputsAndEstimates();

		const stakeAccountSelect = document.getElementById('stakeAccountSelect');
        stakeAccountSelect.innerHTML = '<option disabled selected>Select a stake account</option>';
        globalSortedStakeAccounts = [];

        await displayWalletInfo(selectedWallet, { prevSolBalance, prevXShinBalance, prevStakeBalance });
        showTransactionDetail('Wallet refreshed successfully.', 'success');

        updateStakingUI(true);
        updateUnstakingUI(true);

        const publicKey = selectedWallet.publicKey || new solanaWeb3.PublicKey(selectedPubkey);
        await loadStakeAccounts(publicKey);
        populateStakeAccountDropdown();
    } catch (error) {
        showTransactionDetail('Error refreshing wallet: ' + error.message, 'error');
    } finally {
        hideHeaderMessage();
        enableAllWalletButtons();
        updateWalletButtons(selectedPubkey ? false : true);
    }
}

function clearInputsAndEstimates() {
    document.getElementById('stakeAmount').value = '';
    document.getElementById('unstakeXshinAmount').value = '';
    document.getElementById('unstakeToStakeAmount').value = '';
    document.getElementById('solToXshinEstimate').textContent = '';
    document.getElementById('xshinToSolEstimate').textContent = '';
    document.getElementById('xshinToStakeEstimate').innerHTML = '';
	document.getElementById('stakeToXshinEstimate').textContent = '';
}

function clearStakeAccountDropdown() {
    const stakeAccountSelect = document.getElementById('stakeAccountSelect');
    stakeAccountSelect.innerHTML = '<option disabled selected>Connect wallet to see stake accounts</option>';
    globalSortedStakeAccounts = [];
}

function updateWalletButtons(isConnected) {
    const connectButton = document.getElementById('connectWalletButton');
    const walletInfoContainer = document.querySelector('.wallet-info-container');
    const defaultHeader = document.getElementById('defaultHeader');
    const walletLabel = document.querySelector('.wallet-label');

    if (isConnected || selectedPubkey) {
        if (connectButton) connectButton.style.display = 'none';
        if (walletInfoContainer) walletInfoContainer.style.display = 'flex';
        if (defaultHeader) defaultHeader.style.display = 'block';

        if (walletLabel) {
            if (selectedPubkey) {
                walletLabel.textContent = 'Custom Pubkey';
            } else if (selectedWallet && selectedWallet.name) {
                let walletName = selectedWallet.name;
                if (!walletName.toLowerCase().includes('wallet')) {
                    walletName += ' Wallet';
                }
                walletLabel.textContent = walletName;
            } else {
                walletLabel.textContent = 'Wallet';
            }
        }
    } else {
        if (connectButton) connectButton.style.display = 'inline-block';
        if (walletInfoContainer) walletInfoContainer.style.display = 'none';
        if (defaultHeader) defaultHeader.style.display = 'block';

        if (walletLabel) walletLabel.textContent = 'Wallet';
    }

    updateStakeToXshinUI(isConnected);
    updateStakingUI(isConnected);
}

document.getElementById('stakeAmount').addEventListener('input', async function () {
    const amount = parseFloat(this.value);
    if (!isNaN(amount) && amount > 0) {
        const estimate = await simulateStakeSolToXshin(amount);
        if (estimate !== null) {
            document.getElementById('solToXshinEstimate').textContent = `~${estimate.toFixed(3)}`;
        } else {
            document.getElementById('solToXshinEstimate').textContent = 'Error estimating xSHIN';
        }
    } else {
        document.getElementById('solToXshinEstimate').textContent = '';
    }
});

document.getElementById('unstakeXshinAmount').addEventListener('input', async function () {
    const amount = parseFloat(this.value);
    if (!isNaN(amount) && amount > 0) {
        const estimate = await simulateUnstakeXshinToSol(amount);
        document.getElementById('xshinToSolEstimate').textContent = `~${estimate.toFixed(3)}`;
    } else {
        document.getElementById('xshinToSolEstimate').textContent = '';
    }
});

document.getElementById('unstakeToStakeAmount').addEventListener('input', async function () {
    const amount = parseFloat(this.value);
    const estimateElement = document.getElementById('xshinToStakeEstimate');

    if (!isNaN(amount) && amount > 0) {
        const estimate = await simulateUnstakeXshinToStakeAccount(amount);
        estimateElement.innerHTML = `
            <span class="estimate-value">~${estimate.toFixed(2)}</span>
            <span class="estimate-label">Staked</span>
        `;
    } else {
        estimateElement.innerHTML = '';
    }
});

function showLoading() {
    document.getElementById('loading').style.display = 'block';
}

function hideLoading() {
    document.getElementById('loading').style.display = 'none';
}

function showHeaderMessage(message, showSpinner = false) {
    const headerMessageElement = document.getElementById('headerMessage');
    if (headerMessageElement) {
        headerMessageElement.innerHTML = `
            <div style="display: flex; flex-direction: column; align-items: center;">
                <span>${message}</span>
                ${showSpinner ? '<div class="loading-spinner"></div>' : ''}
            </div>
        `;
        headerMessageElement.style.display = 'flex';
        headerMessageElement.style.justifyContent = 'center';
    }
    document.getElementById('walletButtonContainer').style.display = 'none';
    document.getElementById('walletInfo').style.display = 'none';
}

function hideHeaderMessage() {
    const headerMessageElement = document.getElementById('headerMessage');
    if (headerMessageElement) {
        headerMessageElement.style.display = 'none';
    }
    const walletButtonContainer = document.getElementById('walletButtonContainer');
    const walletInfo = document.getElementById('walletInfo');

    if (!selectedWallet && !selectedPubkey) {
        // No wallet connected or custom pubkey used
        walletButtonContainer.style.display = 'flex';
        walletInfo.style.display = 'none';
    } else {
        walletButtonContainer.style.display = 'none';
        walletInfo.style.display = 'flex';
    }
}

// VALIDATION

function isValidatorsTabActive() {
	return document.getElementById('Validators').style.display === 'block';
}

async function handleValidatorTypeChange(type, fromHomePage = false) {
    const appState = getAppState();

    if (type === appState.validatorType && !fromHomePage) {
        return;
    }

    const validatorSearch = document.getElementById('validatorSearch');
    const currentSearchQuery = validatorSearch.value.trim();

    updateAppState({ validatorType: type, lastUsedDataset: type });

    showLoadingSpinner();
    try {
        await refreshValidatorData(true);

        const updatedAppState = getAppState();

        let validatorData = type === 'pool' ? updatedAppState.cache.pool?.validators : mergeValidatorData();

        if (!validatorData || validatorData.size === 0) {
            console.error('Validator data is not available after refresh');
            return;
        }

        validatorData = validatorData instanceof Map ? validatorData : new Map(validatorData);

        let filteredData;
        if (currentSearchQuery) {
            filteredData = filterValidators(type, currentSearchQuery, Array.from(validatorData.entries()));
        } else {
            filteredData = Array.from(validatorData.entries());
        }

        await populateValidatorRows(filteredData);

        if (currentSearchQuery) {
            applySearchFilter(filteredData, validatorData, currentSearchQuery);
            validatorSearch.value = currentSearchQuery;  // Preserve the search query in the input field
            await searchValidators(); // Perform the search with the preserved query
        }

        updateAppState({ validatorsPopulated: true, searchQuery: currentSearchQuery });

        history.pushState(null, '', '#Validators');

        if (!fromHomePage) {
            closeDetailsPane();
        }

        updateValidatorTypeRadioButtons();

        const currentSortColumn = updatedAppState.activeSortColumn || 'details.total_score';
        const currentSortDirection = updatedAppState.activeSortDirection || 'desc';
        updateSortIndicator(currentSortColumn, currentSortDirection);
    } catch (error) {
        console.error('Error updating validator list:', error);
    } finally {
        hideLoadingSpinner();
    }
}

function applySearchFilter(filteredData, validatorData, searchQuery) {
    const filteredPubkeys = new Set(filteredData.map(([pubkey]) => pubkey));
    const validatorContainers = document.getElementsByClassName('validator-row-container');

    Array.from(validatorContainers).forEach(container => {
        const pubkey = container.querySelector('.validator-row').dataset.validatorPubkey;
        const isVisible = filteredPubkeys.has(pubkey);
        container.style.display = isVisible ? '' : 'none';
        if (isVisible) {
            const validator = validatorData.get(pubkey);
            if (validator) {
                updateRowHighlight(pubkey, searchQuery, validator);
            }
        }
    });

    updateNoMatchMessage(filteredData.length === 0);
}

function closeDetailsPane() {
    const detailsPane = document.getElementById('validator-details-pane');
    if (detailsPane) {
        detailsPane.style.display = 'none';
        detailsPane.dataset.pubkey = '';
        detailsPane.classList.remove('active');
    }

    document.querySelectorAll('.validator-row, .validator-icon-name').forEach(el =>
        el.classList.remove('active', 'active-row')
    );
}

function updateValidatorTypeRadioButtons() {
    const validatorType = getAppState().validatorType || 'all';
    const allRadio = document.getElementById('all-validators');
    const poolRadio = document.getElementById('pool-validators');

    allRadio.checked = validatorType === 'all';
    poolRadio.checked = validatorType === 'pool';

    // Add event listeners to update app state when radio buttons change
    allRadio.addEventListener('change', () => {
		if (allRadio.checked) {
			updateAppState({ validatorType: 'all' });
		}
	});

	poolRadio.addEventListener('change', () => {
		if (poolRadio.checked) {
			updateAppState({ validatorType: 'pool' });
		}
	});

    sortValidators(getAppState('activeSortColumn') || 'details.total_score', getAppState('activeSortDirection') || 'desc');
}

// returns null if RPC URL is valid, or string describing why invalid
async function getInvalidRpcUrlDescription(url) {
    // Check if the URL length is greater than zero
    if (url.length === 0) {
        return "Empty URL";
    }

    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return "must begin with http:// or https://";
    }

    // Check if the URL contains "devnet" or "testnet"
    if ((url.includes("devnet") || url.includes("testnet"))) {
        return "Only mainnet RPC values work with stake pools.";
    }

    try {
        new URL(url);
    }
    catch (error) {
        return error.toString();
    }

    return null;
}


async function connectToRpcNode() {
    let rpcUrl = defaultRpcUrl;

    // If selectedRpcUrl is provided, use it
    if (selectedRpcUrl) {
        rpcUrl = selectedRpcUrl;
    }

    try {
        const connection = new solanaWeb3.Connection(rpcUrl);
        await connection.getEpochInfo(); // This ensures the connection is valid
        return connection;
    } catch (error) {
        if (rpcUrl === defaultRpcUrl) {
            console.error(`Failed to connect to default RPC URL ${defaultRpcUrl}. Trying backup RPC URL.`);
            try {
                const connection = new solanaWeb3.Connection(backupRpcUrl);
                await connection.getEpochInfo(); // This ensures the connection is valid
                console.warn('Using backup RPC URL due to failure of the default RPC URL.');
                return connection;
            } catch (backupError) {
                showTransactionDetail('Both default and backup RPC URLs failed to connect. Please try again later or try a custom RPC URL.', 'error');
                throw new Error('Both default and backup RPC URLs failed. Please try again later or use a custom RPC URL.');
            }
        } else {
            showTransactionDetail('Failed to connect to RPC URL: ' + selectedRpcUrl, 'error');
            throw new Error('Invalid RPC URL: ' + selectedRpcUrl);
        }
    }
}

function isValidPubkey(pubkey) {
    if (typeof pubkey !== 'string' || pubkey.trim().length === 0) {
        return false;
    }

    const trimmedPubkey = pubkey.trim();

    if (trimmedPubkey.length !== 44) {
        return false;
    }

    try {
        new solanaWeb3.PublicKey(trimmedPubkey);
        return true;
    } catch (error) {
        showTransactionDetail('Invalid pubkey3: ' + pubkey, 'error');
        return false;
    }
}

async function getPublicKey() {
    let publicKey = '';

    if (isValidPubkey(selectedPubkey)) {
        publicKey = new solanaWeb3.PublicKey(selectedPubkey);
        disconnectWallet();
    } else if (selectedWallet && selectedWallet.publicKey) {
        publicKey = selectedWallet.publicKey;
    }

    displayWalletAddress(publicKey.toString());

    return publicKey;
}

//
// Global functions
//

// Expose functions using in html DOM to the global scope
globalThis.updatePubkeyStatus = updatePubkeyStatus;
globalThis.updateRpcUrlStatus = updateRpcUrlStatus;
globalThis.applyPubkey = applyPubkey;
globalThis.applyRpcUrl = applyRpcUrl;
globalThis.searchValidators = searchValidators;
globalThis.disconnectPubkey = disconnectPubkey;
globalThis.resetRpcUrl = resetRpcUrl;
globalThis.getStakeAccounts = getStakeAccounts;
globalThis.openStakeTab = openStakeTab;
globalThis.openUnstakeTab = openUnstakeTab;
globalThis.openTab = openTab;
globalThis.clearSearch = clearSearch;
