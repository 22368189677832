
// ====================================================================================================================
// Public API
// ====================================================================================================================


import { bs58 } from './bs58.js';

// If new data is available, calls back on_new_data with the timestmap of the new data
export function check_new_timestamp(on_new_timestamp) {
    let cache = check_new_timestamp.cache || (check_new_timestamp.cache = [Number(0)]);

    const req = new XMLHttpRequest();

    req.open("GET", "https://xshin.fi/data/pool/newest", true);

    req.onload = (event) => {
        if (req.response > cache[0]) {
            cache[0] = Number(req.response);
            on_new_timestamp(cache[0]);
        }
    };

    req.send();
}

// Loads a VersionedSearch
// struct Search
// {
//     // Map from voter pubkey to name (or null if no name)
//     voters : Map<Pubkey, Option<String>>
// }
// if timestamp_or_url is a number, then it is used as a timestamp to fetch directly from xshin.fi; else it's a
// url that is loaded
export function load_search(timestamp_or_url, onload) {
    if (typeof timestamp_or_url == "number") {
        let timestamp = timestamp_or_url;

        let cache = load_search.cache || (load_search.cache = { timestamp: 0, value: null });

        if (cache.timestamp == timestamp) {
            setTimeout(() => { onload(cache.value); }, 0);
        }
        else {
            const req = new XMLHttpRequest();

            req.open("GET", "https://xshin.fi/data/pool/" + timestamp + "/search.bin", true);

            req.responseType = "arraybuffer";

            req.onload = (event) => {
                cache.timestamp = timestamp;
                cache.value = bincode_search(bincode_parser(req.response));
                onload(cache.value);
            };

            req.send();
        }
    }
    else {
        let url = timestamp_or_url;

        const req = new XMLHttpRequest();

        req.open("GET", url, true);

        req.responseType = "arraybuffer";

        req.onload = (event) => {
            cache.timestamp = timestamp;
            cache.value = bincode_search(bincode_parser(req.response));
            onload(cache.value);
        };

        req.send();
    }
}


// Loads a VersionedVoterMetricsGroup
// struct VoterMetricsGroup
// {
//     // Map from voter pubkey to vote account metrics
//     voters : Map<Pubkey, VoteAccountMetrics>
// }
// struct VoteAccountMetrics
// {
//     // Most recent ten epochs in which LeaderData was available for this vote account
//     // Mapped from epoch number to data
//     leader_data : Map<u64, LeaderData>,
//
//     // Most recent ten epochs in which VoterData was available for this vote account
//     // Mapped from epoch number to data
//     voter_data : Map<u64, VoterData>,
//
//     // Most recent ten epochs in which PoolData was available for this vote account
//     // Mapped from epoch number to data
//     pool_data : Map<u64, PoolData>
// }
// struct LeaderData
// {
//     // Total number of leader slots that the validator had or has had in the epoch.
//     leader_slots : u64,
//
//     // Total number of leader groups that the validator had in the epoch.  This is almost the same as slots / 4,
//     // except sometimes validators get one or more contiguous group which are then counted as a single group.
//     leader_groups : u64,
//
//     // Total number of blocks produced by the validator in the epoch.  1.0 - (blocks / slots) is skip rate.
//     blocks : u64,
//
//     // The number of skips prior to the first emitted block of a validator's leader group
//     prior_skips : u64,
//
//     // The number of skips after the last emitted block of a validator's leader group
//     subsequent_skips : u64,
//
//     // Total CU of all transactions included in all blocks emitted by the validator in the epoch
//     total_cu : u64,
//
//     // Total number of vote tx included in all blocks emitted by the validator in the epoch
//     total_vote_tx : u64
// }
// struct VoterData
// {
//     // This validator's commission
//     commission : u8,
//
//     // Total vote credits earned by the validator in the epoch
//     vote_credits : u64,
//
//     // Total number of fork slots voted on by the validator in the epoch
//     total_fork_slots_voted_on : u64,
//
//     // Total fork slot latency of all slots voted on in the epoch
//     total_fork_slot_vote_latency : u64,
//
//     // Total number of fork_slots voted on with low latency (< 3)
//     total_low_latency_fork_slots : u64,
//
//     // Total number of successful vote tx in the epoch
//     total_successful_vote_tx : u64,
//
//     // Total number of consensus vote tx in the epoch
//     total_consensus_vote_tx : u64,
//
//     // Total fraction of slots of the epoch in which the validator was delinquent
//     delinquency_fraction : f64,
//
//     // APY achieved in the epoch (estimated for current epoch)
//     apy : f32,
//
//     // List of vote accounts that this vote account shares validator identity with
//     shared_identity_vote_accounts : Vec<Pubkey>,
//
//     // Percentage of active stake in the city that this validator is in, if it is known
//     geo_concentration_city : Option<f64>,
//
//     // Percentage of active stake in the country that this validator is in, if it is known
//     geo_concentration_country : Option<f64>,
// }
// struct PoolData
// {
//     // Total extra lamports in all pool stake accounts for this validator
//     extra_lamports : u64,
//
//     // Total pool actively staked to by the pool to this validator
//     pool_lamports : u64
// }
// if timestamp_or_url is a number, then it is used as a timestamp to fetch directly from xshin.fi using vote_pubkey
// as the group identifier; else it's a url that is loaded (and vote_pubkey is ignored)
export function load_voter_metrics_group(timestamp_or_url, vote_pubkey, onload) {
    if (typeof timestamp_or_url == "number") {
        let timestamp = timestamp_or_url;

        let cache = load_voter_metrics_group.cache || (load_voter_metrics_group.cache = new Map());
        let key = vote_pubkey.slice(-1);
        let entry = cache.get(key);

        if (entry == null) {
            entry = { timestamp: 0, value: null };
            cache.set(key, entry);
        }

        if (entry.timestamp < timestamp) {
            const req = new XMLHttpRequest();

            req.open("GET", "https://xshin.fi/data/pool/" + timestamp + "/groups/" + key + ".bin", true);

            req.responseType = "arraybuffer";

            req.onload = (event) => {
                entry.timestamp = timestamp;
                entry.value = bincode_voter_metrics_group(bincode_parser(req.response));
                onload(entry.value);
            };

            req.send();
        }
        else {
            setTimeout(() => { onload(entry.value); }, 0);
        }
    }
    else {
        let url = timestamp_or_url;

        const req = new XMLHttpRequest();

        req.open("GET", url, true);

        req.responseType = "arraybuffer";

        req.onload = (event) => {
            entry.timestamp = timestamp;
            entry.value = bincode_voter_metrics_group(bincode_parser(req.response));
            onload(entry.value);
        };

        req.send();
    }
}


// Loads a VersionedNonPoolVoters
// struct NonPoolVoters
// {
//     voters : Map<Pubkey, NonPoolVoterDetails>
// }
// struct NonPoolVoterDetails
// {
//     details : VoterDetails,
//
//     // If empty, was eligible
//     noneligibility_reasons : Vec<String>
// }
// struct VoterDetails
// {
//     name : Option<String>,
//
//     // If this came from keybase, then it's a regular URL.  If it came from validator info, then it will be
//     // prepended with "**icon_url**".  If it's from keybase, it should be rendered as a circle; otherwise, it
//     // should be rendered as-is.
//     icon_url : Option<String>,
//
//     // Details
//     details : Option<String>,
//
//     // Website link
//     website_url : Option<String>,
//
//     // City, if known
//     city : Option<String>,
//
//     // Country, if known
//     country : Option<String>,
//
//     // Current total stake levels
//     stake : Stake,
//
//     // Current pool target stake level
//     target_pool_stake : u64,
//
//     // Raw score derived from epoch-weighted average raw scores
//     raw_score : Score,
//
//     // Normalized score derived from raw_score metrics relative to the best scorer for each metric
//     pub normalized_score : Score,
//
//     // Total score
//     total_score : f64
// }
// struct Stake
// {
//     // Active stake
//     active : u64,
//
//     // Activating stake - will be active next epoch
//     activating : u64,
//
//     // Deactiving stake - will no longer be active next epoch
//     deactivating : u64
// }
// struct Score
// {
//     skip_rate : f64,
//
//     prior_skip_rate : f64,
//
//     subsequent_skip_rate : f64,
//
//     cu : f64,
//
//     latency : f64,
//
//     llv : f64,
//
//     cv : f64,
//
//     vote_inclusion : f64,
//
//     apy : f32,
//
//     pool_extra_lamports : f64, // As a fraction of validator's total pool stake
//
//     city_concentration : f64,
//
//     country_concentration : f64
// }
// if timestamp_or_url is a number, then it is used as a timestamp to fetch directly from xshin.fi; else it's a
// url that is loaded
export function load_non_pool_voters(timestamp_or_url, onload) {
    if (typeof timestamp_or_url == "number") {
        let timestamp = timestamp_or_url;

        let cache = load_non_pool_voters.cache || (load_non_pool_voters.cache = { timestamp: 0, value: null });

        if (cache.timestamp == timestamp) {
            setTimeout(() => { onload(cache.value); }, 0);
        }
        else {
            const req = new XMLHttpRequest();

            req.open("GET", "https://xshin.fi/data/pool/" + timestamp + "/non_pool_voters.bin", true);

            req.responseType = "arraybuffer";

            req.onload = (event) => {
                cache.timestamp = timestamp;
                cache.value = bincode_non_pool_voters(bincode_parser(req.response));
                onload(cache.value);
            };

            req.send();
        }
    }
    else {
        let url = timestamp_or_url;

        const req = new XMLHttpRequest();

        req.open("GET", url, true);

        req.responseType = "arraybuffer";

        req.onload = (event) => {
            cache.timestamp = timestamp;
            cache.value = bincode_non_pool_voters(bincode_parser(req.response));
            onload(cache.value);
        };

        req.send();
    }
}


// Loads a VersionedOverviewDetails
// struct OverviewDetails
// {
//     // Current SOL price in U.S. Dollars
//     price : f32,
//
//     // Current epoch
//     epoch : u64,
//
//     // Epoch start time
//     epoch_start : u64,
//
//     // Estimated epoch duration
//     epoch_duration : u64,
//
//     // Current total stake in the pool
//     pool_stake : Stake,
//
//     // Current stake in reserve
//     reserve : u64,
//
//     // Current pool APY -- which is a blending of completed epochs plus estimate for this epoch
//     apy : f32
// }
// struct Stake
// {
//     // Active stake
//     active : u64,
//
//     // Activating stake - will be active next epoch
//     activating : u64,
//
//     // Deactiving stake - will no longer be active next epoch
//     deactivating : u64
// }
// if timestamp_or_url is a number, then it is used as a timestamp to fetch directly from xshin.fi; else it's a
// url that is loaded
export function load_overview_details(timestamp_or_url, onload) {
    if (typeof timestamp_or_url == "number") {
        let timestamp = timestamp_or_url;

        let cache = load_overview_details.cache || (load_overview_details.cache = { timestamp: 0, value: null });

        if (cache.timestamp == timestamp) {
            setTimeout(() => { onload(cache.value); }, 0);
        }
        else {
            const req = new XMLHttpRequest();

            req.open("GET", "https://xshin.fi/data/pool/" + timestamp + "/overview.bin", true);

            req.responseType = "arraybuffer";

            req.onload = (event) => {
                cache.timestamp = timestamp;
                cache.value = bincode_overview_details(bincode_parser(req.response));
                onload(cache.value);
            };

            req.send();
        }
    }
    else {
        let url = timestamp_or_url;

        const req = new XMLHttpRequest();

        req.open("GET", url, true);

        req.responseType = "arraybuffer";

        req.onload = (event) => {
            cache.timestamp = timestamp;
            cache.value = bincode_overview_details(bincode_parser(req.response));
            onload(cache.value);
        };

        req.send();
    }
}


// Loads a VersionedPoolDetails
// struct PoolDetails
// {
//     // Number of validators in the pool
//     pool_validator_count : u64,
//
//     // "Best validator" selections, randomly selected from top 5% of pool/top 10 (whichever is larger)
//     // pubkey, skip rate, pool ranking
//     best_skip_rate : Vec<Best>,
//
//     // pubkey, skip rate, pool ranking
//     best_cu : Vec<Best>,
//
//     // pubkey, avg latency, pool ranking
//     best_latency : Vec<Best>,
//
//     // pubkey, llv, pool ranking
//     best_llv : Vec<Best>,
//
//     // pubkey, cv, pool ranking
//     best_cv : Vec<Best>,
//
//     // pubkey, vote_inclusion, pool ranking
//     best_vote_inclusion : Vec<Best>,
//
//     // pubkey, apy, pool ranking
//     best_apy : Vec<Best>,
//
//     // pubkey, pool_extra_lamports, pool ranking
//     best_pool_extra_lamports : Vec<Best>,
//
//     // pubkey, city_concentration, pool ranking
//     best_city_concentration : Vec<Best>,
//
//     // pubkey, country_concentration, pool ranking
//     best_country_concentration : Vec<Best>,
//
//     // pubkey, total_score, pool ranking
//     best_overall : Vec<Best>,
//
//     // Comparative metrics -- compare SPP to non-SPP, weighting SPP validators by their current SPP stake levels
//     compare_by_current : ComparativeMetrics,
//
//     // Comparative metrics -- compare SPP to non-SPP, weighting SPP validators by their target SPP stake levels
//     compare_by_target : ComparativeMetrics,
//
//     // All the voters currently in the pool
//     pool_voters : Map<Pubkey, PoolVoterDetails>,
//
//     // Weights used when scoring all validators before inclusion in the pool
//     pub inclusion_weights : MetricWeights,
//
//     // Weights used when scoring pool validators for ranking within the pool
//     pub ranking_weights : MetricWeights
//
// }
// struct Best
// {
//     // Pubkey of the vote account
//     pubkey : Pubkey,
//
//     // Metric value
//     metric : f64,
//
//     // Rank within all ranked validators for this metric
//     rank : u64
// }
// struct ComparativeMetrics
// {
//     // Raw score of SPP
//     spp_raw : Score,
//
//     // Raw score of other
//     other_raw : Score
// }
// struct PoolVoterDetails
// {
//     details : VoterDetails,
//
//     // Current pool stake, where activating and deactivating are how much the pool is activating/deactivating
//     pool_stake : Stake,
//
//     // If the pool voter is not currently eligible, but is still in the pool as they are rebalanced out, this
//     // will hold noneligibility reasons
//     noneligibility_reasons : Vec<String>
// }
// struct VoterDetails
// {
//     name : Option<String>,
//
//     // If this came from keybase, then it's a regular URL.  If it came from validator info, then it will be
//     // prepended with "**icon_url**".  If it's from keybase, it should be rendered as a circle; otherwise, it
//     // should be rendered as-is.
//     icon_url : Option<String>,
//
//     // Details
//     details : Option<String>,
//
//     // Website link
//     website_url : Option<String>,
//
//     // City, if known
//     city : Option<String>,
//
//     // Country, if known
//     country : Option<String>,
//
//     // Current total stake levels
//     stake : Stake,
//
//     // Current pool target stake level
//     target_pool_stake : u64,
//
//     // Raw score derived from epoch-weighted average raw scores
//     raw_score : Score,
//
//     // Normalized score derived from raw_score metrics relative to the best scorer for each metric
//     pub normalized_score : Score,
//
//     // Total score
//     total_score : f64
// }
// struct Stake
// {
//     // Active stake
//     active : u64,
//
//     // Activating stake - will be active next epoch
//     activating : u64,
//
//     // Deactiving stake - will no longer be active next epoch
//     deactivating : u64
// }
// struct Score
// {
//     skip_rate : f64,
//
//     prior_skip_rate : f64,
//
//     subsequent_skip_rate : f64,
//
//     cu : f64,
//
//     latency : f64,
//
//     llv : f64,
//
//     cv : f64,
//
//     vote_inclusion : f64,
//
//     apy : f32,
//
//     city_concentration : f64,
//
//     country_concentration : f64
// }
// struct MetricWeights
// {
//     skip_rate_weight : f64,
//
//     prior_skip_rate_weight : f64,
//
//     subsequent_skip_rate_weight : f64,
//
//     cu_weight : f64,
//
//     latency_weight : f64,
//
//     llv_weight : f64,
//
//     cv_weight : f64,
//
//     vote_inclusion_weight : f64,
//
//     apy_weight : f64,
//
//     city_concentration_weight : f64,
//
//     country_concentration_weight : f64
// }

export function load_pool_details(timestamp_or_url, onload) {
    if (typeof timestamp_or_url == "number") {
        let timestamp = timestamp_or_url;

        let cache = load_pool_details.cache || (load_pool_details.cache = { timestamp: 0, value: null });

        if (cache.timestamp == timestamp) {
            setTimeout(() => { onload(cache.value); }, 0);
        }
        else {
            const req = new XMLHttpRequest();

            req.open("GET", "https://xshin.fi/data/pool/" + timestamp + "/pool.bin", true);

            req.responseType = "arraybuffer";

            req.onload = (event) => {
                cache.timestamp = timestamp;
                cache.value = bincode_pool_details(bincode_parser(req.response));
                onload(cache.value);
            };

            req.send();
        }
    }
    else {
        let url = timestamp_or_url;

        const req = new XMLHttpRequest();

        req.open("GET", url, true);

        req.responseType = "arraybuffer";

        req.onload = (event) => {
            cache.timestamp = timestamp;
            cache.value = bincode_pool_details(bincode_parser(req.response));
            onload(cache.value);
        };

        req.send();
    }
}


// ====================================================================================================================
// Private Implementation
// ====================================================================================================================

// This can be improved:
// - Use fetch API and parse data structures as they are read, instead of after being completely read.  This overlaps
//   parsing with receiving the data, which should result in overall faster fetches of remote data.

function bincode_parser(buffer) {
    return {
        buffer: buffer,

        offset: 0
    };
}


function bincode_parser_advance(return_value, parser, count) {
    parser.offset += count;

    return return_value;
}


function bincode_parser_data_view(parser, length) {
    return new DataView(parser.buffer, parser.offset);
}


function bincode_parser_peek(parser) {
    return bincode_parser_data_view(parser, 1).getUint8();
}


function bincode_parser_slice(parser, length) {
    return parser.buffer.slice(parser.offset, parser.offset + length);
}


function bincode_bool(parser) {
    switch (bincode_parser_data_view(parser).getUint8()) {
        case 0:
            return bincode_parser_advance(false, parser, 1);
        case 1:
            return bincode_parser_advance(true, parser, 1);
        default:
            throw new Error("Failed to parse bool");
    }
}


function bincode_u8(parser) {
    return bincode_parser_advance(bincode_parser_data_view(parser).getUint8(), parser, 1);
}


function bincode_u16(parser) {
    switch (bincode_parser_peek(parser)) {
        case 251:
            return bincode_parser_advance(bincode_parser_data_view(parser).getUint16(1, true), parser, 3);
        default:
            return bincode_parser_advance(bincode_parser_data_view(parser).getUint8(0, true), parser, 1);
    }
}


function bincode_u32(parser) {
    switch (bincode_parser_peek(parser)) {
        case 251:
            return bincode_parser_advance(bincode_parser_data_view(parser).getUint16(1, true), parser, 3);
        case 252:
            return bincode_parser_advance(bincode_parser_data_view(parser).getUint32(1, true), parser, 5);
        default:
            return bincode_parser_advance(bincode_parser_data_view(parser).getUint8(0, true), parser, 1);
    }
}


function bincode_u64(parser) {
    switch (bincode_parser_peek(parser)) {
        case 251:
            return bincode_parser_advance(BigInt(bincode_parser_data_view(parser).getUint16(1, true)), parser, 3);
        case 252:
            return bincode_parser_advance(BigInt(bincode_parser_data_view(parser).getUint32(1, true)), parser, 5);
        case 253:
            return bincode_parser_advance(bincode_parser_data_view(parser).getBigUint64(1, true), parser, 9);
        default:
            return bincode_parser_advance(BigInt(bincode_parser_data_view(parser).getUint8(0, true)), parser, 1);
    }
}


function bincode_i64(parser) {
    let u64 = bincode_u64(parser);;

    // Now un-zig-zag
    let one = BigInt(1);

    if ((u64 & BigInt(0x1)) == BigInt(0x1)) {
        return -((u64 + one) >> one);
    }
    else {
        return u64 >> one;
    }
}


function bincode_f32(parser) {
    return bincode_parser_advance(bincode_parser_data_view(parser).getFloat32(0, true), parser, 4);
}


function bincode_f64(parser) {
    return bincode_parser_advance(bincode_parser_data_view(parser).getFloat64(0, true), parser, 8);
}


function bincode_string(parser) {
    let length = bincode_u32(parser);

    return bincode_parser_advance((new TextDecoder()).decode(bincode_parser_slice(parser, length)), parser, length);
}


function bincode_pubkey(parser) {
    return bincode_parser_advance(bs58.encode(new Uint8Array(bincode_parser_slice(parser, 32))), parser, 32);
}


function bincode_all_epochs_stake_size(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    let count = bincode_u64(parser);

    let epochs = [];

    for (let i = 0; i < count; i++) {
        epochs.push(bincode_stake_size_u64(parser));
    }

    return {
        epochs: epochs
    };
}


function bincode_epoch_cluster(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    let timestamp = bincode_u64(parser);

    let cluster = bincode_epoch_stakes(parser);

    let count = bincode_u64(parser);

    let voters = new Map();

    for (let i = 0; i < count; i++) {
        voters.set(bincode_pubkey(parser), bincode_epoch_voter_summary(parser));
    }

    return {
        timestamp: timestamp,

        cluster: cluster,

        voters: voters
    };
}


function bincode_epoch_voter_details_group(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    let count = bincode_u64(parser);

    let voters = new Map();

    for (let i = 0; i < count; i++) {
        voters.set(bincode_pubkey(parser), bincode_epoch_stakes(parser));
    }

    return {
        voters: voters
    };
}

// NEW STARTS HERE
// --------------------------------------------------------------------------------------------------------------------

function bincode_vote_account_metrics(parser) {
    let leader_data = new Map();

    let count = bincode_u64(parser);

    for (let i = 0; i < count; i++) {
        leader_data.set(bincode_u64(parser), bincode_leader_data(parser));
    }

    let voter_data = new Map();

    count = bincode_u64(parser);

    for (let i = 0; i < count; i++) {
        voter_data.set(bincode_u64(parser), bincode_voter_data(parser));
    }

    let pool_data = new Map();

    count = bincode_u64(parser);

    for (let i = 0; i < count; i++) {
        pool_data.set(bincode_u64(parser), bincode_pool_data(parser));
    }

    return {
        leader_data: leader_data,

        voter_data: voter_data,

        pool_data: pool_data
    };
}


function bincode_leader_data(parser) {
    return {
        leader_slots: bincode_u64(parser),

        leader_groups: bincode_u64(parser),

        blocks: bincode_u64(parser),

        prior_skips: bincode_u64(parser),

        subsequent_skips: bincode_u64(parser),

        total_cu: bincode_u64(parser),

        total_vote_tx: bincode_u64(parser)
    };
}


function bincode_voter_data(parser) {
    let commission = bincode_u8(parser);

    let vote_credits = bincode_u64(parser);

    let total_fork_slots_voted_on = bincode_u64(parser);

    let total_fork_slot_vote_latency = bincode_u64(parser);

    let total_low_latency_fork_slots = bincode_u64(parser);

    let total_successful_vote_tx = bincode_u64(parser);

    let total_consensus_vote_tx = bincode_u64(parser);

    let delinquency_fraction = bincode_f64(parser);

    let apy = bincode_f32(parser);

    let count = bincode_u64(parser);

    let shared_identity_vote_accounts = [];

    for (let i = 0; i < count; i++) {
        shared_identity_vote_accounts.push(bincode_pubkey(parser));
    }

    let geo_concentration_city = null;

    let geo_concentration_country = null;

    if (bincode_bool(parser)) {
        geo_concentration_city = bincode_f64(parser);

        geo_concentration_country = bincode_f64(parser);
    }

    return {
        commission: commission,

        vote_credits: vote_credits,

        total_fork_slots_voted_on: total_fork_slots_voted_on,

        total_fork_slot_vote_latency: total_fork_slot_vote_latency,

        total_low_latency_fork_slots: total_low_latency_fork_slots,

        total_successful_vote_tx: total_successful_vote_tx,

        total_consensus_vote_tx: total_consensus_vote_tx,

        delinquency_fraction: delinquency_fraction,

        apy: apy,

        shared_identity_vote_accounts: shared_identity_vote_accounts,

        geo_concentration_city: geo_concentration_city,

        geo_concentration_country: geo_concentration_country
    };
}


function bincode_pool_data(parser) {
    return {
        extra_lamports: bincode_u64(parser),

        pool_lamports: bincode_u64(parser)
    };
}


function bincode_pool_voter_details(parser, version) {
    let details = bincode_voter_details(parser, version);

    let pool_stake = bincode_stake(parser);

    let count = bincode_u64(parser);

    let noneligibility_reasons = [];

    for (let i = 0; i < count; i++) {
        noneligibility_reasons.push(bincode_noneligibility_reason(parser));
    }

    return {
        details: details,

        pool_stake: pool_stake,

        noneligibility_reasons: noneligibility_reasons
    };
}


function bincode_non_pool_voter_details(parser, version) {
    let details = bincode_voter_details(parser, version);

    let count = bincode_u64(parser);

    let noneligibility_reasons = [];

    for (let i = 0; i < count; i++) {
        noneligibility_reasons.push(bincode_noneligibility_reason(parser));
    }

    return {
        details: details,

        noneligibility_reasons: noneligibility_reasons
    };
}


function bincode_voter_details(parser, version) {
    let name = null;
    if (bincode_bool(parser)) {
        name = bincode_string(parser);
    }

    let icon_url = null;
    if (bincode_bool(parser)) {
        icon_url = bincode_string(parser);
    }

    let details = null;
    if (bincode_bool(parser)) {
        details = bincode_string(parser);
    }

    let website_url = null;
    if (bincode_bool(parser)) {
        website_url = bincode_string(parser);
    }

    let city = null;
    if (bincode_bool(parser)) {
        city = bincode_string(parser);
    }

    let country = null;
    if (bincode_bool(parser)) {
        country = bincode_string(parser);
    }

    let stake = bincode_stake(parser);

    let target_pool_stake = bincode_u64(parser);

    let raw_score = bincode_score(parser, version);

    let normalized_score;

    // Version < 2 does not have normalized_score, so use all zeroes
    if (version < 2) {
        normalized_score = {
            skip_rate: 0.0,
            prior_skip_rate: 0.0,
            subsequent_skip_rate: 0.0,
            cu: 0.0,
            latency: 0.0,
            llv: 0.0,
            cv: 0.0,
            vote_inclusion: 0.0,
            apy: 0.0,
            pool_extra_lamports: 0.0,
            city_concentration: 0.0,
            country_concentration: 0.0
        }
    }
    // Version >= 2 does have normalized_score
    else {
        normalized_score = bincode_score(parser, version);
    }

    let total_score = bincode_f64(parser);

    return {
        name: name,

        icon_url: icon_url,

        details: details,

        website_url: website_url,

        city: city,

        country: country,

        stake: stake,

        target_pool_stake: target_pool_stake,

        raw_score: raw_score,

        normalized_score: normalized_score,

        total_score: total_score
    };
}


function bincode_vote_inclusion(parser, version) {
    if (version < 1) {
        return 0;
    }
    else {
        return bincode_f64(parser);
    }
}


function bincode_score(parser, version) {
    return {
        skip_rate: bincode_f64(parser),

        prior_skip_rate: bincode_f64(parser),

        subsequent_skip_rate: bincode_f64(parser),

        cu: bincode_f64(parser),

        latency: bincode_f64(parser),

        llv: bincode_f64(parser),

        cv: bincode_f64(parser),

        vote_inclusion: bincode_vote_inclusion(parser, version),

        apy: bincode_f32(parser),

        pool_extra_lamports: bincode_f64(parser),

        city_concentration: bincode_f64(parser),

        country_concentration: bincode_f64(parser)
    };
}


function bincode_metric_weights(parser) {
    return {
        skip_rate_weight : bincode_f64(parser),

        prior_skip_rate_weight : bincode_f64(parser),

        subsequent_skip_rate_weight : bincode_f64(parser),

        cu_weight : bincode_f64(parser),

        latency_weight : bincode_f64(parser),

        llv_weight : bincode_f64(parser),

        cv_weight : bincode_f64(parser),

        vote_inclusion_weight : bincode_f64(parser),

        apy_weight : bincode_f64(parser),

        city_concentration_weight : bincode_f64(parser),

        country_concentration_weight : bincode_f64(parser)
    }
}


function bincode_stake(parser) {
    return {
        active: bincode_u64(parser),

        activating: bincode_u64(parser),

        deactivating: bincode_u64(parser)
    };
}


function bincode_noneligibility_reason(parser) {
    let index = bincode_u64(parser);

    switch (Number(index)) {
        case 0:
            return "Blacklisted (" + bincode_string(parser) + ")";

        case 1:
            return "In superminority";

        case 2: {
            let s = "Not leader in recent epochs (";
            let comma = false;
            let count = bincode_u64(parser);
            for (let i = 0; i < count; i++) {
                if (comma) {
                    s += ", ";
                }
                comma = true;
                s += bincode_u64(parser);
            }
            return s + ")";
        }

        case 3: {
            let s = "Low credits in recent epochs (";
            let comma = false;
            let count = bincode_u64(parser);
            for (let i = 0; i < count; i++) {
                if (comma) {
                    s += ", ";
                }
                comma = true;
                s += bincode_u64(parser);
            }
            return s + ")";
        }

        case 4: {
            let s = "Excessive delinquency in recent epochs (";
            let comma = false;
            let count = bincode_u64(parser);
            for (let i = 0; i < count; i++) {
                if (comma) {
                    s += ", ";
                }
                comma = true;
                s += bincode_u64(parser);
            }
            return s + ")";
        }

        case 5:
            return "Shared vote accounts";

        case 6:
            return "Commission too high: " + bincode_u8(parser);

        case 7: {
            let s = "APY too low in recent epochs (";
            let comma = false;
            let count = bincode_u64(parser);
            for (let i = 0; i < count; i++) {
                if (comma) {
                    s += ", ";
                }
                comma = true;
                s += bincode_u64(parser);
            }
            return s + ")";
        }

        case 8:
            return "Insufficient branding";

        case 9:
            return "Insufficient non-pool stake";

        default:
            throw "Invalid non eligibility reason: " + index;
    }
}


function bincode_best_f64(parser) {
    let count = bincode_u64(parser);

    let best = [];

    for (let i = 0; i < count; i++) {
        best.push({
            pubkey: bincode_pubkey(parser),

            metric: bincode_f64(parser),

            ranking: bincode_u64(parser)
        });
    }

    return best;
}


function bincode_best_f32(parser) {
    let count = bincode_u64(parser);

    let best = [];

    for (let i = 0; i < count; i++) {
        best.push({
            pubkey: bincode_pubkey(parser),

            metric: bincode_f32(parser),

            ranking: bincode_u64(parser)
        });
    }

    return best;
}


function bincode_comparative_metrics(parser, version) {
    return {
        spp_raw: bincode_score(parser, version),

        other_raw: bincode_score(parser, version)
    };
}


// Loaded from data/EPOCH:04/search.bin
function bincode_search(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    let count = bincode_u64(parser);

    let voters = new Map();

    for (let i = 0; i < count; i++) {
        let pubkey = bincode_pubkey(parser);

        if (bincode_bool(parser)) {
            voters.set(pubkey, bincode_string(parser));
        }
        else {
            voters.set(pubkey, null);
        }
    }

    return {
        voters: voters
    };
}


// Loaded from data/EPOCH:04/groups/ID.bin
function bincode_voter_metrics_group(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    let count = bincode_u64(parser);

    let voters = new Map();

    for (let i = 0; i < count; i++) {
        voters.set(bincode_pubkey(parser), bincode_vote_account_metrics(parser));
    }

    return {
        voters: voters
    };
}


// Loaded from data/EPOCH:04/non_pool_voters.bin
function bincode_non_pool_voters(parser) {
    let version = bincode_u8(parser);
    // Check that version is supported
    if ((version < 0) || (version > 2)) {
        throw new Error("Unexpected version: " + version);
    }

    let count = bincode_u64(parser);

    let voters = new Map();

    for (let i = 0; i < count; i++) {
        voters.set(bincode_pubkey(parser), bincode_non_pool_voter_details(parser, version));
    }

    return {
        voters: voters
    };
}


// Loaded from data/EPOCH:04/overview.bin
function bincode_overview_details(parser) {
    // Check that version is 0
    let version = bincode_u8(parser);
    if (version != 0) {
        throw new Error("Unexpected version: " + version);
    }

    return {
        price: bincode_f32(parser),

        epoch: bincode_u64(parser),

        epoch_start: bincode_u64(parser),

        epoch_duration: bincode_u64(parser),

        pool_stake: bincode_stake(parser),

        reserve: bincode_u64(parser),

        apy: bincode_f32(parser)
    };
}


// Loaded from data/EPOCH:04/pool.bin
function bincode_pool_details(parser) {
    // Check that version is supported
    let version = bincode_u8(parser);
    if ((version < 0) || (version > 2)) {
        throw new Error("Unexpected version: " + version);
    }

    let pool_validator_count = bincode_u64(parser);

    let best_skip_rate = bincode_best_f64(parser);

    let best_cu = bincode_best_f64(parser);

    let best_latency = bincode_best_f64(parser);

    let best_llv = bincode_best_f64(parser);

    let best_cv = bincode_best_f64(parser);

    let best_vote_inclusion;

    // version < 1 does not have best_vote_inclusion
    if (version < 1) {
        best_vote_inclusion = [];
    }
    // version >= 1 does have best_vote_inclusion
    else {
        best_vote_inclusion = bincode_best_f64(parser);
    }

    let best_apy = bincode_best_f32(parser);

    let best_pool_extra_lamports = bincode_best_f64(parser);

    let best_city_concentration = bincode_best_f64(parser);

    let best_country_concentration = bincode_best_f64(parser);

    let best_overall = bincode_best_f64(parser);

    let compare_by_current = bincode_comparative_metrics(parser, version);

    let compare_by_target = bincode_comparative_metrics(parser, version);

    let count = bincode_u64(parser);

    let pool_voters = new Map();

    for (let i = 0; i < count; i++) {
        pool_voters.set(bincode_pubkey(parser), bincode_pool_voter_details(parser, version));
    }

    let inclusion_weights;
    let ranking_weights;

    // version < 1 does not have inclusion_weights or ranking_weights
    if (version < 1) {
        inclusion_weights = {
            skip_rate_weight : 0,

            prior_skip_rate_weight : 0,

            subsequent_skip_rate_weight : 0,

            cu_weight : 0,

            latency_weight : 0,

            llv_weight : 0,

            cv_weight : 0,

            vote_inclusion_weight : 0,

            apy_weight : 0,

            city_concentration_weight : 0,

            country_concentration_weight : 0
        };
        ranking_weights = {
            skip_rate_weight : 0,

            prior_skip_rate_weight : 0,

            subsequent_skip_rate_weight : 0,

            cu_weight : 0,

            latency_weight : 0,

            llv_weight : 0,

            cv_weight : 0,

            vote_inclusion_weight : 0,

            apy_weight : 0,

            city_concentration_weight : 0,

            country_concentration_weight : 0
        };
    }
    // version >= 1 includes inclusion_weights and ranking_weights
    else {
        inclusion_weights = bincode_metric_weights(parser);
        ranking_weights = bincode_metric_weights(parser);
    }

    return {
        pool_validator_count: pool_validator_count,

        best_skip_rate: best_skip_rate,

        best_cu: best_cu,

        best_latency: best_latency,

        best_llv: best_llv,

        best_cv: best_cv,

        best_vote_inclusion: best_vote_inclusion,

        best_apy: best_apy,

        best_pool_extra_lamports: best_pool_extra_lamports,

        best_city_concentration: best_city_concentration,

        best_country_concentration: best_country_concentration,

        best_overall: best_overall,

        compare_by_current: compare_by_current,

        compare_by_target: compare_by_target,

        pool_voters: pool_voters,

        inclusion_weights: inclusion_weights,

        ranking_weights: ranking_weights
    };
}
